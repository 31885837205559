import {
  catchError,
  delay,
  map,
  mergeMap,
  startWith,
  switchMap,
} from "rxjs/operators"
import {
  connect,
  connected,
  disconnect,
  disconnected,
  receiveMessage,
  sendMessage,
  sentMessage,
} from "./index"
import { navCreated, navUpdated } from "../navs"

import { API_WS } from "../../../config"
import { Subject } from "rxjs/Subject"
import { combineEpics } from "redux-observable"
import { getTokens } from "../../../services/AuthService"
import { of } from "rxjs"
import { ofType } from "redux-observable"
import { webSocket } from "rxjs/webSocket"

let webSocketSubject
let onOpenSubject = new Subject()
let onCloseSubject = new Subject()

const connectSocket = () => {
  const tokens = getTokens()
  onOpenSubject = new Subject()
  onCloseSubject = new Subject()
  const url = `${API_WS}/?token=${tokens.access}`
  webSocketSubject = webSocket({
    url,
    openObserver: onOpenSubject,
    closeObserver: onCloseSubject,
  })
  return webSocketSubject
}

const connectEpic = (action$, state$) =>
  action$.pipe(
    ofType(connect.type),
    switchMap((action) =>
      connectSocket().pipe(
        map((data) => receiveMessage(data)),
        catchError((e) => of(disconnect({ retry: true }))),
      ),
    ),
  )

const connectedEpic = (action$) =>
  action$.pipe(
    ofType(connect.type),
    switchMap(() =>
      onOpenSubject.pipe(
        map(() => {
          onCloseSubject.pipe(map(() => disconnect()))
          return connected()
        }),
      ),
    ),
  )

const sendMessageEpic = (action$) =>
  action$.pipe(
    ofType(sendMessage.type),
    map((action) => {
      webSocketSubject.next(action.payload)
      return sentMessage()
    }),
  )

const receiveMessageEpic = (action$) =>
  action$.pipe(
    ofType(receiveMessage.type),
    map(({ payload: { type, data, extras } }) => {
      // console.log("action", action)
      switch (type) {
        case "nav.notify.create":
          return navCreated(data)
        case "nav.notify.update":
          return navUpdated(data)
        default:
          throw new Error("unkowwn message type")
      }
    }),
  )

const disconnectEpic = (action$) =>
  action$.pipe(
    ofType(disconnect.type),
    mergeMap((action) => {
      // console.log("action", action)
      if (action.payload.retry) {
        return of(connect()).pipe(delay(5000), startWith(disconnected()))
      }
      onCloseSubject.complete()
      webSocketSubject.complete()
      return [disconnected()]
    }),
  )

export default combineEpics(
  connectEpic,
  connectedEpic,
  sendMessageEpic,
  receiveMessageEpic,
  disconnectEpic,
)
