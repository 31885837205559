import { MobileDateTimePicker as MUIMobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { Field } from "formik"
import TextField from "@mui/material/TextField"
import { parseISO } from "date-fns"

export const MobileDateTimePicker = ({
  name,
  label,
  copyTo,
  maxField,
  minField,
  ...props
}) => (
  <Field name={name}>
    {({
      field: { name, value, onBlur },
      form: { values, setFieldValue },
      meta: { touched, error },
    }) => {
      const onChange = (d) => {
        if (copyTo) {
          console.log("copyTo: ", copyTo)
          setFieldValue(copyTo, d)
        }
        setFieldValue(name, d)
      }

      return (
        <MUIMobileDateTimePicker
          name={name}
          label={label}
          value={parseISO(value)}
          onChange={onChange}
          onClose={() => onBlur({ target: { name } })}
          ampm={false}
          minutesStep={5}
          minDate={minField && parseISO(values[minField])}
          maxDate={maxField && parseISO(values[maxField])}
          // disablePast
          clearable
          showToolbar
          renderInput={(inputProps) => (
            <TextField
              onBlur={onBlur}
              {...inputProps}
              fullWidth
              data-cy={`input-${name}`}
              error={touched && error}
              helperText={error && "Une date valide est requise."}
            />
          )}
          data-cy={`datetimepicker-${name}`}
          {...props} //disablePast...
        />
      )
    }}
  </Field>
)
