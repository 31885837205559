import { Drawer, Fab, Grid, List } from "@mui/material"
import { Home, PersonPin, PinDrop } from "@mui/icons-material"
import React, { useState } from "react"

import { ListItem } from "../../../components"
import { centerOnMe } from "../../../../services/GeoService"
import { setMapTarget } from "../../../../state/modules/geo"
import { useDispatch } from "react-redux"
import { sxRightFab } from "../../../styles"

const MapOpts = () => {
  const [mapOpts, setMapOpts] = useState(false)
  const openMapOpts = () => setMapOpts(true)
  const closeMapOpts = () => setMapOpts(false)

  const dispatch = useDispatch()
  const gotoBase = () => dispatch(setMapTarget("base"))

  return (
    <>
      <Fab onClick={openMapOpts} aria-label="menu" sx={sxRightFab}>
        <PinDrop />
      </Fab>
      <Drawer anchor="right" open={mapOpts} onClose={closeMapOpts}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          style={{ height: "100vh" }}
        >
          <Grid item>
            <List>
              <ListItem secondary="Aller vers" disabled={true} />
              <ListItem
                primary="Base"
                icon={<Home />}
                action={gotoBase}
                next={closeMapOpts}
              />
              <ListItem
                primary="Ma position"
                icon={<PersonPin />}
                action={() => centerOnMe(dispatch)}
                next={closeMapOpts}
              />
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}

export default MapOpts
