import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  EditLocation,
} from "@mui/icons-material"
import { Card, CardActions, CardContent, IconButton } from "@mui/material"

import { Field } from "formik"
import React from "react"
import { TextField } from "formik-mui"

const styleActions = {
  justifyContent: "center",
}

const ZoneItem = ({ arrayHelpers, zoneIndex, setZone }) => {
  return (
    <Card>
      <CardContent>
        <Field
          component={TextField}
          name={`zones.${zoneIndex}.name`}
          type="text"
          label="Nom de la zone"
          variant="outlined"
          fullWidth
          required
        />
        <br />
        <br />
        <Field
          component={TextField}
          name={`zones.${zoneIndex}.description`}
          type="text"
          label="Description"
          variant="outlined"
          fullWidth
          required
        />
      </CardContent>
      <CardActions style={styleActions}>
        <IconButton
          aria-label="draw"
          onClick={() => setZone(zoneIndex)}
          color="primary"
          size="large"
        >
          <EditLocation />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => arrayHelpers.move(zoneIndex, zoneIndex - 1)}
          color="primary"
          size="large"
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => arrayHelpers.move(zoneIndex, zoneIndex + 1)}
          color="primary"
          size="large"
        >
          <ArrowDownward />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => arrayHelpers.remove(zoneIndex)}
          color="secondary"
          size="large"
        >
          <Delete />
        </IconButton>
      </CardActions>
    </Card>
  )
}
export default ZoneItem
