import * as R from "ramda"

import { Circle, Marker, Polygon, Polyline, Tooltip } from "react-leaflet"

import React from "react"

const DrawZone = ({ zone, active, baseCoords }) => {
  if (R.isEmpty(zone.coords))
    return (
      <Circle center={baseCoords} radius={400}>
        <Tooltip permanent direction="center">
          Cliquez sur la carte pour placer le premier point
        </Tooltip>
      </Circle>
    )

  const pathOpts = active
    ? { color: "yellow", fillColor: "yellow" }
    : {
        color: "red",
        fillColor: "red",
        opacity: 0.5,
        fillOpacity: 0.1,
        dashArray: "8, 8",
        weight: 1,
      }

  return (
    <>
      {active ? (
        <Polyline pathOptions={pathOpts} positions={zone.coords}>
          <Marker position={R.last(zone.coords)} />
        </Polyline>
      ) : (
        <Polygon pathOptions={pathOpts} positions={zone.coords}>
          <Tooltip permanent direction="center">
            {zone.name}
          </Tooltip>
        </Polygon>
      )}
    </>
  )
}

export default DrawZone
