import * as R from "ramda"

import { createSlice } from "@reduxjs/toolkit"

const motorboatsSlice = createSlice({
  name: "motorboats",
  initialState: {
    fetching: false,
    creating: false,
    results: {},
  },
  reducers: {
    fetchMotorboats: R.assoc(["fetching"], true),
    fetchMotorboatsOk: (state, { payload: { results } }) =>
      R.compose(R.assoc("fetching", false), R.assoc("results", results))(state),
    fetchMotorboatsError: (state, { payload: { error } }) =>
      R.compose(R.assoc("fetching", false), R.assoc("error", error))(state),
    favMotorboat: (state, { payload: { motorboat } }) =>
      R.over(R.lensPath(["results", "user"]), R.append(motorboat), state),
    unfavMotorboat: (state, { payload: { id } }) => {
      const index = R.findIndex(R.propEq("id", id))(
        R.path(["results", "user"], state),
      )
      return R.over(R.lensPath(["results", "user"]), R.remove(index, 1), state)
    },
  },
})

export const {
  fetchMotorboats,
  fetchMotorboatsOk,
  fetchMotorboatsError,
  favMotorboat,
  unfavMotorboat,
} = motorboatsSlice.actions

export default motorboatsSlice.reducer
