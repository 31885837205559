import React, { useEffect, useState } from "react"
import { getUserFromToken, storeTokens } from "../../../../services/AuthService"

import { Container } from "@mui/material"
import Intro from "./Intro"
import Login from "../LogIn"
import { SignUpForm } from "../components"
import { getInvitation } from "../../../../legacyApi"
import { handleError } from "../../../components/formik"
import { invitationSignup } from "../../../../legacyApi"
import { loginOk } from "../../../../state/modules/auth"
import { styleFullHeight } from "../../../styles"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"

const Invitation = () => {
  const { token } = useParams()
  const [invitation, setInvitation] = useState(null)
  const [step, setStep] = useState("intro")
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const fetchInvitation = async () => {
      const result = await getInvitation(token)
      // console.log("result", result)
      setInvitation(result)
    }
    fetchInvitation()
  }, [token])

  if (!invitation) {
    return <p>Loading</p>
  }

  if (invitation.baseuser) return <Login />

  const initialValues = {
    email: invitation.receiver_email,
    password: "",
    lastname: invitation.receiver_lastname,
    firstname: invitation.receiver_firstname,
    mobile: invitation.receiver_mobile,
    pro_card: invitation.receiver_pro_card,
    photo: "",
    key: invitation.key,
    base_id: invitation.base.id,
  }

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      const { data } = await invitationSignup(values)
      setStatus({ ...status, errors: null })
      storeTokens(data)
      dispatch(
        loginOk({
          ...data,
          user: getUserFromToken(),
        }),
      )
      history.push("/")
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  const renderSteps = () => {
    switch (step) {
      case "intro":
        return <Intro setStep={setStep} invitation={invitation} />
      case "form":
        return (
          <SignUpForm
            setStep={setStep}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            title="Inscription sur invitation"
          />
        )
      default:
        throw Error
    }
  }

  return (
    <Container maxWidth="sm">
      <div sx={styleFullHeight}>{renderSteps(step)}</div>
    </Container>
  )
}
export default Invitation
