import { clearMapTarget } from "../../../state/modules/geo"
import { useDispatch } from "react-redux"
import { useMapEvents } from "react-leaflet"
import { useMapTarget } from "../../../services/GeoService"

const ClickOutside = () => {
  const dispatch = useDispatch()
  const mapTarget = useMapTarget()

  const map = useMapEvents({
    click() {
      mapTarget && dispatch(clearMapTarget())
    },
    mousedown() {
      mapTarget && dispatch(clearMapTarget())
    },
  })
  return null
}

export default ClickOutside
