import * as Yup from "yup"

import { Box, Button, ButtonGroup, Paper } from "@mui/material"
import { CheckboxWithLabel, TextField } from "formik-mui"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"

import { PageTitle } from "../../../components"
import PasswordModal from "./PasswordModal"
import { handleError } from "../../../components/formik"
import { setBaseUserOk } from "../../../../state/modules/baseusers"
import { sxForm } from "../../../styles"
import { sxProfile } from ".."
import { updateBaseUserPromise } from "../../../../legacyApi"
import { useDispatch } from "react-redux"
import { useViewerBaseUser } from "../../../../services/UserService"

const ProfilYupSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string().email().required("Requis"),
    lastname: Yup.string().required("Requis"),
    firstname: Yup.string().required("Requis"),
    mobile: Yup.string().phone("FR").required(),
    pro_card: Yup.string().required("Requis"),
  }),
})

const MyProfile = () => {
  const [openPassword, setOpenPassword] = useState(false)
  const viewerBaseUser = useViewerBaseUser()
  const isRtq = viewerBaseUser.role === "rtq"

  const dispatch = useDispatch()

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      const res = await updateBaseUserPromise(values)
      dispatch(setBaseUserOk(res.data))
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <Paper sx={sxProfile.paper}>
      <PasswordModal
        openPassword={openPassword}
        setOpenPassword={setOpenPassword}
      />
      <PageTitle>Mon profil</PageTitle>
      <Formik
        initialValues={viewerBaseUser}
        validationSchema={ProfilYupSchema}
        onSubmit={handleSubmit}
      >
        {({
          submitForm,
          isSubmitting,
          isValid,
          values,
          setFieldValue,
          dirty,
          errors,
        }) => {
          return (
            <Box sx={sxForm}>
              <Form>
                <br />
                <br />
                <br />
                <br />
                <Field
                  component={TextField}
                  name="user.email"
                  type="email"
                  label="E-mail"
                  fullWidth
                  required
                />
                {/* <Button
          style={{ margin: "16px 0" }}
          variant="outlined"
          color="primary"
          onClick={() => setOpenPassword(true)}
        >
          Changer de mot de passe
        </Button> */}
                <br />
                <br />
                <Field
                  component={TextField}
                  name="user.lastname"
                  type="text"
                  label="Nom"
                  fullWidth
                  required
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name="user.firstname"
                  type="text"
                  label="Prénom"
                  fullWidth
                  required
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name="user.mobile"
                  type="phone"
                  label="Téléphone mobile"
                  fullWidth
                  required
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  name="user.pro_card"
                  type="text"
                  label="Numéro de carte professionnelle"
                  fullWidth
                  required
                />

                {isRtq && (
                  <>
                    <br />
                    <br />
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Recevoir les emails d'alerte" }}
                      name="rtq_receive_email"
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Recevoir les SMS d'alerte" }}
                      name="rtq_receive_sms"
                    />
                  </>
                )}
              </Form>
              <br />
              <br />
              <br />
              <ButtonGroup fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    isSubmitting || isValid === false || dirty === false
                  }
                  onClick={submitForm}
                >
                  {isSubmitting ? "Patientez..." : "Enregistrer"}
                </Button>
              </ButtonGroup>
            </Box>
          )
        }}
      </Formik>
    </Paper>
  )
}

export default MyProfile
