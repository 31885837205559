import * as R from "ramda"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { fr } from "date-fns/locale"

import {
  format,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  parseISO,
} from "date-fns"

export const getPeriodStart = (range, date) => {
  switch (range) {
    case "week":
      return startOfWeek(date)
    case "month":
      return startOfMonth(date)
    case "year":
      return startOfYear(date)
    default:
      throw new Error("wrong range in getPeriodStart")
  }
}

export const getPeriodEnd = (range, date) => {
  switch (range) {
    case "week":
      return endOfWeek(date)
    case "month":
      return endOfMonth(date)
    case "year":
      return endOfYear(date)
    default:
      throw new Error("wrong range in getPeriodEnd")
  }
}

export const getTargetPeriod = ({
  range,
  periodStart,
  periodEnd,
  targetDate,
}) => {
  switch (range) {
    case "week":
      return `Du ${format(periodStart, "eee dd/MM", {
        locale: fr,
      })} au 
      ${format(periodEnd, "eee dd/MM", { locale: fr })}`
    case "month":
      return format(targetDate, "MMM yyyy", { locale: fr }).toUpperCase()
    case "year":
      return format(targetDate, "yyyy").toUpperCase()
    default:
      throw new Error(`wrong range in period ${range}`)
  }
}

export const useIncrement = (value) => {
  const [inc, setInc] = useState(0)

  const amount = value ? value / 20 : 0

  useEffect(() => {
    const interval = setInterval(() => {
      if (!value || inc + amount < value) {
        setInc(Math.round(inc + amount))
      } else {
        setInc(value)
        clearInterval(interval)
      }
    }, 10)

    return () => clearInterval(interval)
  }, [inc, setInc, amount, value])

  return inc
}

export const pieOptions = ({ data, title = "" }) => ({
  chart: {
    type: "pie",
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
  },
  tooltip: {
    pointFormat: "{series.name}\n<b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  title: { text: title },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        // format: "{point.name}\n<b>{point.percentage:.1f}%</b>",
      },
    },
  },
  series: [
    {
      data: data.map(({ id, name, amount }) => ({
        name,
        y: amount * 100,
      })),
    },
  ],
})

export const useTargetDate = () =>
  parseISO(useSelector(R.path(["reporting", "dateInfos", "targetDate"])))

export const useDateInfos = () => {
  const range = useSelector(R.path(["reporting", "dateInfos", "range"]))
  const targetDate = useTargetDate()

  const periodStart = getPeriodStart(range, targetDate)
  const periodEnd = getPeriodEnd(range, targetDate)

  const targetPeriod = getTargetPeriod({
    range,
    periodStart,
    periodEnd,
    targetDate,
  })

  return {
    periodStart,
    periodEnd,
    targetPeriod,
  }
}
