import * as R from "ramda"
import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { useActiveBaseId } from "../../../services/BaseService"
import { useViewerOngoingNav } from "../../../services/UserService"
import { usePrevious } from "../../../services/MainService"

import { FullScreen } from "../../components"
import Idle from "./Idle"
import InNav from "./NewInNav"
// import InNav from "./InNav"

const CoachHome = () => {
  const { push } = useHistory()
  const baseId = useActiveBaseId()
  const base = useSelector(R.pathOr({}, ["bases", baseId]))
  const nav = useViewerOngoingNav()

  const previousStatus = usePrevious(nav?.status)
  const previousId = usePrevious(nav?.id)
  console.log("previousStatus: ", previousStatus)

  if (previousStatus === "ONGOING" && !nav) {
    console.log("Nav finished!")
    push(`/navs/${previousId}/debrief`)
  }

  const lastPos = useSelector(R.path(["geo", "lastPos"]))

  if (lastPos?.error) return <FullScreen text={lastPos.error} />

  return <>{nav ? <InNav nav={nav} /> : <Idle base={base} />}</>
}

export default CoachHome
