import React from "react"

import { useNavOwner } from "../../../../services/BaseService"
import { useViewerBaseUser } from "../../../../services/UserService"
import { navTimeInfos } from "../../../../services/NavService"

import { Typography } from "@mui/material"

export const NavInfos = ({ nav, title = true }) => {
  const navOwner = useNavOwner(nav)
  const viewerBaseUser = useViewerBaseUser()

  const viewerIsOwner = navOwner.id === viewerBaseUser.user.id

  return (
    <>
      {title && (
        <Typography color="primary" variant="h4">
          {viewerIsOwner ? "Ma navigation" : `Nav de ${navOwner.fullname}`}{" "}
        </Typography>
      )}
      <Typography variant="body1" gutterBottom>
        {navTimeInfos(nav)}
      </Typography>
    </>
  )
}
