import { Box, Typography } from "@mui/material"

import React from "react"
import { sxBlock } from "../styles"

const Block = ({ title, body, children }) => (
  <Box sx={sxBlock}>
    <Typography variant="body2" color="primary">
      {title.toUpperCase()}
    </Typography>
    {body ?? (
      <Typography variant="h6" gutterBottom>
        {body}
      </Typography>
    )}
    {children}
  </Box>
)

export default Block
