import * as R from "ramda"

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material"
import { Field, FieldArray, Form, Formik } from "formik"
import React, { useState } from "react"

import { CollapsibleAlert } from "../../../components"
import DrawModal from "./DrawModal"
import ReactMarkdown from "react-markdown"
import { TextField } from "formik-mui"
import ZoneItem from "./ZoneItem"
import { handleError } from "../../../components/formik"
import { setBase } from "../../../../state/modules/bases"
import { sxForm } from "../../../styles"
import { updateBasePromise } from "../../../../legacyApi"
import { useDispatch } from "react-redux"

const newZone = { name: "", description: "", coords: "" }

const Update = ({ base, setEdit }) => {
  const [preview, setPreview] = useState(false)
  const [draw, setDraw] = useState(false)
  const [zoneIndex, setZoneIndex] = useState(null)
  console.log("zoneIndex: ", zoneIndex)
  const setZone = (index) => {
    setZoneIndex(index)
    setDraw(true)
  }
  const clearZone = () => {
    setDraw(false)
    setZoneIndex(null)
  }

  const handlePreview = () => setPreview(!preview)

  const dispatch = useDispatch()

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      await updateBasePromise(values)
      setStatus({ ...status, errors: null })
      dispatch(setBase(values))
      setEdit(false)
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <Formik
      initialValues={base}
      // validationSchema={ChangeMe}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => {
        const isEqual = R.equals(base, values)

        return (
          <Box sx={sxForm}>
            <Form>
              <Typography
                color="primary"
                align="center"
                variant="h4"
                gutterBottom
              >
                Editer la base
              </Typography>
              <Field
                component={TextField}
                name="name"
                type="text"
                label="Nom de la base"
                variant="outlined"
                fullWidth
                required
              />
              <br />
              <br />
              <Field
                component={TextField}
                name="description"
                type="text"
                label="Texte de description"
                variant="outlined"
                fullWidth
                multiline
                rows={8}
              />
              <br />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={preview}
                    onChange={handlePreview}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Prévisualiser le règlement intérieur"
              />

              {preview ? (
                <ReactMarkdown>{values.rules_n_regulations}</ReactMarkdown>
              ) : (
                <>
                  <CollapsibleAlert
                    title="La mise en forme du règlement intérieur se fait en Markdown"
                    severity="info"
                  >
                    <a
                      href="https://fr.wikipedia.org/wiki/Markdown#Quelques_exemples"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Des explications sur la syntaxe markdown disponibles ici.
                    </a>
                  </CollapsibleAlert>

                  <Field
                    component={TextField}
                    name="rules_n_regulations"
                    type="text"
                    label="Règlement intérieur"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={24}
                  />
                </>
              )}
              <br />
              <br />
              <Field
                component={TextField}
                name="emergency_vhf"
                type="text"
                label="Canal VHF"
                variant="outlined"
                fullWidth
                multiline
                rows={8}
              />
              <br />
              <br />
              <Field
                component={TextField}
                name="emergency_phone"
                type="text"
                label="Téléphone d'urgence"
                variant="outlined"
                fullWidth
                multiline
                rows={8}
              />
              <br />
              <br />
              <Field
                component={TextField}
                name="coords"
                type="text"
                label="Position sur la carte"
                variant="outlined"
                fullWidth
                multiline
                rows={8}
              />
              <br />
              <br />

              <Typography
                color="primary"
                align="center"
                variant="h5"
                gutterBottom
              >
                Zones de navigation
              </Typography>
              <FieldArray name="zones">
                {(arrayHelpers) => (
                  <div>
                    <Grid container spacing={2}>
                      {values.zones.map((zone, index) => (
                        <Grid key={index} item xs={6}>
                          <ZoneItem
                            arrayHelpers={arrayHelpers}
                            zoneIndex={index}
                            zone={zone}
                            setZone={setZone}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <br />

                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => arrayHelpers.push(newZone)}
                    >
                      Ajouter une zone
                    </Button>
                  </div>
                )}
              </FieldArray>

              <br />
              <br />

              <Button
                variant="contained"
                color="primary"
                disabled={isEqual || isSubmitting}
                onClick={submitForm}
                fullWidth
              >
                {isSubmitting
                  ? "Patientez..."
                  : "Enregistrer les modifications"}
              </Button>
              {!R.isNil(zoneIndex) && (
                <DrawModal
                  show={draw}
                  zones={values.zones}
                  zoneIndex={zoneIndex}
                  baseCoords={base.coords}
                  clearZone={clearZone}
                  setFieldValue={setFieldValue}
                />
              )}
            </Form>
          </Box>
        )
      }}
    </Formik>
  )
}

export default Update
