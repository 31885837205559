import { createSlice } from "@reduxjs/toolkit"
import * as R from "ramda"

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    enqueueSnackbar: (state, { payload }) => {
      const key = payload.options && payload.options.key
      return R.append({ key, ...payload }, state)
    },
    closeSnackbar: (state, { payload: { key, dismissAll } }) =>
      R.map(
        (notification) =>
          dismissAll || notification.key === key
            ? R.assoc("dismissed", true, notification)
            : notification,
        state,
      ),
    removeSnackbar: (state, { payload: { key } }) =>
      R.reject(R.propEq("key", key), state),

    addInfo: R.identity,
    addError: R.identity,
    addSuccess: R.identity,
    removeNotification: R.identity,
  },
})

// Action creators are generated for each case reducer function
export const {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,

  addError,
  addInfo,
  addSuccess,
  removeNotification,
} = notificationsSlice.actions

export default notificationsSlice.reducer
