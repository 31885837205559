import React from "react"

import { useTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import EventNoteIcon from "@mui/icons-material/EventNote"
import AddAlertIcon from "@mui/icons-material/AddAlert"
import EditIcon from "@mui/icons-material/Edit"

import NavInfos from "./NavInfos"
import EventList from "./EventList"
import Alert from "./Alert"
import Edit from "./Edit"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const InNav = ({ nav }) => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
      }}
    >
      <Box sx={{ pb: "72px" }}>
        <NavInfos nav={nav} />
        <TabPanel value={value} index={0} dir={theme.direction}>
          <EventList events={nav.events} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Alert nav={nav} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Edit nav={nav} />
        </TabPanel>
      </Box>
      <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab icon={<EventNoteIcon />} label="Log" {...a11yProps(0)} />
          <Tab icon={<AddAlertIcon />} label="Alerter" {...a11yProps(1)} />
          <Tab icon={<EditIcon />} label="Modifier" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
    </Box>
  )
}

export default InNav
