import React from "react"
import { Box, Button, ButtonGroup, Typography } from "@mui/material"
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded"

import { useBaseRtqUsers } from "../../../services/BaseService.js"
import { PageTitle } from "../../components"

const style = { marginBottom: "30px", borderRadius: "4px" }

const Contacts = () => {
  const rtqUsers = useBaseRtqUsers()

  return (
    <Box p={3} style={{ maxWidth: "500px", margin: "auto" }}>
      <PageTitle style={{ marginBottom: "20px" }}>Numéros utiles</PageTitle>
      <ButtonGroup orientation="vertical" fullWidth={true} color="primary">
        // TODO uniquement Benjamin Glorennec. AJouter clé public
        {/* {rtqUsers.map((e) => (
          <>
            <Typography align="center" variant="p" color="primary">
              {`RTQ: ${e.user.firstname} ${e.user.lastName}`}
            </Typography>
            <Button
              variant="contained"
              href={`tel:${e.user.mobile}`}
              style={style}
              startIcon={<PhoneRoundedIcon />}
              color="primary"
            >
              {e.user.mobile}
            </Button>
          </>
        ))} */}
        <Typography align="center" variant="p">
          ENVSN Permanence techinque : Benjamin Glorennec
        </Typography>
        <Button
          variant="contained"
          href="tel:0602156661"
          style={style}
          startIcon={<PhoneRoundedIcon />}
        >
          06 02 15 66 61
        </Button>
        <Typography align="center" variant="p">
          ENVSN Permanence technique
        </Typography>
        <Button
          variant="contained"
          href="tel:0297303040"
          style={style}
          startIcon={<PhoneRoundedIcon />}
        >
          02 97 30 30 40
        </Button>
        <Typography align="center" variant="p">
          Administrateur d'astreinte
        </Typography>
        <Button
          variant="contained"
          href="tel:0672714548"
          style={style}
          startIcon={<PhoneRoundedIcon />}
        >
          06 72 71 45 48
        </Button>
        <Typography align="center" variant="p">
          ENVSN Accueil
        </Typography>
        <Button
          variant="contained"
          href="tel:0297303030"
          style={style}
          startIcon={<PhoneRoundedIcon />}
        >
          02 97 30 30 30
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default Contacts
