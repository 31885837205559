import authEpics from "./modules/auth/epics"
import baseUserEpics from "./modules/baseusers/epics"
import { combineEpics } from "redux-observable"
import geoEpics from "./modules/geo/epics"
import motorboatsEpics from "./modules/motorboats/epics"
import navsEpics from "./modules/navs/epics"
import reportingEpics from "./modules/reporting/epics"
import supportsEpics from "./modules/supports/epics"
import webSocketEpics from "./modules/webSocket/epics"

const rootEpic = combineEpics(
  authEpics,
  baseUserEpics,
  geoEpics,
  motorboatsEpics,
  navsEpics,
  reportingEpics,
  supportsEpics,
  webSocketEpics,
)

export default rootEpic
