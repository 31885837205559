import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { fetchSupports, fetchSupportsError, fetchSupportsOk } from "."

import { API_HTTP } from "../../../config"

const fetchSupportsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchSupports.type),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const baseId = state.auth.user.active_base_id
      return ajax({
        method: "GET",
        url: `${API_HTTP}/bases/${baseId}/supports/`,
      }).pipe(
        map(({ response }) => {
          return fetchSupportsOk({ results: response })
        }),
        catchError((error) => fetchSupportsError(error.xhr.response.detail)),
      )
    }),
  )

export default combineEpics(fetchSupportsEpic)
