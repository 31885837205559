import {
  Button,
  ButtonGroup,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
} from "@mui/material"

import React from "react"

export const ButtonSelect = (props) => {
  const { name, label, options } = props
  return (
    <FormGroup>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <ButtonGroup>
        {options.map((option) => (
          <Button onClick={() => props.form.setFieldValue(option.value)}>
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </FormGroup>
  )
}
