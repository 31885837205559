import * as R from "ramda"

import { useNav, useNavs } from "../../../services/NavService"

import ClickOutside from "./ClickOutside"
import CoordPopup from "./CoordPopup"
import MapBase from "./MapBase"
import MapContainer from "./MapContainer"
import MapNav from "./MapNav"
import MapUser from "./MapUser"
import React from "react"
import { useBase } from "../../../services/BaseService"

export const Map = ({ date = new Date(), selectedNavId = null }) => {
  const base = useBase()
  const allNavs = useNavs({ date, status: "ONGOING" })
  const { greenNavs, alertNavs } = R.compose(
    R.reduceBy(
      (acc, nav) => acc.concat(nav),
      [],
      (nav) => (nav.alert_status === "alert" ? "alertNavs" : "greenNavs"),
    ),
    R.map((nav) =>
      R.when(
        (nav) => nav.id === parseInt(selectedNavId),
        R.assoc("selected", true),
        nav,
      ),
    ),
  )(allNavs)

  return (
    <MapContainer
      center={base.coords}
      zoom={12}
      style={{
        width: "100%",
        height: "100vh",
        margin: "0",
      }}
      zoomControl={false}
    >
      <CoordPopup />
      {allNavs.length > 0 ? (
        <>
          {greenNavs?.map((nav, index) => (
            <MapNav
              key={nav.id}
              nav={nav}
              navIndex={index}
              navCount={greenNavs.length}
            />
          ))}
          {alertNavs?.map((nav, index) => (
            <MapNav
              key={nav.id}
              nav={nav}
              navIndex={index}
              navCount={alertNavs.length}
              alert
            />
          ))}
        </>
      ) : (
        <div>loading</div>
      )}

      <MapBase base={base} />
      <MapUser />
      <ClickOutside />
    </MapContainer>
  )
}

export default Map
