export const styleLeftFab = {
  position: "fixed",
  top: "12px",
  left: "12px",
  zIndex: 1300,
}

export const styleFullHeight = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
}
