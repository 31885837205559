import * as R from "ramda"
import React, { useState } from "react"

import { Field, Form, Formik } from "formik"

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepButton from "@mui/material/StepButton"
import StepLabel from "@mui/material/StepLabel"
import StepContent from "@mui/material/StepContent"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

import EditIcon from "@mui/icons-material/Edit"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import SaveIcon from "@mui/icons-material/Save"
import StartIcon from "@mui/icons-material/Start"

import { getSteps } from "./steps"

import { AlertTitle } from "@mui/material"

const NavStepper = (props) => {
  const { kindIsNow, values, handleSubmit } = props
  const [activeStep, setActiveStep] = useState(0)
  const [completedSteps, setCompletedSteps] = useState({})

  const steps = getSteps(kindIsNow)

  const isCompleted = (step) => R.has(step, completedSteps)

  const handleNext = () => {
    setCompletedSteps({ ...completedSteps, [activeStep]: true })
    const findNextActiveStep = (step) => {
      const nextStep = step + 1
      return !R.has(nextStep, completedSteps)
        ? nextStep
        : findNextActiveStep(nextStep)
    }
    const newActiveStep = findNextActiveStep(activeStep)
    setActiveStep(newActiveStep)
  }

  const handleEditCompleted = (step) => () => {
    setActiveStep(step)
    setCompletedSteps(R.dissoc(step), completedSteps)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompletedSteps({})
  }

  return (
    <Box>
      {/* <Box sx={{ maxWidth: 800 }}> */}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((stepData, step) => {
          const filteredValues = R.pick(stepData.fields, values)
          const notLastStep = step !== steps.length - 1

          return (
            <Step key={stepData.id} completed={completedSteps[step]}>
              <StepButton
                component="div"
                color="inherit"
                onClick={handleEditCompleted(step)}
              >
                <StepLabel
                  optional={
                    step === 5 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  <Typography variant="h6">
                    {isCompleted(step) ? (
                      <>
                        {stepData.completedTitle(filteredValues)}
                        <IconButton size="small">
                          <EditIcon
                            fontSize="inherit"
                            color="primary"
                            size="small"
                          />
                        </IconButton>
                      </>
                    ) : (
                      <>{stepData.initialTitle}</>
                    )}
                  </Typography>
                </StepLabel>
              </StepButton>
              <StepContent>
                {!R.isEmpty(stepData.helperText) && (
                  <Alert severity="info">
                    {stepData.helperText.title && (
                      <AlertTitle>{stepData.helperText.title}</AlertTitle>
                    )}
                    {stepData.helperText.body}
                  </Alert>
                )}

                {stepData.content(filteredValues)}
                <Box sx={{ mb: 2 }}>
                  <ButtonGroup fullWidth>
                    <Button
                      disabled={step === 0}
                      onClick={handleBack}
                      sx={{ mt: 1 }}
                      startIcon={<KeyboardArrowUpIcon />}
                    >
                      Retour
                    </Button>
                    <Button
                      variant="contained"
                      onClick={notLastStep ? handleNext : handleSubmit}
                      sx={{ mt: 1 }}
                      startIcon={
                        notLastStep ? (
                          <KeyboardArrowDownIcon />
                        ) : kindIsNow ? (
                          <StartIcon />
                        ) : (
                          <SaveIcon />
                        )
                      }
                      disabled={stepData.disabled(filteredValues)}
                    >
                      {notLastStep
                        ? "Continuer"
                        : kindIsNow
                        ? "Démarrer"
                        : "Enregistrer"}
                    </Button>
                  </ButtonGroup>
                </Box>
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>
            All steps completedSteps - you&apos;re finished
          </Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  )
}
export default NavStepper
