import { Box, Container } from "@mui/material"

import { HeaderWithIcon } from "../../components"
import { Home } from "@mui/icons-material"
import React from "react"
// import Step1 from "./Step1"
// import Step2 from "./Step2"
import Step3 from "./Step3"
import { styleFullHeight } from "../../styles"

const Welcome = () => {
  // const [step, setStep] = useState(1)

  // const prev = step > 1 && step - 1
  // const next = step < 3 && step + 1
  // // const handlePrev = () => step > 1 && setStep(prev)
  // const handleNext = () => step < 3 && setStep(next)

  // const renderStep = () => {
  //   switch (step) {
  //     case 1:
  //       return <Step1 />
  //     case 2:
  //       return <Step2 />
  //     case 3:
  //       return <Step3 handlePrev={handlePrev} />
  //     default:
  //       throw new Error("Base")
  //   }
  // }

  return (
    <Container maxWidth="xs">
      <Box sx={styleFullHeight.paper}>
        <HeaderWithIcon
          title="Règlement intérieur de la base"
          icon={<Home />}
        />
        <Step3 />

        {/* temporary deactivated */}
        {/* {renderStep()}
        {step !== 3 && (
          <ButtonGroup color="primary" fullWidth className={d.submit}>
            <Button disabled={!prev} onClick={handlePrev}>
              Précédent
            </Button>
            <Button disabled={!next} onClick={handleNext}>
              Suivant
            </Button>
          </ButtonGroup>
        )} */}
      </Box>
    </Container>
  )
}

export default Welcome
