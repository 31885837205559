import * as R from "ramda"

import { Drawer, Fab } from "@mui/material"
import React, { useState } from "react"
import {
  useViewerBaseUser,
  useViewerOngoingNav,
} from "../../../services/UserService"

import ErrorBoundary from "./ErrorBoundary"
import { LoadScreen } from "../../components"
import Menu from "./Menu"
import { Menu as MenuIcon } from "@mui/icons-material"
import NavModal from "./NavModal"
import Notifications from "./Notifications"
import Routing from "./Routing"
import Welcome from "../../pages/Welcome"
import { styleLeftFab } from "../../styles"
import { useActiveBaseId } from "../../../services/BaseService"
import { useConnectWebSocket } from "../../../services/WebSocketService"
import { useFetchBaseAndUsers } from "../../../services/MainService"
import { useSelector } from "react-redux"

const Authenticated = () => {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)
  const tokenCheck = useSelector(R.path(["auth", "tokenCheck"]))
  const baseId = useActiveBaseId()
  const base = useSelector(R.path(["bases", `${baseId}`]))
  const baseuser = useViewerBaseUser()
  const nav = useViewerOngoingNav()

  const hideBurger = nav || showMenu

  useFetchBaseAndUsers()
  useConnectWebSocket()

  if (tokenCheck) {
    return <LoadScreen text="Vérification de votre identité" />
  }

  if (!baseId || !base) {
    return <LoadScreen text="Chargement de la base" />
  }

  if (!baseuser) return <LoadScreen text="Chargement de vos informations" />

  if (!baseuser.rules_n_regulations) return <Welcome />

  return (
    <>
      {!hideBurger && (
        <Fab
          onClick={toggleMenu}
          aria-label="menu"
          sx={styleLeftFab}
          data-cy="toggle-main-menu"
        >
          <MenuIcon />
        </Fab>
      )}
      <Drawer anchor="left" open={showMenu} onClose={toggleMenu}>
        <Menu toggleMenu={toggleMenu} />
      </Drawer>

      <ErrorBoundary>
        <Routing role={baseuser.role} />
      </ErrorBoundary>

      <Notifications />
      <NavModal />
    </>
  )
}

export default Authenticated
