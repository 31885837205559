import { Paper, Stack, Typography } from "@mui/material"
import { FieldArray } from "formik"
import React from "react"
import { Switch, TextField } from "../../../../../components/formik"

const MotorboatForm = ({ motorboatreport, index }) => {
  return (
    <Stack spacing={1} key={index}>
      <Typography variant="h6" align="center">
        {motorboatreport.motorboat_name}
      </Typography>
      <TextField
        name={`motorboatreports.${index}.body`}
        label={`Problèmes avec le ${motorboatreport.motorboat_name}`}
        fullWidth
        multiline
        rows={4}
      />
      <Switch
        name={`motorboatreports.${index}.solved`}
        label="Problème résolu"
      />
    </Stack>
  )
}

const MotorboatsForm = ({ motorboatreports }) => (
  <FieldArray name="motorboatreports">
    {(arrayHelpers) => (
      <Paper
        variant="outlined"
        sx={{
          p: (theme) => theme.spacing(1),
        }}
      >
        {motorboatreports.map((motorboatreport, index) => (
          <MotorboatForm
            key={index}
            motorboatreport={motorboatreport}
            index={index}
          />
        ))}
      </Paper>
    )}
  </FieldArray>
)

export default MotorboatsForm
