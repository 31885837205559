import * as R from "ramda"

import { Chip } from "@mui/material"
import React from "react"
import { translateStatus } from "../../../../../services/NavService"

const StatusChip = ({ status, alert_status }) => {
  const getColor = () => {
    switch (status) {
      case "PLANNED":
        return "info"
      case "ONGOING":
        return "warning"
      case "FINISHED":
        return "warning"
      case "DEBRIEFED":
        return "success"
      default:
        return ""
    }
  }
  return (
    <Chip
      label={R.toUpper(translateStatus({ status, alert_status }))}
      color={alert_status ? "secondary" : getColor()}
    />
  )
}

export default StatusChip
