import {
  AssignmentTurnedIn,
  Call,
  ExitToApp,
  NotificationsOff,
  PhoneRounded,
  PlayArrow,
  Settings,
} from "@mui/icons-material"
import { Button, ButtonGroup, Dialog } from "@mui/material"
import React, { useState } from "react"

import { FullScreen } from "../../../../components"
import { formatISO } from "date-fns"
import { getPos } from "../../../../../services/GeoService"
import { setNavInModal } from "../../../../../state/modules/navs"
import { sxNavDetail } from "../styles"
import { updateNavWS } from "../../../../../state/modules/navs"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

const NavActions = ({
  nav,
  navOwner,
  viewerBaseUser,
  viewerIsOwner,
  viewerIsRTQ,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  let buttons = []

  const openNavModal = async () => {
    dispatch(setNavInModal({ ...nav, kind: "modify" }))
  }

  const handleSubmitStartNav = async () => {
    setLoading(true)

    const pos = await getPos()

    dispatch(
      updateNavWS({
        ...nav,
        status: "ONGOING",
        real_start: formatISO(new Date()),
        event: { ...pos, kind: "start" },
      }),
    )
    setLoading(false)
  }

  const handleSolvedByRTQ = () => {
    dispatch(
      updateNavWS({
        ...nav,
        event: {
          kind: "solved",
          timestamp: Date.now(),
          desc: `Solved by RTQ (baseuser_id: ${viewerBaseUser.id}, email: ${viewerBaseUser.user.email}).`,
        },
      }),
    )
  }

  const handleCloseByRTQ = () =>
    dispatch(
      updateNavWS({
        ...nav,
        status: "FINISHED",
        event: {
          kind: "finish",
          timestamp: Date.now(),
          desc: `Closed by RTQ (baseuser_id: ${viewerBaseUser.id}, email: ${viewerBaseUser.user.email}).`,
        },
      }),
    )

  // everyone
  if (!viewerIsOwner) {
    buttons.push(
      <Button
        key="callNavOwner"
        variant="contained"
        color="primary"
        sx={sxNavDetail.button}
        startIcon={<PhoneRounded />}
        href={`tel:${navOwner.mobile}`}
      >
        Appeler l'entraîneur
      </Button>,
    )
  }

  // coach actions
  if (viewerIsOwner) {
    if (nav.status === "PLANNED") {
      buttons.push(
        <Button
          key="modify"
          variant="outlined"
          color="secondary"
          sx={sxNavDetail.button}
          startIcon={<Settings />}
          onClick={openNavModal}
          data-cy="edit-nav"
        >
          Modifier la nav
        </Button>,
      )

      buttons.push(
        <Button
          key="start"
          variant="contained"
          color="primary"
          sx={sxNavDetail.button}
          startIcon={<PlayArrow />}
          onClick={handleSubmitStartNav}
          data-cy="start-nav"
        >
          Démarrer tout de suite
        </Button>,
      )
    }
    if (nav.status === "ONGOING") {
      buttons.push(
        <Button
          key="callRTQ"
          variant="contained"
          color="primary"
          sx={sxNavDetail.button}
          startIcon={<Call />}
          href="tel://+1234567890"
        >
          Appeler le coach
        </Button>,
      )
    }
    if (!nav.report) {
      buttons.push(
        <Button
          key="writeDebrief"
          variant="contained"
          color="primary"
          sx={sxNavDetail.button}
          startIcon={<AssignmentTurnedIn />}
          onClick={() => history.push(`/navs/${nav.id}/debrief`)}
        >
          Debriefer la nav
        </Button>,
      )
    }
  }

  // rtq actions
  if (viewerIsRTQ) {
    if (nav.status === "ONGOING") {
      if (nav.alert_status) {
        buttons.push(
          <Button
            key="closeAlert"
            variant="contained"
            color="primary"
            sx={sxNavDetail.button}
            startIcon={<NotificationsOff />}
            onClick={handleSolvedByRTQ}
          >
            Cloturer l'alerte
          </Button>,
        )
      } else {
        buttons.push(
          <Button
            key="callRTQ"
            variant="contained"
            color="primary"
            sx={sxNavDetail.button}
            startIcon={<ExitToApp />}
            onClick={handleCloseByRTQ}
          >
            Clôturer la nav
          </Button>,
        )
      }
    }
  }

  return (
    <>
      <Dialog fullWidth open={loading}>
        <FullScreen
          text="Acquisition GPS et démarrage de la session..."
          severity="info"
        />
      </Dialog>

      <ButtonGroup orientation="vertical" fullWidth>
        {buttons}
      </ButtonGroup>
    </>
  )
}
export default NavActions
