import { Chip } from "@mui/material"
import React from "react"
import { useZone } from "../../../../../services/BaseService"

const ZoneItem = ({ zoneId }) => {
  const zone = useZone(zoneId)
  return <Chip key={zoneId} label={zone.name} variant="outlined" />
}

export default ZoneItem
