import { Button, ButtonGroup, Paper } from "@mui/material"

import { PageTitle } from "../../../components"
import React from "react"
import { sxProfile } from ".."

const WarehouseSection = () => {
  return (
    <>
      <Paper sx={sxProfile.paper}>
        <PageTitle>Mes semi-rigides</PageTitle>
      </Paper>
      <Paper sx={sxProfile.paper}>
        <PageTitle>Mes supports</PageTitle>
      </Paper>
    </>
  )
}

export default WarehouseSection
