import React, { useState } from "react"

import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import CloseIcon from "@mui/icons-material/Close"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"

export const CollapsibleAlert = ({ children, title = null, ...props }) => {
  const [open, setOpen] = useState(true)

  return (
    <div sx={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          {...props}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {children}
        </Alert>
        <br />
      </Collapse>
    </div>
  )
}
