import { API_HTTP } from "../config"
import axios from "axios"

export const fetchCategories = async () =>
  axios.get(`${API_HTTP}/warehouse/categories/`)

export const fetcSupports = async (baseId) => {
  const response = await axios.get(`${API_HTTP}/bases/${baseId}/supports/`)
  return response.data
}

export const fetchMotorboats = async (baseId) =>
  axios.get(`${API_HTTP}/warehouse/motorboats/`)

export const createSupportFav = async (values) => {
  const response = await axios.post(
    `${API_HTTP}/warehouse/supportfavs/`,
    values,
  )
  return response.data
}

export const deleteSupportFav = async (supportId) =>
  axios.delete(`${API_HTTP}/warehouse/supportfavs/${supportId}/`)

export const createMotorboatFav = async (values) => {
  const response = await axios.post(
    `${API_HTTP}/warehouse/motorboatfavs/`,
    values,
  )
  return response.data
}

export const deleteMotorboatFav = async (motorboatId) =>
  axios.delete(`${API_HTTP}/warehouse/motorboatfavs/${motorboatId}/`)
