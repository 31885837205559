import { Box, Button, ButtonGroup } from "@mui/material"

import { PageTitle } from "../../components"
import React from "react"

const style = { marginBottom: "30px", borderRadius: "4px" }

const Links = () => (
  <Box p={3}>
    <PageTitle style={{ marginBottom: "40px" }}>Liens utiles</PageTitle>

    <ButtonGroup
      orientation="vertical"
      fullWidth={true}
      size="large"
      color="primary"
    >
      <Button
        variant="contained"
        href="https://www.windmorbihan.com"
        target="_blank"
        style={style}
      >
        Wind Morbihan
      </Button>

      <Button
        variant="contained"
        href="https://meteofrance.com/meteo-marine"
        target="_blank"
        style={style}
      >
        Meteo France
      </Button>

      <Button
        variant="contained"
        href="https://www.windguru.cz/18"
        target="_blank"
        style={style}
      >
        Winduguru
      </Button>

      <Button
        variant="contained"
        href="https://www.meteociel.fr/"
        target="_blank"
        style={style}
      >
        Meteociel
      </Button>

      <Button
        variant="contained"
        href="https://www.envsn.sports.gouv.fr/"
        target="_blank"
        style={style}
      >
        ENVSN
      </Button>
    </ButtonGroup>
  </Box>
)

export default Links
