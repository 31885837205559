import { Card, CardContent, Container, Fab } from "@mui/material"
import { Clear, Edit } from "@mui/icons-material"
import React, { useState } from "react"

import Show from "./Show"
import Update from "./Update"
import { sxRightFab } from "../../styles"
import { useActiveBase } from "../../../services/BaseService"
import { useViewerBaseUser } from "../../../services/UserService"

const Base = () => {
  const [edit, setEdit] = useState(false)

  const base = useActiveBase()
  const viewerBaseUser = useViewerBaseUser()

  return (
    <>
      <Container maxWidth="sm">
        <Card style={{ position: "relative" }}>
          {viewerBaseUser.role === "rtq" && (
            <Fab sx={sxRightFab} color="primary" aria-label="add">
              {!edit ? (
                <Edit onClick={() => setEdit(true)} />
              ) : (
                <Clear onClick={() => setEdit(false)} />
              )}
            </Fab>
          )}
          <CardContent>
            {!edit ? (
              <Show base={base} viewerBaseUser={viewerBaseUser} />
            ) : (
              <Update base={base} setEdit={setEdit} />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

export default Base
