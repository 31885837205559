import * as R from "ramda"

import { Field, FieldArray } from "formik"
import { Paper, Stack, Typography } from "@mui/material"

import React from "react"
import { TextField } from "formik-mui"

const NavSupportDebriefs = ({ navsupports }) => (
  <FieldArray name="navsupports">
    {(arrayHelpers) => (
      <Paper
        variant="outlined"
        sx={{
          p: (theme) => theme.spacing(1),
        }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          Les supports
        </Typography>
        <Stack spacing={2}>
          {navsupports.map((ns, index) => {
            const nsPath = `navsupports.${index}`
            return (
              <Field
                key={nsPath}
                component={TextField}
                name={`${nsPath}.debrief`}
                type="text"
                label={ns.support.name}
                fullWidth
                multiline
                rows={4}
                data-cy={`input-${nsPath}.debrief`}
              />
            )
          })}
        </Stack>
      </Paper>
    )}
  </FieldArray>
)

export default NavSupportDebriefs
