import * as R from "ramda"
import { Box, Button, Typography } from "@mui/material"

import React from "react"
import { useHistory } from "react-router-dom"
import { sxBlock } from "../../styles"

const DebriefBlock = ({ nav }) => {
  const history = useHistory()

  if (["ONGOING", "PLANNED"].includes(nav.status)) {
    return null
  }

  const debriefSummary = () => {
    let summary = []
    if (!R.isEmpty(nav.report.human)) {
      console.log("nav.report.human: ", nav.report.human)
      summary.push("humain")
    }
    if (!R.isEmpty(nav.report.motorboatreports)) {
      summary.push("semi-rigide")
    }
    if (!R.isEmpty(nav.report.supportreports)) {
      summary.push("support")
    }
    return `Debrief disponible (${summary.join(", ")})`
  }

  return (
    <Box sx={sxBlock}>
      <Typography variant="body2" color="primary">
        DEBRIEF
      </Typography>
      {!nav.report ? (
        <Typography variant="body2" gutterBottom>
          <em>Pas encore renseigné</em>
        </Typography>
      ) : nav.report.all_clear ? (
        <Typography variant="body2" gutterBottom>
          <em>Rien à signaler</em>
        </Typography>
      ) : (
        <>
          <Typography variant="body2" gutterBottom>
            <em>{debriefSummary()}</em>
          </Typography>
          <Button
            variant="outlined"
            onClick={() => history.push(`/navs/${nav.id}/debrief`)}
          >
            Voir le debrief
          </Button>
        </>
      )}
    </Box>
  )
}
export default DebriefBlock
