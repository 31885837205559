import * as R from "ramda"

import { Alert } from "@mui/material"
import { Button } from "@mui/material"
import React from "react"
import { accord } from "../../../../services/MainService"
import { parseISO } from "date-fns"
import { sxNavList } from "./styles"

const PasNavsAlert = ({ onGoingPastNavs, setDate }) => {
  const handleAction = () => {
    setDate(
      R.compose(
        R.tap(console.log),
        parseISO,
        R.prop("real_start"),
        R.last,
      )(onGoingPastNavs),
    )
  }
  if (onGoingPastNavs)
    return (
      <Alert
        sx={sxNavList.alert}
        severity="error"
        variant="filled"
        action={
          <Button color="inherit" size="small" onClick={handleAction}>
            VOIR
          </Button>
        }
      >
        Il y a {onGoingPastNavs.length} {accord("nav", onGoingPastNavs)}{" "}
        {accord("passée", onGoingPastNavs)} non{" "}
        {accord("clôturée", onGoingPastNavs)}.
      </Alert>
    )
  return null
}

export default PasNavsAlert
