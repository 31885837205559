import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

import React from "react"
import ReactMarkdown from "react-markdown"

const RNRModal = ({ showRNR, toggleRNR, rnr }) => (
  <Dialog
    open={showRNR}
    onClose={toggleRNR}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle id="scroll-dialog-title">Le règlement intérieur</DialogTitle>
    <DialogContent dividers>
      <ReactMarkdown>{rnr}</ReactMarkdown>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleRNR} color="primary">
        Fermer
      </Button>
    </DialogActions>
  </Dialog>
)

export default RNRModal
