import { Box, Grid } from "@mui/material"
import MyProfile from "./MyProfile"
import MyWarehouse from "./MyWarehouse"
import React from "react"

export const sxProfile = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: (theme) => theme.spacing(2),
    textAlign: "center",
    color: (theme) => theme.palette.text.secondary,
  },
}
const Profile = () => {
  return (
    <Box sx={sxProfile.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MyProfile />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyWarehouse />
        </Grid>
      </Grid>
    </Box>
  )
}
export default Profile
