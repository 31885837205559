import { Marker, Popup, useMap } from "react-leaflet"
import { useLastPos, useMapTarget } from "../../../../services/GeoService"

import MapIcon from "../MapIcon"
import React from "react"
import { formatDistance } from "date-fns"
import { fr } from "date-fns/locale"
import { setMapTarget } from "../../../../state/modules/geo"
import { useDispatch } from "react-redux"
import { useViewerOngoingNav } from "../../../../services/UserService"

const MapUser = () => {
  const dispatch = useDispatch()
  const viewerOngoingNav = useViewerOngoingNav()

  const lastPos = useLastPos()
  const mapTarget = useMapTarget()

  const map = useMap()

  if (mapTarget === "me" && lastPos) {
    map.setView(lastPos, map.getZoom(), {
      animate: false,
    })
  }
  if (lastPos)
    return (
      <Marker
        position={[lastPos.lat, lastPos.lng]}
        icon={MapIcon({ kind: "me", color: "gray" })}
        eventHandlers={{
          click: () => {
            console.log("marker clicked")
            dispatch(
              setMapTarget(viewerOngoingNav ? viewerOngoingNav.id : "idle"),
            )
          },
        }}
      >
        <Popup>
          Position relevée{" "}
          {formatDistance(lastPos.timestamp, new Date(), {
            addSuffix: true,
            locale: fr,
          })}
          .
        </Popup>
      </Marker>
    )
  return null
}

export default MapUser
