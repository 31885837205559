import { CircularProgress, Container, Typography } from "@mui/material"

import React from "react"

export const LoadScreen = ({ text }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress />
      <Typography component="h1" variant="h5" align="center">
        {text}
      </Typography>
    </Container>
  )
}
