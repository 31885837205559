import * as R from "ramda"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatISO } from "date-fns"
import { Route, Switch } from "react-router-dom"

import Box from "@mui/material/Box"

import { setTargetDate } from "../../../state/modules/reporting"
import Menu from "./Menu"
import Dashboard from "./Dashboard"
import Motorboats from "./Motorboats"
import Supports from "./Supports"

const Reporting = () => {
  const [stringDate] = useState(formatISO(new Date()))
  const dispatch = useDispatch()
  const targetDate = useSelector(
    R.path(["reporting", "dateInfos", "targetDate"]),
  )

  useEffect(() => {
    dispatch(setTargetDate({ targetDate: stringDate }))
  }, [dispatch, stringDate])

  if (!targetDate) {
    return <p>Loading...</p>
  }
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 8fr",
      }}
    >
      <Box>
        <Box sx={{ height: "76px" }} />
        <Menu />
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          // flexGrow: 1,
          width: "100%",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Switch>
          <Route path="/reporting" exact>
            <Dashboard />
          </Route>
          <Route path="/reporting/motorboats">
            <Motorboats />
          </Route>
          <Route path="/reporting/supports">
            <Supports />
          </Route>
        </Switch>
      </Box>
    </Box>
  )
}

export default Reporting
