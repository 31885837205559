import * as R from "ramda"

import { closeSnackbar, enqueueSnackbar } from "../state/modules/notifications"
import { connect, disconnect } from "../state/modules/webSocket"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"

import { API_WS } from "../config"
import { getTokens } from "./AuthService"
import { webSocket } from "rxjs/webSocket"

const tokens = getTokens()

const url = `${API_WS}/?token=${tokens?.access}`
// console.log("url", url)

const subject = webSocket(url)

export const useConnectWebSocket = () => {
  const dispatch = useDispatch()

  const [firstSuccessKey, setFirstSuccessKey] = useState()
  const [errorKey, setErrorKey] = useState()
  const { connected, connecting, disconnecting, retry } = useSelector(
    R.prop("webSocket"),
  )

  useEffect(() => {
    if (!connected && !connecting) {
      dispatch(connect())
    }
    return () => {
      dispatch(disconnect(true))
    }
  }, [dispatch])

  useEffect(() => {
    if (connecting && !connected && !retry && !disconnecting) {
      const key = new Date().getTime() + Math.random()
      !firstSuccessKey && setFirstSuccessKey(key)
      !firstSuccessKey &&
        dispatch(
          enqueueSnackbar({
            message: "Établissement de la connexion temps réel…",
            options: {
              key,
              variant: "info",
              persist: "true",
            },
          }),
        )
    }
    if (disconnecting && retry) {
      const key = new Date().getTime() + Math.random()
      !errorKey && setErrorKey(key)
      !errorKey &&
        dispatch(
          enqueueSnackbar({
            message: "Connection perdue, tentative de reconnection...",
            options: {
              key,
              variant: "warning",
              persist: "true",
            },
          }),
        )
    }
    if (connected) {
      firstSuccessKey && dispatch(closeSnackbar({ key: firstSuccessKey }))
      errorKey && dispatch(closeSnackbar({ key: errorKey })) && setErrorKey()
      dispatch(
        enqueueSnackbar({
          message: "Connexion temps réel activée.",
          options: {
            variant: "success",
          },
        }),
      )
    }
  }, [
    dispatch,
    connecting,
    disconnecting,
    connected,
    retry,
    errorKey,
    firstSuccessKey,
  ])
}

export default subject
