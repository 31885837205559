import React from "react"
import { Button, Card, CardActions, CardContent } from "@mui/material"

import { TextField } from "../../../../../components/formik"
import { Switch } from "../../../../../components/formik"
import { useFormikContext } from "formik"

const CommentForm = ({ kind, index }) => {
  const { submitForm } = useFormikContext()

  return (
    <Card raised>
      <CardContent>
        <TextField
          fullWidth
          name={`${kind}reports.${index}.comment`}
          label="Commenter"
          multiline
          rows={3}
        />
        <Switch
          name={`${kind}reports.${index}.solved`}
          label="Problème résolu"
        />
      </CardContent>
      <CardActions sx={{ float: "right" }}>
        <Button variant="outlined" onClick={submitForm}>
          Envoyer
        </Button>
      </CardActions>
    </Card>
  )
}
export default CommentForm
