import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { fetchMotorboats, fetchMotorboatsError, fetchMotorboatsOk } from "."

import { API_HTTP } from "../../../config"

const fetchMotorboatsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchMotorboats.type),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const baseId = state.auth.user.active_base_id
      return ajax({
        method: "GET",
        url: `${API_HTTP}/bases/${baseId}/motorboats/`,
      }).pipe(
        map(({ response }) => {
          return fetchMotorboatsOk({ results: response })
        }),
        catchError((error) => fetchMotorboatsError(error.xhr.response.detail)),
      )
    }),
  )

export default combineEpics(fetchMotorboatsEpic)
