import * as R from "ramda"
import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { formatISO } from "date-fns"

import { useDateInfos } from "../../../../services/ReportingService"
import { fetchMotorboats } from "../../../../state/modules/reporting"
import { MasonryBlock } from "../components"
import MotorboatDataGrid from "../components/MotorboatGrid"

const Motorboats = () => {
  const dispatch = useDispatch()

  const fetching = useSelector(R.path(["reporting", "motorboats", "fetching"]))
  const motorboats = useSelector(R.path(["reporting", "motorboats", "list"]))

  const { periodStart, periodEnd } = useDateInfos()
  const start = formatISO(periodStart)
  const end = formatISO(periodEnd)

  useEffect(() => {
    dispatch(
      fetchMotorboats({
        start,
        end,
      }),
    )
  }, [dispatch, start, end])

  return (
    <MasonryBlock title={"Semi-rigides les plus utilisés"} fetching={fetching}>
      <MotorboatDataGrid motorboats={motorboats} />
    </MasonryBlock>
  )
}

export default Motorboats
