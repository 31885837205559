import { LinearProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

import BadToken from "./BadToken"
import SetPassword from "./SetPassword"
import { resetPwdCheckToken } from "../../../../legacyApi"
import { useParams } from "react-router-dom"

const CheckToken = ({ classes }) => {
  const { token } = useParams()

  const [tokenCheck, setTokenCheck] = useState(true)
  const [validToken, setValidToken] = useState(false)

  useEffect(() => {
    const checkToken = async () => {
      try {
        const result = await resetPwdCheckToken({ token })
        if (result.status === 200) {
          setValidToken(true)
          setTokenCheck(false)
        }
      } catch (error) {
        setTokenCheck(false)
      }
    }
    checkToken()
  }, [token])

  if (tokenCheck) {
    return (
      <div>
        <Typography varian="h3">Checking token...</Typography>
        <LinearProgress />
      </div>
    )
  }

  if (validToken) {
    return <SetPassword classes={classes} token={token} />
  } else {
    return <BadToken classes={classes} />
  }
}

export default CheckToken
