import * as R from "ramda"

import {
  createMotorboatFav,
  deleteMotorboatFav,
} from "../../../../../../../../legacyApi"
import {
  favMotorboat,
  unfavMotorboat,
} from "../../../../../../../../state/modules/motorboats"
import { grey, yellow } from "@mui/material/colors"

import IconButton from "@mui/material/IconButton"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import TextField from "@mui/material/TextField"
import { createFilterOptions } from "@mui/material/Autocomplete"
import { useDispatch } from "react-redux"

export const filterOptions = (options, params = "", dataCy) => {
  const filter = createFilterOptions()
  const filtered = filter(options, params)

  const inputExists = R.compose(
    R.includes(R.toLower(params.inputValue)),
    R.map((motorboat) => R.toLower(R.prop("fullname", motorboat))),
  )(options)

  if (params.inputValue !== "" && !inputExists) {
    filtered.push({
      inputValue: params.inputValue,
      fullname: `Ajouter "${params.inputValue}"`,
      dataCy: `${dataCy}.addnew`,
    })
  }

  return filtered
}

export const groupBy = (option) =>
  option.kind === "user"
    ? "Mes semi-rigides préférés"
    : option.kind === "base"
    ? "Semi-rigides de la base"
    : "Autres semi-rigides"

export const getOptionLabel = (option) => {
  // e.g value selected with enter, right from the input
  if (typeof option === "string") {
    return option
  }
  if (option.inputValue) {
    return option.inputValue
  }
  return option.fullname
}

export const Option = ({ option, ...props }) => {
  const dispatch = useDispatch()
  const { id, identification, fullname, kind } = option

  const toggleFav = async () => {
    if (kind === "user") {
      await deleteMotorboatFav(id)
      dispatch(unfavMotorboat({ id }))
    } else {
      const motorboat = await createMotorboatFav({ identification })
      dispatch(favMotorboat({ motorboat }))
    }
  }

  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="fav"
          onClick={toggleFav}
          data-cy={`${option.dataCy}.fav`}
        >
          {kind === "user" ? (
            <StarIcon sx={{ color: yellow[700] }} />
          ) : (
            <StarBorderIcon sx={{ color: grey[300] }} />
          )}
        </IconButton>
      }
    >
      <ListItemText data-cy={option.dataCy} {...props} primary={fullname} />
    </ListItem>
  )
}

export const Input = (props) => {
  return <TextField {...props} label="Choisir le semi-rigide" />
}
