import * as R from "ramda"

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material"

import React from "react"

export const CheckboxGroup = (props) => {
  const {
    field: { name, value },
    form: { setFieldValue },
    label,
    options,
  } = props

  const isChecked = (option) => value.includes(option.value)
  const handleChange = (event) => {
    const clicked = parseInt(event.target.value)
    const newArrayOfValues = value.includes(clicked)
      ? R.without([clicked], value)
      : [...value, clicked]
    setFieldValue(name, newArrayOfValues)
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={isChecked(option)}
                onChange={handleChange}
                name={option.label}
                value={parseInt(option.value)}
                data-cy={`${name}-${index}-checkbox`}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {/* {error && <p>{error}</p>} */}
    </FormControl>
  )
}
