import * as R from "ramda"

export const getActiveBaseId = (state) =>
  R.path(["auth", "user", "active_base_id"], state)

export const getActiveBase = (state) => {
  const baseId = getActiveBaseId(state)
  return R.pathOr(null, ["bases", baseId], state)
}

export const getUserId = R.pathOr({}, ["auth", "user", "id"])

export const getViewerBaseUser = (state) => {
  const baseId = getActiveBaseId(state)
  const userId = getUserId(state)

  return R.find(
    (baseuser) => baseuser.base_id === baseId && baseuser.user.id === userId,
    R.pathOr([], ["baseusers", `${baseId}`], state),
  )
}

export const getLastPos = R.path(["geo", "lastPos"])
