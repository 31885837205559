import { Route, Switch } from "react-router-dom"

import BaseSignUp from "../BaseSignUp"
import { Container, Box } from "@mui/material"
import EmailSent from "./ResetPwd/EmailSent"
import ForgotPassword from "./ResetPwd/ForgotPassword"
import Invitation from "./Invitation"
import LogIn from "./LogIn"
import React from "react"
import ResetPassword from "./ResetPwd/CheckToken"
import { styleFullHeight } from "../../styles"

const Anonymous = () => {
  return (
    <Container maxWidth="xs">
      <Box sx={styleFullHeight}>
        <Switch>
          <Route path="/invitation/:token/">
            <Invitation />
          </Route>
          <Route path="/password/forgot/">
            <ForgotPassword />
          </Route>
          <Route path="/password/email-sent/">
            <EmailSent />
          </Route>
          <Route path="/password/reset/:token/">
            <ResetPassword />
          </Route>
          <Route path="/bases/:short_name/">
            <BaseSignUp />
          </Route>
          <Route>
            <LogIn />
          </Route>
        </Switch>
      </Box>
    </Container>
  )
}
export default Anonymous
