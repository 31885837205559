import React, { useEffect } from "react"

import * as R from "ramda"

import { MasonryBlock } from "../components"
import { useDispatch, useSelector } from "react-redux"
import { Typography } from "@mui/material"

import { formatISO } from "date-fns"

import { useDateInfos } from "../../../../services/ReportingService"
import { fetchNavAmount } from "../../../../state/modules/reporting"

const NavAmount = () => {
  const dispatch = useDispatch()

  const fetching = useSelector(R.path(["reporting", "navAmount", "fetching"]))
  const amount = useSelector(R.path(["reporting", "navAmount", "amount"]))
  const { periodStart, periodEnd } = useDateInfos()
  const start = formatISO(periodStart)
  const end = formatISO(periodEnd)

  useEffect(() => {
    dispatch(
      fetchNavAmount({
        start,
        end,
      }),
    )
  }, [dispatch, start, end])

  return (
    <MasonryBlock title={"Navs sur la période"} fetching={fetching}>
      <Typography component="p" variant="h2">
        {amount}
      </Typography>
    </MasonryBlock>
  )
}
export default NavAmount
