import { AuthShortcuts } from "../components"
import { HeaderWithIcon } from "../../../components"
import React from "react"
import { Send } from "@mui/icons-material"
import { Typography } from "@mui/material"

const EmailSent = ({ classes }) => (
  <>
    <HeaderWithIcon title="Email envoyé" icon={<Send />} />
    <Typography>
      Si un compte associé à votre email existe, vous allez recevoir un email
      pour réinitialiser votre mot de passe.
    </Typography>
    <br />
    <br />
    <AuthShortcuts />
  </>
)

export default EmailSent
