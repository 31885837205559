import { Avatar, Box, Typography } from "@mui/material"

const sxWrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}
const sxHeaderIcon = {
  margin: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.secondary.main,
}
export const HeaderWithIcon = ({
  title = null,
  icon,
  children = null,
  sx = null,
}) => (
  <Box sx={{ ...sxWrapper, ...sx }}>
    <Avatar sx={sxHeaderIcon}>{icon}</Avatar>
    {title && (
      <Typography component="h1" variant="h5" align="center">
        {title}
      </Typography>
    )}
    {children}
  </Box>
)
