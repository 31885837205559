import * as R from "ramda"
import React from "react"
import { Card, CardContent, CardHeader } from "@mui/material"

import { Avatar } from "../../../../../components/"
import { useUserById } from "../../../../../../services/UserService"
import { timeToNow } from "../../../../../../services/MainService"

const CommentBlock = ({ comment }) => {
  const { fullname } = useUserById(comment.author_id)
  return (
    <Card variant="outlined">
      <CardHeader
        align="left"
        avatar={<Avatar name={fullname} />}
        title={fullname}
        subheader={timeToNow(comment.created_at)}
      />
      <CardContent align="left">{comment.body}</CardContent>
    </Card>
  )
}

const Comments = ({ comments }) => (
  <>
    {R.reverse(comments).map((comment, index) => (
      <CommentBlock key={index} comment={comment} />
    ))}
  </>
)

export default Comments
