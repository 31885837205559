import * as R from "ramda"

import { catchError, map, mergeMap } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import {
  deleteTokens,
  getUserFromToken,
  storeTokens,
} from "../../../services/AuthService"
import { refreshToken, refreshTokenError, refreshTokenOk } from "."

import { API_HTTP } from "../../../config"
import { of } from "rxjs"

class TokenError extends Error {
  constructor(message) {
    super(message)
    this.name = "TokenError"
  }
}

export const verifyUserFromToken = (user) => {
  const fieldList = [
    "token_type",
    "exp",
    "jti",
    "id",
    "email",
    "firstname",
    "lastname",
    "photo",
    "main_base_id",
    "active_base_id",
  ]
  const userHasField = (field) => R.has(field, user)

  const hasAllFields = R.all(userHasField, fieldList)

  if (!hasAllFields) {
    throw new TokenError("Les informations d'authentification ont expiré.")
  }
}

const refreshTokenEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(refreshToken.type),
    mergeMap((action) => {
      const url = `${API_HTTP}/users/token/refresh/`
      const refresh = action.payload.refresh
      if (!refresh) {
        return of(refreshTokenError(null))
      }
      return ajax({
        method: "POST",
        url,
        body: { refresh },
      }).pipe(
        map(({ response: { access } }) => {
          storeTokens({ refresh, access })
          const user = getUserFromToken()
          verifyUserFromToken(user)
          return refreshTokenOk(user)
        }),
        catchError((error) => {
          deleteTokens()
          if (error instanceof TokenError) {
            return of(refreshTokenError(error.message))
          }

          return of(
            refreshTokenError(
              R.pathOr(null, ["xhr", "response", "detail"], error),
            ),
          )
        }),
      )
    }),
  )

export default combineEpics(refreshTokenEpic)
