import { Marker, Popup, useMapEvents } from "react-leaflet"
import React, { useState } from "react"

const CoordPopup = () => {
  const [position, setPosition] = useState(null)

  useMapEvents({
    click: (e) => setPosition(e.latlng),
  })

  return position === null ? null : (
    <Marker position={position}>
      <Popup>
        <p>
          {`{
              "timestamp": ${Date.now()}, 
              "lat": ${position.lat}, 
              "lng": ${position.lng}
            }`}
        </p>
      </Popup>
    </Marker>
  )
}

export default CoordPopup
