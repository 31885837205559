import React from "react"
import { Box, Typography } from "@mui/material"

import Comments from "./Comments"
import CommentForm from "./CommentForm"

const SupportSection = ({ supportreport, index, handleSubmit }) => {
  return (
    <Box>
      <Typography variant="h6" color="secondary">
        {supportreport.support_name}
      </Typography>
      <Typography align="left">{supportreport.body}</Typography>
      <Comments comments={supportreport.comments} />
      <CommentForm kind="support" index={index} handleSubmit={handleSubmit} />
    </Box>
  )
}

export default SupportSection
