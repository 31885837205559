import * as R from "ramda"

import { Avatar, Card, CardHeader, Typography } from "@mui/material"
import {
  Check,
  PinDrop,
  PlayArrow,
  PriorityHigh,
  QuestionAnswer,
} from "@mui/icons-material"

import React from "react"
import { formatDistance } from "date-fns"
import { formatTime } from "../../../../services/MainService"
import { fr } from "date-fns/locale"
import { getEventDesc } from "../../../../services/NavService"
import { latLngAsDMS } from "../../../../services/GeoService"
import { sxEventItem } from "./styles"

const EventIcon = ({ kind }) => {
  const getIcon = () => {
    switch (kind) {
      case "start":
        return <PlayArrow />
      case "pin":
        return <PinDrop />
      case "alert":
        return <PriorityHigh />
      case "solved":
        return <Check />
      default:
        return <QuestionAnswer />
    }
  }
  return <Avatar sx={sxEventItem[kind]}>{getIcon()}</Avatar>
}

const Title = ({ event }) => (
  <>
    <Typography variant="body1">
      {formatTime(event.timestamp)}
      <strong> {getEventDesc(event.kind)}</strong> (
      {formatDistance(event.timestamp, new Date(), {
        addSuffix: true,
        locale: fr,
      })}
      )
    </Typography>
    {event.desc && (
      <Typography variant="body2">
        <strong>{event.desc.toUpperCase()}</strong>
      </Typography>
    )}
  </>
)

const EventItem = ({ event }) => {
  return (
    <Card sx={sxEventItem.card}>
      <CardHeader
        avatar={<EventIcon kind={event.kind} />}
        title={<Title event={event} />}
        subheader={latLngAsDMS(R.pick(["lat", "lng"], event))}
      />
    </Card>
  )
}

export default EventItem
