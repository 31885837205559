import React from "react"
import { Box, Typography } from "@mui/material"

import Comments from "./Comments"
import CommentForm from "./CommentForm"

const MotorboatSection = ({ motorboatreport, index, handleSubmit }) => {
  return (
    <Box>
      <Typography variant="h6" color="secondary">
        {motorboatreport.motorboat_name}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {motorboatreport.body}
      </Typography>
      <Comments comments={motorboatreport.comments} />
      <CommentForm kind="motorboat" index={index} handleSubmit={handleSubmit} />
    </Box>
  )
}

export default MotorboatSection
