import { AuthShortcuts } from "../components"
import { HeaderWithIcon } from "../../../components"
import React from "react"
import { Send } from "@mui/icons-material"
import { Typography } from "@mui/material"

const EmailSent = () => {
  return (
    <>
      <HeaderWithIcon
        title="Le token n'existe pas ou n'est plus valide"
        icon={<Send />}
      />
      <Typography>
        Veuillez vous connecter ou refaire une demande de réinitialisation
      </Typography>
      <br />
      <br />
      <AuthShortcuts />
    </>
  )
}

export default EmailSent
