import { Route, Switch } from "react-router-dom"
import React from "react"

import List from "./List"
import Detail from "./Detail"

const Motorboats = () => (
  <Switch>
    <Route path="/reporting/motorboats/" exact>
      <List />
    </Route>
    <Route path="/reporting/motorboats/:id/">
      <Detail />
    </Route>
  </Switch>
)

export default Motorboats
