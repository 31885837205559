import { Redirect, Route, Switch } from "react-router-dom"

import Base from "../../pages/Base"
import Coach from "../../pages/Coach"
import Debrief from "../../pages/Debrief"
import Infos from "../../pages/Infos"
// import Motorboats from "../../pages/Motorboats"
import Navs from "../../pages/Navs"
import NotFound from "../../pages/NotFound"
import Profile from "../../pages/Profile"
import Reporting from "../../pages/Reporting"
// import Supports from "../../pages/Supports"

const routes = {
  BASE: "/base/",
  HOME: "/",
  INFOS: "/infos/",
  MOTORBOATS: "/motorboats/",
  PROFILE: "/profile/",
  NAVS: "/navs/",
  DEBRIEF: "/debrief/",
  REPORTING: "/reporting/",
  SUPPORTS: "/supports/",
}

const RedirectToHome = () => <Redirect to="/" />
const RedirectToNavs = () => <Redirect to="/navs" />

const Routing = ({ role }) => {
  switch (role) {
    case "rtq":
      return (
        <Switch>
          <Route exact path={routes.HOME} render={RedirectToNavs} />
          <Route path="/invitation/" render={RedirectToNavs} />

          <Route path={routes.BASE} component={Base} />
          <Route path={routes.INFOS} component={Infos} />
          <Route path={routes.PROFILE} component={Profile} />
          {/* <Route path={routes.MOTORBOATS} component={Motorboats} /> */}
          {/* <Route path={routes.SUPPORTS} component={Supports} /> */}
          <Route path={routes.NAVS} component={Navs} />
          <Route path={routes.DEBRIEF} component={Debrief} />
          <Route path={routes.Reporting} component={Reporting} />
          <Route path="/bases/:short_name/" render={RedirectToHome} />
          <Route component={NotFound} />
        </Switch>
      )
    default:
      return (
        <Switch>
          <Route exact path={routes.HOME} component={Coach} />
          <Route path="/invitation/" render={RedirectToHome} />
          <Route path={routes.BASE} component={Base} />
          <Route path={routes.INFOS} component={Infos} />
          <Route path={routes.PROFILE} component={Profile} />
          <Route path={routes.NAVS} component={Navs} />
          <Route path={routes.DEBRIEF} component={Debrief} />
          {/* <Route path={routes.MOTORBOATS} component={Motorboats} /> */}
          {/* <Route path={routes.SUPPORTS} component={Supports} /> */}
          <Route path="/bases/:short_name/" render={RedirectToHome} />
          <Route component={NotFound} />
        </Switch>
      )
  }
}

export default Routing
