import * as R from "ramda"
import React from "react"
import {
  MobileDateTimePicker,
  StaticTimePicker,
} from "../../../../../components/formik"

import Box from "@mui/material/Box"
import { Typography } from "@mui/material"

const StartEnd = ({ values, kindIsNow }) => {
  if (kindIsNow)
    return (
      <StaticTimePicker
        name="planned_end"
        value={values.planned_end}
        disablePast
        clearable
        minField={"planned_start"}
      />
    )
  return (
    <Box>
      <Typography variant="h6">Date et heure de début</Typography>
      <MobileDateTimePicker
        name="planned_start"
        value={values.planned_start}
        disablePast
        // clearable
        copyTo={"planned_end"}
        minField={"planned_start"}
      />
      <Typography variant="h6">Date et heure de fin</Typography>
      <MobileDateTimePicker
        name="planned_end"
        value={values.planned_end}
        disablePast
        clearable
        minField={"planned_start"}
        openTo={"hours"}
      />
    </Box>
  )
}

export default StartEnd
