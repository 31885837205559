import * as Yup from "yup"

import { Box, Button, LinearProgress } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { TopLevelErrors, handleError } from "../../components/formik"
import { sxForm, sxSubmit } from "../../styles"

import { CheckboxWithLabel } from "formik-mui"
import RNR from "./RNR"
import React from "react"
import { setBaseUserOk } from "../../../state/modules/baseusers"
import { updateBaseUserPromise } from "../../../legacyApi"
import { useDispatch } from "react-redux"
import { useViewerBaseUser } from "../../../services/UserService"

const RNRSchema = Yup.object().shape({
  rules_n_regulations: Yup.bool().required("Requis"),
})

const Step3 = ({ handlePrev }) => {
  const dispatch = useDispatch()
  const baseuser = useViewerBaseUser()

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      const res = await updateBaseUserPromise(values)
      dispatch(setBaseUserOk(res.data))
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  // console.log("baseuser", baseuser)

  return (
    <React.Fragment>
      <RNR />
      <Formik
        initialValues={baseuser}
        validationSchema={RNRSchema}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm, isSubmitting, status }) => (
          <Box sx={sxForm}>
            <Form>
              <TopLevelErrors status={status} />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                Label={{ label: "Je valide le règlement intérieur" }}
                name="rules_n_regulations"
                required
                data-cy="checkbox-rnr"
              />
              {isSubmitting && <LinearProgress />}
              <Button
                sx={sxSubmit}
                variant="contained"
                color="primary"
                disabled={!values.rules_n_regulations}
                onClick={submitForm}
                fullWidth
                data-cy="button-submit"
              >
                VALIDER
              </Button>
            </Form>
          </Box>
        )}
      </Formik>
      {/* temporary deactivated */}
      {/* <Grid>
        <Link onClick={handlePrev} variant="body2">
          Précédent
        </Link>
      </Grid> */}
    </React.Fragment>
  )
}

export default Step3
