import * as R from "ramda"

import { createSlice } from "@reduxjs/toolkit"

const navsSlice = createSlice({
  name: "navs",
  initialState: {
    navSyncing: false,
    navSynced: false,
    fetching: true,
    navs: [],
    creating: false,
    navInModal: null,
  },
  reducers: {
    startNavSync: R.evolve({
      navSyncing: R.always(true),
      navSynced: R.always(false),
    }),
    stopNavSync: R.evolve({
      navSyncing: R.always(false),
      navSynced: R.always(false),
    }),

    fetchNavs: R.assoc("fetching", true),
    fetchNavsOk: (state, { payload }) =>
      R.compose(R.assoc("fetching", false), R.assoc("navs", payload))(state),
    fetchNavsError: (state, { payload }) => R.assoc("error", payload),
    cancelFetchNavs: R.identity,

    updateNav: R.identity,
    navUpdated: (state, { payload }) => {
      const nav = R.when(
        R.propEq("status", "FINISHED"),
        R.assoc("justFinished", true),
        payload,
      )

      return R.set(
        R.lensPath([
          "navs",
          R.findIndex(R.propEq("id", payload.id))(state.navs),
        ]),
        nav,
        state,
      )
    },
    updateNavError: R.identity,

    createNav: (state, { payload: { nav, baseId } }) =>
      R.assoc("creating", true, state),
    navCreated: (state, { payload }) =>
      R.compose(
        R.assoc("creating", false),
        R.over(R.lensProp("navs"), R.append(payload)),
      )(state),
    createNavError: (state, { payload: { error, baseId } }) =>
      R.compose(
        R.dissocPath([R.toString(baseId), "create"]),

        R.set(R.lensPath([R.toString(baseId), "error"]), error),
      )(state),

    updateNavWS: (state, { payload }) => {
      return R.over(
        R.lensPath([
          "navs",
          R.findIndex(R.propEq("id", payload.id))(state.navs),
        ]),
        R.mergeDeepLeft(payload),
        state,
      )
    },
    updateNavWSOk: R.identity,
    updateNavWSError: R.identity,

    setNavInModal: (state, { payload }) =>
      R.assoc("navInModal", payload, state),
    clearNavInModal: R.assoc("navInModal", null),
  },
})

// Action creators are generated for each case reducer function
export const {
  startNavSync,
  stopNavSync,

  fetchNavs,
  fetchNavsOk,
  fetchNavsError,
  cancelFetchNavs,

  sendNavData,
  receiveNavData,

  updateNav,
  navUpdated,
  updateNavError,

  createNav,
  navCreated,
  createNavError,

  updateNavWS,
  updateNavWSOk,
  updateNavWSError,

  setNavInModal,
  clearNavInModal,
} = navsSlice.actions

export default navsSlice.reducer
