import * as R from "ramda"

import {
  Input,
  Option,
  filterOptions,
  getOptionLabel,
  groupBy,
} from "./helpers"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AddMotorboatDialog from "./AddMotorboatDialog"
import Autocomplete from "@mui/material/Autocomplete"
import { fetchMotorboats } from "../../../../../../../../state/modules/motorboats"
import { formatMotorboatsForSelect } from "../../../../../../../../services/WarehouseService"

const MotorboatSearch = ({
  dataCy,
  field: { name, value, onBlur },
  form: { setFieldValue },
}) => {
  const [motorboatName, setMotorboatName] = useState("")

  const dispatch = useDispatch()
  const motorboats = useSelector(R.pathOr(null, ["motorboats", "results"]))
  const fetchingMotorboats = useSelector(R.path(["motorboats", "fetching"]))

  const handleChange = (value) => {
    setFieldValue(
      name,
      R.pick(
        ["id", "name", "fullname", "identification", "length", "horsepower"],
        value,
      ),
    )
  }

  useEffect(() => {
    dispatch(fetchMotorboats())
  }, [dispatch])

  return (
    <>
      <Autocomplete
        name={name}
        loading={fetchingMotorboats}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setMotorboatName(newValue)
            })
          } else if (newValue && newValue.inputValue) {
            setMotorboatName(newValue.inputValue)
          } else {
            handleChange(newValue)
          }
        }}
        id={dataCy}
        options={formatMotorboatsForSelect(motorboats)}
        filterOptions={(options, params) =>
          filterOptions(options, params, dataCy)
        }
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <Option {...props} option={option} />}
        freeSolo
        renderInput={(params) => (
          <Input {...params} data-cy={`${dataCy}.textinput`} onBlur={onBlur} />
        )}
        fullWidth
      />
      <AddMotorboatDialog
        motorboatName={motorboatName}
        setMotorboatName={setMotorboatName}
        setFormValue={handleChange}
        dataCy={dataCy}
      />
    </>
  )
}

export default MotorboatSearch
