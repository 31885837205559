import auth from "./modules/auth"
import bases from "./modules/bases"
import baseusers from "./modules/baseusers"
import { combineReducers } from "redux"
import geo from "./modules/geo"
import motorboats from "./modules/motorboats"
import navs from "./modules/navs"
import notifications from "./modules/notifications"
import reporting from "./modules/reporting"
import supports from "./modules/supports"
import webSocket from "./modules/webSocket"

const appReducer = combineReducers({
  auth,
  bases,
  baseusers,
  geo,
  motorboats,
  navs,
  notifications,
  reporting,
  supports,
  webSocket,
})

export default appReducer
