import * as R from "ramda"
import {
  AccountCircle,
  BarChart,
  ExitToApp,
  FormatListNumbered,
  Home,
  Link,
  Phone,
  Store,
  CircleNotifications,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material"
import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material"

import { ListItem } from "../../components"
import React, { useState } from "react"
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded"
import { deleteTokens } from "../../../services/AuthService"
import { getInitialValues, useNavs } from "../../../services/NavService"
import { removeUser } from "../../../state/modules/auth"
import { setNavInModal } from "../../../state/modules/navs"
import { useDispatch } from "react-redux"
import { useViewerLastNav } from "../../../services/UserService"
import { Sail } from "../../components/icons/Sail"
import { format, parseISO } from "date-fns"

const logOut = (dispatch) => {
  deleteTokens()
  dispatch(removeUser())
}

const Menu = ({ toggleMenu }) => {
  const [openMissing, setOpenMissing] = useState(false)
  const toggleMissing = () => setOpenMissing(!openMissing)
  const dispatch = useDispatch()

  const lastNav = useViewerLastNav()
  const nav = getInitialValues(lastNav)

  const openNavModal = async () => {
    dispatch(setNavInModal({ ...nav, kind: "planify" }))
  }

  const navsMissingDebrief = useNavs({ viewerOnly: true, status: "FINISHED" })

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      // alignItems="flex-start"
      style={{ height: "100vh" }}
    >
      <Grid item>
        <List>
          <ListItem
            primary="Accueil"
            icon={<Home />}
            action="/"
            next={toggleMenu}
            data-cy="main-menu-home"
          />
          <ListItem secondary="Navs" />
          {!R.isEmpty(navsMissingDebrief) && (
            <>
              <ListItemButton onClick={toggleMissing}>
                <ListItemIcon>
                  <CircleNotifications color="secondary" />
                </ListItemIcon>
                <ListItemText
                  sx={{ color: "secondary.main" }}
                  primary="A débriefer"
                />
                {openMissing ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openMissing} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {navsMissingDebrief.map((nav) => (
                    <ListItem
                      key={nav.id}
                      primary={`Nav du ${format(
                        parseISO(nav.start),
                        "dd/MM HH:mm",
                      )}`}
                      icon={<Sail />}
                      action={`/navs/${nav.id}`}
                      next={toggleMenu}
                      data-cy="main-menu-home"
                    />
                  ))}
                </List>
              </Collapse>
            </>
          )}
          <ListItem
            primary="Voir les navs"
            icon={<FormatListNumbered />}
            action="/navs/"
            next={toggleMenu}
            data-cy="main-menu-navs"
          />
          <ListItem
            primary="Plannifier une nav"
            icon={<ScheduleRoundedIcon />}
            action={openNavModal}
            data-cy="main-menu-plan-nav"
          />
          <ListItem secondary="Paramètres" />
          <ListItem
            primary="Mon profil"
            icon={<AccountCircle />}
            action="/profile/"
            next={toggleMenu}
            data-cy="main-menu-profile"
          />
          <ListItem
            primary="La base"
            icon={<Store />}
            action="/base"
            next={toggleMenu}
            data-cy="main-menu-base"
          />
          <ListItem
            primary="Statistiques"
            icon={<BarChart />}
            action="/reporting"
            next={toggleMenu}
            data-cy="main-menu-base"
          />

          {/* désactivé momentanément */}
          {/* <ListItem
            primary="Semi-rigides"
            icon={<DirectionsBoat />}
            action="/motorboats/"
            next={toggleMenu}
          />
          <ListItem
            primary="Supports"
            icon={<Rowing />}
            action="/supports/"
            next={toggleMenu}
          /> */}
          <ListItem
            primary="Numéros utiles"
            icon={<Phone />}
            action="/infos/contacts"
            next={toggleMenu}
            data-cy="main-menu-contacts"
          />
          <ListItem
            primary="Liens utiles"
            icon={<Link />}
            action="/infos/links"
            next={toggleMenu}
            data-cy="main-menu-links"
          />
          <ListItem
            primary="Se déconnecter"
            icon={<ExitToApp />}
            action={() => logOut(dispatch)}
            next={toggleMenu}
            data-cy="main-menu-logout"
          />
        </List>
      </Grid>
    </Grid>
  )
}

export default Menu
