import * as R from "ramda"

import {
  Input,
  Option,
  filterOptions,
  getOptionLabel,
  groupBy,
} from "./helpers"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AddSupportDialog from "./AddSupportDialog"
import Autocomplete from "@mui/material/Autocomplete"
import { fetchSupports } from "../../../../../../../../state/modules/supports"
import { formatSupportsForSelect } from "../../../../../../../../services/WarehouseService"

const SupportSearch = ({
  dataCy,
  field: { name, value, onBlur },
  form: { setFieldValue },
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogValue, setDialogValue] = useState("")

  const dispatch = useDispatch()
  const supports = useSelector(R.pathOr(null, ["supports", "results"]))
  const fetchingSupports = useSelector(R.path(["supports", "fetching"]))

  const handleChange = (value) => {
    setFieldValue(name, R.pick(["id", "name"], value))
  }

  useEffect(() => {
    dispatch(fetchSupports())
  }, [dispatch])

  return (
    <>
      <Autocomplete
        name={name}
        loading={fetchingSupports}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setOpenDialog(true)
              setDialogValue(newValue)
            })
          } else if (newValue && newValue.inputValue) {
            setOpenDialog(true)
            setDialogValue(newValue.inputValue)
          } else {
            handleChange(newValue)
          }
        }}
        id={dataCy}
        options={formatSupportsForSelect(supports)}
        filterOptions={(options, params) =>
          filterOptions(options, params, dataCy)
        }
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <Option {...props} option={option} />}
        freeSolo
        renderInput={(params) => (
          <Input {...params} data-cy={`${dataCy}.textinput`} onBlur={onBlur} />
        )}
        fullWidth
      />
      <AddSupportDialog
        open={openDialog}
        setOpen={setOpenDialog}
        setValue={setDialogValue}
        value={dialogValue}
        setFormValue={handleChange}
        dataCy={dataCy}
      />
    </>
  )
}

export default SupportSearch
