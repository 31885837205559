import { FormHelperText, Paper, Stack, Typography } from "@mui/material"
import { FieldArray } from "formik"
import React from "react"
import { Switch, TextField } from "../../../../../components/formik"

const SupportForm = ({ supportreport, index }) => {
  return (
    <Stack spacing={1} key={index}>
      <Typography variant="h6" align="center">
        {supportreport.support_name}
      </Typography>
      <TextField
        name={`supportreports.${index}.body`}
        label={`Problèmes avec le support ${supportreport.support_name}`}
        fullWidth
        multiline
        rows={4}
      />
      <FormHelperText>
        Précisez toute information permettant d’identifier le/les supports (n°
        coque, n° de voile, couleur...)
      </FormHelperText>
      <Switch name={`supportreports.${index}.solved`} label="Problème résolu" />
    </Stack>
  )
}

const SupportsForm = ({ supportreports }) => (
  <FieldArray name="supportreports">
    {(arrayHelpers) => (
      <Paper
        variant="outlined"
        sx={{
          p: (theme) => theme.spacing(1),
        }}
      >
        {supportreports.map((supportreport, index) => (
          <SupportForm
            key={index}
            supportreport={supportreport}
            index={index}
          />
        ))}
      </Paper>
    )}
  </FieldArray>
)

export default SupportsForm
