import * as R from "ramda"

import { createSlice } from "@reduxjs/toolkit"

const baseUserLens = (baseUserId, base_id, state) => {
  const index = R.findIndex(R.propEq("id", baseUserId))(R.prop(base_id, state))
  return R.lensPath([`${base_id}`, index])
}

const baseusersSlice = createSlice({
  name: "baseusers",
  initialState: {},
  reducers: {
    setBaseUsers: (state, { payload }) =>
      R.set(R.lensProp(payload[0].base_id), payload, state),
    setBaseUser: (state, { payload }) => {
      console.log(2)
      return R.over(
        baseUserLens(payload.id, payload.base_id, state),
        R.assoc("loading", true),
        state,
      )
    },
    setBaseUserOk: (state, { payload }) => {
      console.log("payload: ", payload)
      return R.set(
        baseUserLens(payload.id, payload.base_id, state),
        R.dissoc("loading", payload),
        state,
      )
    },
  },
})

export const { setBaseUsers, setBaseUser, setBaseUserOk } =
  baseusersSlice.actions

export default baseusersSlice.reducer
