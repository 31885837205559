import * as R from "ramda"

import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { Field, Form, Formik } from "formik"

import NavMotorboats from "./NavMotorboats"
import NavSupports from "./NavSupports"
import React from "react"
import { TextField } from "formik-mui"
import { handleError } from "../../components/formik"
import { sxForm } from "../../styles"
import { updateNavWS } from "../../../state/modules/navs"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { useLocation } from "react-router-dom"
import { useNav } from "../../../services/NavService"

const sxTitle = {
  marginLeft: (theme) => theme.spacing(2),
  flex: 1,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Debrief = () => {
  const history = useHistory()
  const location = useLocation()

  const regex = /(\/debrief\/)(\d+)/g
  const selectedNavId = regex.exec(location.pathname)[2]
  const nav = useNav(selectedNavId)

  const dispatch = useDispatch()

  const handleSubmitDebrief = async (
    values,
    { status, setStatus, setSubmitting },
  ) => {
    try {
      dispatch(updateNavWS({ ...values, status: "DEBRIEFED" }))
      history.push(`/`)
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  const handleSaveDebrief = async (
    values,
    { status, setStatus, setSubmitting },
  ) => {
    try {
      dispatch(updateNavWS(values))
      history.push(`/`)
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <Dialog
      fullScreen
      open={true}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <Typography variant="h6" sx={sxTitle}>
            Debriefer la nav
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Formik
          initialValues={
            (R.pick([
              "id",
              "debrief_all_clear",
              "debrief_human",
              "navsupports",
              "navmotorboats",
            ]),
            nav)
          }
          onSubmit={handleSubmitDebrief}
        >
          {({
            submitForm,
            isSubmitting,
            isValid,
            values,
            setValues,
            dirty,
            status,
            setStatus,
            setSubmitting,
          }) => {
            const notAllClear =
              values.debrief_human ||
              values.debrief_motorboat ||
              values.debrief_support
                ? true
                : false

            return (
              <Box sx={sxForm}>
                <Form>
                  <Stack spacing={1}>
                    <Typography variant="h3" align="center">
                      Debrief de la nav
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.debrief_all_clear}
                          onChange={() =>
                            setValues({
                              ...values,
                              debrief_all_clear: !values.debrief_all_clear,
                            })
                          }
                          name="checkedB"
                          color="primary"
                          disabled={notAllClear}
                          data-cy="checkbox-all_clear"
                        />
                      }
                      label="Rien à déclarer"
                    />

                    <Field
                      component={TextField}
                      name="debrief_human"
                      type="text"
                      label="Humain"
                      fullWidth
                      multiline
                      rows={4}
                      data-cy="textfield-debrief_human"
                    />
                    <Box sx={{ p: (theme) => theme.spacing(1) }}>
                      {values.navsupports.length > 0 && (
                        <NavSupports navsupports={values.navsupports} />
                      )}
                    </Box>
                    <Box sx={{ p: (theme) => theme.spacing(1) }}>
                      {values.navmotorboats.length > 0 && (
                        <NavMotorboats navmotorboats={values.navmotorboats} />
                      )}
                    </Box>
                  </Stack>
                </Form>
                <ButtonGroup fullWidth>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSaveDebrief(values, {
                        status,
                        setStatus,
                        setSubmitting,
                      })
                    }}
                    data-cy="button-save-for-later"
                  >
                    Continuer plus tard
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      isSubmitting || isValid === false || dirty === false
                    }
                    onClick={submitForm}
                    data-cy="button-submit"
                  >
                    {isSubmitting ? "Patientez..." : "Enregistrer le debrief"}
                  </Button>
                </ButtonGroup>
              </Box>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default Debrief
