export const sxForm = {
  width: "100%",
  marginTop: (theme) => theme.spacing(8),
}

export const sxSubmit = {
  m: (theme) => theme.spacing(3, 0, 2),
}

export const sxRightFab = {
  position: "absolute",
  right: (theme) => theme.spacing(1),
  top: (theme) => theme.spacing(1),
  zIndex: 1300,
}
