import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useHistory, useLocation } from "react-router-dom"

const MenuItem = ({ path, label, icon, ...props }) => {
  const { pathname } = useLocation()
  const { push } = useHistory()

  return (
    <ListItemButton
      selected={pathname === path}
      onClick={() => push(path)}
      {...props}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

export default MenuItem
