import { catchError, map, mergeMap } from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { setBaseUser, setBaseUserOk } from "."

import { API_HTTP } from "../../../config"
import { of } from "rxjs"

const setBaseUserEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(setBaseUser.type),
    mergeMap(({ payload }) => {
      const url = `${API_HTTP}/bases/${payload.base_id}/baseusers/${payload.id}/`
      return ajax({
        method: "PATCH",
        url,
        body: payload,
      }).pipe(
        map(({ response }) => setBaseUserOk(response)),
        catchError((error) => {}),
      )
    }),
  )

export default combineEpics(setBaseUserEpic)
