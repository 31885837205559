import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { CssBaseline } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { Provider } from "react-redux"
import React from "react"
import { SnackbarProvider } from "notistack"
import { StyledEngineProvider } from "@mui/material/styles"
import { ThemeProvider } from "@mui/material/styles"
import configureStore from "../state/configureStore"
import frLocale from "date-fns/locale/fr"
import theme from "./theme"

const store = configureStore()

const Root = () => (
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={frLocale}
          >
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                preventDuplicate
                maxSnack={3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <CssBaseline />
                <App />
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

export default Root
