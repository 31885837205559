import * as R from "ramda"

import { useActiveBaseId } from "./BaseService"
import { useSelector } from "react-redux"

export const useSupport = (supportId) =>
  R.find(R.propEq("id", supportId))(
    useSelector(R.pathOr([], ["supports", "results"])),
  )

export const useMotorboat = (motorboatId) =>
  R.find(R.propEq("id", motorboatId))(
    useSelector(
      R.pathOr([], ["warehouse", "motorboats", `${useActiveBaseId()}`]),
    ),
  )

// export const getMotorboatName = (motorboat) =>
//   motorboat
//     ? `${motorboat.name} (${motorboat.identification})`
//     : "Pas de semi-rigide"

export const formatSupportsForSelect = R.compose(
  R.flatten,
  R.map(([group, values]) => R.map(R.assoc("kind", group), values)),
  R.toPairs,
)

export const formatMotorboatsForSelect = R.compose(
  R.flatten,
  R.map(([group, values]) => R.map(R.assoc("kind", group), values)),
  R.toPairs,
)
