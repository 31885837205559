import {
  CheckCircle,
  Error,
  Help,
  LocationOn,
  PlayCircleFilled,
} from "@mui/icons-material"
import { Marker, Tooltip } from "react-leaflet"

import React from "react"
import { Typography } from "@mui/material"
import { divIcon } from "leaflet"
import { formatTime } from "../../../../services/MainService"
import { getLatLng } from "../../../../services/NavService"
import { renderToString } from "react-dom/server"
import { useHistory } from "react-router-dom"
import { useNavOwner } from "../../../../services/BaseService"

const iconSize = [24, 24]

const EventIcon = ({ event, ...props }) => {
  switch (event.kind) {
    case "pin":
      return <LocationOn {...props} />
    case "start":
      return <PlayCircleFilled {...props} />
    case "solved":
      return <CheckCircle {...props} />
    case "alert":
      return <Error {...props} />
    default:
      return <Help {...props} />
  }
}

const getDesc = (event) => {
  if (event.kind === "start") {
    return "Départ"
  }
  if (event.kind === "solved") {
    return "Alerte résolue"
  }
  if (event.desc) return event.desc
}

const EventMarker = ({ nav, event, color, last }) => {
  const navOwner = useNavOwner(nav)
  const history = useHistory()

  return (
    <Marker
      position={getLatLng(event)}
      icon={divIcon({
        html: renderToString(<EventIcon event={event} htmlColor={color} />),
        className: "dummy",
        iconSize,
      })}
      eventHandlers={{
        click: () => {
          history.push(`/navs/${nav.id}/`)
        },
      }}
    >
      <Tooltip permanent direction="right" offset={[16, 0]}>
        <Typography variant="body2">
          <strong>{formatTime(event.timestamp)}</strong>{" "}
          {last ? navOwner.fullname : getDesc(event)}
        </Typography>
        {getDesc(event) && last && (
          <Typography variant="caption">{getDesc(event)}</Typography>
        )}
      </Tooltip>
    </Marker>
  )
}

export default EventMarker
