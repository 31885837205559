import { createSlice } from "@reduxjs/toolkit"
import * as R from "ramda"

const webSocketSlice = createSlice({
  name: "webSocket",
  initialState: {
    connected: false,
    connecting: false,
    disconnecting: false,
    retry: false,
    messages: [],
  },
  reducers: {
    connected: R.compose(
      R.assoc("connecting", false),
      R.assoc("connected", true),
      R.assoc("retry", false),
    ),
    connect: R.compose(
      R.assoc("connecting", true),
      R.assoc("connected", false),
    ),
    disconnected: (state) =>
      R.compose(
        R.assoc("disconnecting", false),
        R.assoc("connected", false),
      )(state),
    disconnect: (state, { payload }) =>
      R.compose(
        R.assoc("disconnecting", true),
        R.assoc("retry", payload.retry ? true : false),
      )(state),
    receiveMessage: R.identity,
    sendMessage: (state) => state,
    sentMessage: (state) => state,
  },
})

export const {
  connected,
  connect,
  disconnected,
  disconnect,
  receiveMessage,
  sendMessage,
  sentMessage,
} = webSocketSlice.actions

export default webSocketSlice.reducer
