import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material"
import {
  formatEndTime,
  formatStartTime,
} from "../../../../services/MainService"

import React from "react"
import { getCurrentPos } from "../../../../state/modules/geo"
import { getSupportList } from "../../../../services/NavService"
import { sxNavList } from "./styles"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useNavOwner } from "../../../../services/BaseService"

const NavItem = ({ nav, dataCy }) => {
  const history = useHistory()
  const user = useNavOwner(nav)

  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(getCurrentPos())
    history.push(`/navs/${nav.id}`)
  }

  return (
    <Card
      sx={nav.alert_status ? sxNavList.alertBackground : sxNavList.card}
      style={{ padding: "2px 0 0px" }}
      elevation={0}
      variant="outlined"
    >
      {nav.status === "FINISHED" && user.id === nav.baseuser_id && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            history.push(`/debrief/${nav.id}`)
          }}
        >
          Débriefer
        </Button>
      )}
      <CardActionArea
        onClick={handleClick}
        style={{ padding: "0px" }}
        data-cy={dataCy}
      >
        <CardContent style={{ padding: "0px" }}>
          <List sx={sxNavList.list}>
            <ListItem sx={sxNavList.listItem} style={{ height: "20px" }}>
              <ListItemText
                secondary={getSupportList(nav.navsupports)}
                style={{ textAlign: "center" }}
              />
            </ListItem>
          </List>
          <List sx={sxNavList.list}>
            <ListItem sx={sxNavList.listItem}>
              <Typography
                variant="h6"
                sx={
                  nav.alert_status
                    ? sxNavList.alertText
                    : nav.status === "DEBRIEFED" &&
                      nav.debrief_all_clear === false
                    ? sxNavList.accidentReportedText
                    : sxNavList.cardText
                }
                gutterBottom
              >
                {user.fullname}
              </Typography>
            </ListItem>
            <ListItem sx={sxNavList.listItem}>
              <ListItemText
                primary="Début"
                secondary={formatStartTime(nav.start)}
              />
            </ListItem>
            <ListItem sx={sxNavList.listItem}>
              <ListItemText primary="Fin" secondary={formatEndTime(nav.end)} />
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default NavItem
