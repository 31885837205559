import { createSlice } from "@reduxjs/toolkit"
import * as R from "ramda"

const authSlice = createSlice({
  name: "auth",
  initialState: { tokenCheck: true, access: null, refresh: null, user: null },
  reducers: {
    loginOk: (state, { payload }) => ({
      ...state,
      tokenCheck: false,
      ...payload,
    }),
    removeUser: R.compose(
      R.assoc("user", null),
      R.assoc("access", null),
      R.assoc("refresh", null),
    ),
    refreshToken: (state, { payload }) => ({
      ...state,
      tokenCheck: true,
      ...payload,
    }),
    refreshTokenOk: (state, { payload }) => ({
      ...state,
      tokenCheck: false,
      user: payload,
    }),
    refreshTokenError: (state, { payload }) => ({
      tokenCheck: false,
      token: null,
      tokenError: payload,
    }),
    logout: (state) => ({
      ...state,
      loginOut: true,
    }),
    logoutOk: () => ({
      token: null,
      user: null,
    }),
  },
})

export const {
  loginOk,
  removeUser,
  refreshToken,
  refreshTokenOk,
  refreshTokenError,
  logout,
  logoutOk,
} = authSlice.actions

export default authSlice.reducer
