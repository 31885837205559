import * as R from "ramda"

import { createSlice } from "@reduxjs/toolkit"

const geoSlice = createSlice({
  name: "geo",
  initialState: {
    checking: false,
    active: false,
    lastPos: null,
    mapTarget: null,
  },
  reducers: {
    // geolocCheckOk: R.compose(
    //   R.assoc("active", true),
    //   R.assoc("checking", false),
    // ),
    // geolocCheckErr: (state, { payload }) =>
    //   R.compose(
    //     R.assoc("active", false),
    //     R.assoc("checking", false),
    //     R.assoc("error", payload),
    //   )(state),

    getCurrentPos: R.compose(R.assoc("checking", true), R.dissoc("lastPos")),
    getCurrentPosOk: (state, action) => {
      return R.compose(
        R.dissoc("checking"),
        R.assoc("lastPos", action.payload.pos),
      )(state)
    },
    getCurrentPosError: (state, action) =>
      R.compose(
        R.dissoc("checking"),
        R.assoc("error", action.payload.message),
      )(state),

    setMapTarget: (state, { payload }) => R.assoc("mapTarget", payload, state),
    clearMapTarget: R.assoc("mapTarget", "outside"),
  },
})

// Action creators are generated for each case reducer function
export const {
  // startGeolocCheck,
  // stopGeolocCheck,
  // geolocCheckOk,
  // geolocCheckErr,
  getCurrentPos,
  getCurrentPosOk,
  getCurrentPosError,
  setMapTarget,
  clearMapTarget,
} = geoSlice.actions

export default geoSlice.reducer
