import { applyMiddleware, compose, createStore } from "redux"

import { ajax as rxAjax } from "rxjs/observable/dom/ajax"
import auth from "../services/AuthService"
import { createEpicMiddleware } from "redux-observable"
import { loadState } from "./localStorage"
import reducers from "."
import rootEpic from "./rootEpic"
import { async as scheduler } from "rxjs/scheduler/async"
import webSocket from "../services/WebSocketService"

const ajax = (props) =>
  rxAjax({
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${auth.getTokens().access}`,
      "Content-Type": "application/json",
    },
    ...props,
  })

export const epicMiddleware = createEpicMiddleware({
  dependencies: {
    ajax,
    webSocket,
    auth,
    scheduler,
  },
})

const configureStore = () => {
  const middlewares = [epicMiddleware]

  const persistedState = loadState()

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  epicMiddleware.run(rootEpic)

  return store
}

export default configureStore
