import { Box, Card, Typography } from "@mui/material"

import NavItem from "./NavItem"
import React from "react"
import { capitalize } from "../../../../services/MainService"
import { sxNavList } from "./styles"
import { translateStatus } from "../../../../services/NavService"

const NavBlock = ({ navs, status }) => {
  const amount = navs?.length || 0

  return (
    <Box sx={sxNavList.block}>
      <Card sx={sxNavList.headerCard} elevation={0} variant="outlined">
        <Typography align="center" variant="h5">
          {capitalize(translateStatus({ status, plural: true }))} ({amount})
        </Typography>
      </Card>
      {navs?.map((nav, index) => (
        <NavItem key={nav.id} nav={nav} dataCy={`${status}-${index}`} />
      ))}
    </Box>
  )
}

export default NavBlock
