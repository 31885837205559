import { API_HTTP, JWT_AUTH_TOKEN } from "../config"

import axios from "axios"

export const logInPromise = (values) =>
  axios.post(`${API_HTTP}/users/log_in/`, values)

export const getInvitation = async (key) => {
  const result = await axios.get(`${API_HTTP}/users/invitation/public/${key}/`)
  return result.data
}

export const invitationSignup = (values) =>
  axios.post(`${API_HTTP}/users/invitation/signup/`, values)

export const baseSignup = (values) =>
  axios.post(`${API_HTTP}/users/base_sign_up/`, values)

export const resetPassword = async (values) => {
  const result = await axios.post(`${API_HTTP}/users/password_reset/`, values)

  return result
}
export const resetPwdCheckToken = async (values) => {
  const result = await axios.post(
    `${API_HTTP}/users/password_reset/validate_token/`,
    values,
  )
  return result
}

export const sendNewPassword = async (values) => {
  const result = await axios.post(
    `${API_HTTP}/users/password_reset/confirm/`,
    values,
  )
  return result
}
