import * as Yup from "yup"

import { Box, Button, LinearProgress, Typography } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { TopLevelErrors, handleError } from "../../../components/formik"
import { sxForm, sxSubmit } from "../../../styles"

import { AuthShortcuts } from "../components"
import { Edit } from "@mui/icons-material"
import { HeaderWithIcon } from "../../../components"
import React from "react"
import { TextField } from "formik-mui"
import { resetPassword } from "../../../../legacyApi"
import { useHistory } from "react-router-dom"

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("Requis"),
})

const ForgotPassword = () => {
  const history = useHistory()

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      await resetPassword(values)
      history.push("/password/email-sent/")
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <>
      <HeaderWithIcon title="Mot de passe oublié" icon={<Edit />} />
      <Typography>
        Renseignez l'email avec lequel vous êtes inscrit.e sur navisure.app pour
        recevoir un email de réinitialisation.
      </Typography>

      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, status }) => (
          <Box sx={sxForm}>
            <Form>
              <TopLevelErrors status={status} />
              <Field
                component={TextField}
                name="email"
                type="email"
                label="Email"
                fullWidth
                data-cy="InputEmail"
              />
              {isSubmitting && <LinearProgress />}
              <Button
                sx={sxSubmit}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                fullWidth
                data-cy="ButtonSubmit"
              >
                Réinitialiser le mot de passe
              </Button>
            </Form>
          </Box>
        )}
      </Formik>
      <AuthShortcuts />
    </>
  )
}

export default ForgotPassword
