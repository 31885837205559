import * as R from "ramda"

import {
  formatDistanceToNow,
  isAfter,
  isBefore,
  isDate,
  isSameDay,
  parseISO,
} from "date-fns"

import { formatDuration } from "./MainService"
import { formatTime } from "./MainService"
import { fr } from "date-fns/locale"
import { getPos } from "./GeoService"
import { updateNavWS } from "../state/modules/navs"
import { useSelector } from "react-redux"
import { useSupport } from "./WarehouseService"
import { useViewerBaseUser } from "./UserService"

// Nav List
export const useNavs = ({
  viewerOnly = null,
  status = null,
  groupBy = false,
  date = null,
  start = null,
  end = null,
} = {}) => {
  const currentBaseUserId = useViewerBaseUser()?.id
  return R.compose(
    R.when(
      R.always(viewerOnly),
      R.filter(R.propEq("baseuser_id", currentBaseUserId)),
    ),
    R.when(R.always(status), R.filter(R.propEq("status", status))),
    R.when(R.always(groupBy), R.groupBy(R.prop("status"))),
    R.when(
      R.always(end),
      R.filter((nav) => isBefore(parseISO(nav.start), end)),
    ),
    R.when(
      R.always(start),
      R.filter((nav) => isAfter(parseISO(nav.start), start)),
    ),
    R.when(
      R.always(date),
      R.filter((nav) => isSameDay(parseISO(nav.start), date)),
    ),
  )(useSelector(R.path(["navs", "navs"])))
}

export const useGroupedNavs = () => R.groupBy((nav) => nav.status, useNavs())

// Nav Detail

export const getInitialValues = (lastNav) => {
  const blankValues = {
    planned_start: null,
    planned_end: null,
    zone_ids: [],
    navsupports: [],
    navmotorboats: [],
  }

  return lastNav
    ? R.mergeDeepLeft(
        R.compose(R.pick(["navsupports", "navmotorboats", "zone_ids"]))(
          lastNav,
        ),
        blankValues,
      )
    : blankValues
}

export const useSummary = (nav) => {
  const support = useSupport(nav.support_id)
  return `${support.name} ${formatDuration(nav.start, nav.end)}`
}

export const useNav = (selectedNavId) =>
  R.find(R.propEq("id", parseInt(selectedNavId)), useNavs())

export const translateStatus = ({ status, alert_status, plural = false }) => {
  if (alert_status) {
    return "en alerte"
  }
  switch (status) {
    case "ONGOING":
      return "en cours"
    case "PLANNED":
      return plural ? "programmées" : "programmée"
    case "FINISHED":
      return plural ? "terminées sans debrief" : "terminée sans debrief"
    case "DEBRIEFED":
      return plural ? "terminées et débriefées" : "terminée et débriefée"
    default:
      return "statut inconnu"
  }
}

export const handleSendEvent = async ({
  nav,
  kind,
  desc = "",
  loadingAction,
  closeAction,
  dispatch,
}) => {
  loadingAction(true)
  const pos = await getPos()
  const data =
    kind === "finish"
      ? {
          id: nav.id,
          status: "FINISHED",
          real_end: new Date().toISOString(),
          event: {
            ...pos,
            kind,
            desc,
          },
        }
      : {
          id: nav.id,
          event: {
            ...pos,
            kind,
            desc,
          },
        }
  dispatch(updateNavWS(data))
  loadingAction(false)
  closeAction()
}

export const getLastGeolocEvent = R.compose(
  R.last,
  R.filter(R.and(R.has("lat"), R.has("lng"))),
  R.propOr([], "events"),
)

export const getEventDesc = (kind, lang = "fr") => {
  switch (kind) {
    case "start":
      return "Départ"
    case "pin":
      return "Actualisation position"
    case "alert":
      return "Alerte"
    case "solved":
      return "Alerte résolue"
    default:
      return "Evénement iconnu"
  }
}

export const navTimeInfos = (nav) => {
  const outArr = []

  const start = nav.real_start || nav.planned_start
  const end = nav.real_end || nav.planned_end

  outArr.push("Début")

  outArr.push(`${formatTime(start)}.`)

  outArr.push("Fin")

  outArr.push(formatTime(end))

  outArr.push(
    `(${formatDistanceToNow(isDate(end) ? end : parseISO(end), {
      addSuffix: true,
      locale: fr,
    })})`,
  )
  return outArr.join(" ")
}

export const getNavColor = (index, count, alert) => {
  const colorCursor = (100 / count) * index

  if (alert) {
    const variableRed = `rgb(${255 - colorCursor},${0 + colorCursor},${
      0 + colorCursor
    },1)`
    return variableRed
  }
  const variableGreen = `rgb(0,${200 - colorCursor},${0 + colorCursor},1)`
  return variableGreen
}

export const getLatLng = R.compose(R.values, R.pick(["lat", "lng"]))

export const getSupportList = (navsupports = [], asArray = false) => {
  const list = navsupports?.map(
    (ns, index) => `${ns.support.name} (${ns.count})`,
  )
  if (asArray) return list
  return list.length > 0 ? list.join(" | ") : "Pas de supports"
}

export const getMotorboatList = (navmotorboats = [], asArray = false) => {
  const list = navmotorboats.map(
    (nm, index) =>
      `[${nm.motorboat.identification}] ${nm.motorboat.name} (${nm.additional_crews})`,
  )
  if (asArray) return list
  return list.length > 0 ? list.join(" | ") : "Pas de semi-rigide"
}
