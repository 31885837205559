import { Card, CardContent, Typography } from "@mui/material"

import React from "react"

const ZoneItem = ({ zone }) => (
  <Card variant="outlined">
    <CardContent>
      <Typography color="primary" variant="h6">
        {zone.name}{" "}
      </Typography>
      <Typography variant="body1">{zone.description}</Typography>
    </CardContent>
  </Card>
)

export default ZoneItem
