import * as R from "ramda"

import { Box, CircularProgress, Grid, Paper } from "@mui/material"

import React from "react"
import { useSelector } from "react-redux"

const gBlock = {
  p: 2,
  display: "flex",
  flexDirection: "column",
}

export const GridBlock = ({ children, style = {}, noSkeleton, ...props }) => {
  const fetching = useSelector(R.pathOr(false, ["reporting", "fetching"]))

  return (
    <Grid item {...props}>
      <Paper sx={{ ...gBlock, ...style }}>
        {fetching && !noSkeleton ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </Paper>
    </Grid>
  )
}
