import { PickersDay } from "@mui/x-date-pickers"
import { format } from "date-fns"

const Day = (props) => {
  const { day, ...rest } = props

  const dataCy = `cal-${format(day, "dd/MM/yyyy")}`

  return <PickersDay day={props.day} {...rest} data-cy={dataCy} />
}

export default Day
