import React from "react"

import MotorboatForm from "./MotorboatForm"

const SecurityBoats = ({ values }) => (
  <>
    <MotorboatForm navmotorboats={values.navmotorboats} />
  </>
)
export default SecurityBoats
