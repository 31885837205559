import * as R from "ramda"
import * as Yup from "yup"

import {
  PositiveDecimalField,
  TextField,
  TopLevelErrors,
  handleError,
} from "../../../../../../../components/formik"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Formik } from "formik"
import LinearProgress from "@mui/material/LinearProgress"
import LoadingButton from "@mui/lab/LoadingButton"
import React from "react"
import Stack from "@mui/material/Stack"
import { createMotorboatFav } from "../../../../../../../../legacyApi"
import { favMotorboat } from "../../../../../../../../state/modules/motorboats"
import { useDispatch } from "react-redux"

const AddMotorboatDialog = ({
  motorboatName,
  setMotorboatName,
  setFormValue,
  dataCy,
}) => {
  const dispatch = useDispatch()

  const handleSubmit = async (values, status, setStatus, setSubmitting) => {
    try {
      const motorboat = await createMotorboatFav(values)
      dispatch(favMotorboat({ motorboat }))
      setFormValue(motorboat)
      handleClose()
    } catch (error) {
      console.log("error: ", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  const handleClose = () => {
    setMotorboatName("")
  }

  const MotoboatSchema = Yup.object().shape({
    name: Yup.string().required("Requis"),
    identification: Yup.string().required("Requis"),
    length: Yup.number(
      "Entrez un nombre valide (séparez les décimales avec un point (.)",
    ).required("Requis"),
    horsepower: Yup.number(
      "Entrez un nombre valide (séparez les décimales avec un point (.)",
    ).required("Requis"),
  })

  if (R.isEmpty(motorboatName)) return null

  return (
    <Formik
      initialValues={{
        name: motorboatName,
        identification: "",
        length: "",
        horsepower: "",
      }}
      validationSchema={MotoboatSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, status }) => (
        <Dialog open={true} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Renseignez les informations du semi-rigide</DialogTitle>
          <DialogContent>
            <br />
            <Stack spacing={2}>
              <TopLevelErrors status={status} />
              <TextField
                name="name"
                label="Nom du semi-rigide"
                type="text"
                fullWidth
                data-cy={`${dataCy}.name`}
              />
              <TextField
                name="identification"
                label="Immatriculation"
                type="text"
                fullWidth
                data-cy={`${dataCy}.identification`}
              />
              <PositiveDecimalField
                name="length"
                label="Longueur (m)"
                fullWidth
                data-cy={`${dataCy}.length`}
              />
              <PositiveDecimalField
                name="horsepower"
                label="Puissance (cv)"
                fullWidth
                data-cy={`${dataCy}.horsepower`}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            {isSubmitting && <LinearProgress />}
            <Button data-cy={`${dataCy}.dialog.cancel`} onClick={handleClose}>
              Annuler
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitForm}
              variant="contained"
              color="primary"
              type="submit"
              data-cy={`${dataCy}.dialog.submit`}
            >
              Ajouter
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
export default AddMotorboatDialog
