import { grey } from "@mui/material/colors"

export const sxInNav = {
  root: {
    height: "100vh",
    display: "grid",
    gridTemplateRows: "4fr 5fr 3fr",
  },
  navInfosWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // background: "purple",
  },
  navInfos: {
    textAlign: "center",
    // background: "red",
  },
  events: {
    overflow: "auto",
    // background: "green",
  },
  baseInfos: {
    padding: (theme) =>
      `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
        // background: "yellow",
        2,
      )} ${theme.spacing(2)}`,
    textAlign: "center",
  },
  alertBtn: {
    position: "fixed",
    bottom: "12px",
    right: "12px",
    zIndex: 1200,
  },
  informBtn: {
    position: "fixed",
    bottom: "12px",
    left: "12px",
    zIndex: 1200,
  },
  extendedIcon: {
    marginRight: (theme) => theme.spacing(1),
  },
}

export const sxNav = {
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: (theme) => theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
    paddingBottom: "50px",
  },
  box: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    padding: (theme) => theme.spacing(1),
  },
  helperText: {
    gridColumnEnd: "span 2",
    gridRowEnd: "span 1",
    display: "grid",
    alignItems: "center",
    justifySelf: "center",
    margin: (theme) => `0 ${theme.spacing(2)}`,
    textAlign: "center",
  },
  button: {
    width: "100%",
    mb: (theme) => theme.spacing(1),
    padding: (theme) => `${theme.spacing(2)} 0`,
  },
  bigButton: {
    width: "100%",
    mb: (theme) => theme.spacing(1),
    padding: (theme) => `${theme.spacing(4)} 0`,
  },
  card: {
    padding: (theme) => theme.spacing(1),
    textAlign: "center",
    color: (theme) => theme.palette.text.secondary,
  },
}

export const sxEventItem = {
  card: {
    backgroundColor: grey[200],
  },
  cardContent: {
    paddingTop: 0,
  },
  start: {
    backgroundColor: (theme) => theme.palette.info.light,
  },
  pin: {
    backgroundColor: (theme) => theme.palette.info.light,
  },
  alert: {
    backgroundColor: (theme) => theme.palette.error.light,
  },
  solved: {
    backgroundColor: (theme) => theme.palette.success.light,
  },
  end: {
    backgroundColor: (theme) => theme.palette.info.light,
  },
}
