import { Box, CircularProgress, Paper, Typography } from "@mui/material"

import React from "react"

const mBlock = {
  p: 2,
  display: "flex",
  flexDirection: "column",
}

export const MasonryBlock = ({
  title,
  fetching,
  children,
  style = {},
  noSkeleton,
  ...props
}) => (
  <Paper sx={mBlock}>
    <Typography variant="h5" align="center" color="primary">
      {title}
    </Typography>

    {fetching && !noSkeleton ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      children
    )}
  </Paper>
)
