import React from "react"

import { Field } from "formik"
import { Switch as MUISwitch, FormControlLabel } from "@mui/material"

export const Switch = ({ name, label, ...props }) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <FormControlLabel
          name={name}
          control={<MUISwitch {...field} checked={field.value} />}
          label={label}
        />
      )}
    </Field>
  )
}
