import * as R from "ramda"

import { getUserId, getViewerBaseUser } from "../selectors"
import { useNavs } from "./NavService"
import { useSelector } from "react-redux"

export const formatFrMobile = (mobile) => {
  const mobileWithoutSpace = mobile.replace(/\D/g, "")
  const intFrMobile = `+33${R.takeLast(9, mobileWithoutSpace)}`
  return intFrMobile
}

export const useUserId = () => useSelector(getUserId())

export const getIntFrMobile = (user) => {
  if (!user) {
    return ""
  }
  const mobile = user.mobile
  return formatFrMobile(mobile)
}

export const useViewerOngoingNav = () => {
  const viewerBaseUser = useViewerBaseUser()

  const navs = useNavs({ status: "ONGOING" })
  return (
    viewerBaseUser &&
    R.find((nav) => nav.baseuser_id === viewerBaseUser.id)(navs)
  )
}

export const useViewerLastNav = () => {
  const viewerBaseUser = useViewerBaseUser()
  // console.log("viewerBaseUser", viewerBaseUser)
  const navs = useNavs()
  // console.log("navs", navs)

  const lastNav =
    viewerBaseUser &&
    R.find((nav) => nav.baseuser_id === viewerBaseUser.id)(navs)
  return lastNav
}

export const useViewerBaseUser = () => useSelector(getViewerBaseUser)

export const useUserById = (userId) => {
  const baseId = useSelector(R.path(["auth", "user", "active_base_id"]))
  const baseUsers = useSelector(R.path(["baseusers", `${baseId}`]))
  const baseUser = R.find(R.pathEq(["user", "id"], userId))(baseUsers)

  return baseUser.user
}
