import * as R from "ramda"

import { useDispatch, useSelector } from "react-redux"

import Error from "./Error"
import Prompt from "./Prompt"
import React from "react"
import { getCurrentPos } from "../../../../../state/modules/geo"

const Geoloc = ({ viewerBaseUser }) => {
  const checking = useSelector(R.path(["geo", "checking"]))
  const dispatch = useDispatch()

  const handleGeolocate = () => dispatch(getCurrentPos(viewerBaseUser))

  switch (viewerBaseUser.user.geoloc_status) {
    case "ERROR":
      return <Error checking={checking} handleGeolocate={handleGeolocate} />
    default:
      return <Prompt checking={checking} handleGeolocate={handleGeolocate} />
  }
}

export default Geoloc
