import { Button, ButtonGroup } from "@mui/material"
import React, { useState } from "react"

import { CalendarToday } from "@mui/icons-material"
import MapIcon from "@mui/icons-material/Map"

const sxHeaderButtons = {
  button: {
    padding: "8px 20px",
  },
}

const HeaderButtons = ({ date, setDate, openMap, width, toggleCal }) => {
  return (
    <>
      <ButtonGroup color="primary" variant="text">
        <Button
          onClick={toggleCal}
          aria-label="menu"
          sx={sxHeaderButtons.button}
          color="primary"
          data-cy="button-calendar"
        >
          <CalendarToday />
        </Button>

        {width < 1024 && (
          <Button
            onClick={openMap}
            aria-label="map"
            sx={sxHeaderButtons.button}
            color="primary"
            data-cy="button-map"
          >
            <MapIcon />
          </Button>
        )}
      </ButtonGroup>
    </>
  )
}

export default HeaderButtons
