import * as R from "ramda"
import * as Yup from "yup"

import { Box, Button, LinearProgress, Stack } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { TopLevelErrors, handleError } from "../../components/formik"
import { getUserFromToken, storeTokens } from "../../../services/AuthService"
import { sxForm, sxSubmit } from "../../styles"

import { AuthShortcuts } from "./components"
import { CollapsibleAlert } from "../../components"
import { HeaderWithIcon } from "./../../components"
import { Lock } from "@mui/icons-material"
import React from "react"
import { TextField } from "formik-mui"
import { logInPromise } from "../../../legacyApi"
import { loginOk } from "../../../state/modules/auth"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Requis"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(64, "Too Long!")
    .required("Requis"),
})

const LogIn = () => {
  const tokenError = useSelector(R.path(["auth", "tokenError"]))
  const dispatch = useDispatch()

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      const { data } = await logInPromise(values)
      setStatus({ ...status, errors: null })
      storeTokens(data)
      dispatch(loginOk({ ...data, user: getUserFromToken() }))
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <>
      <HeaderWithIcon title="Connexion" icon={<Lock />} />

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, status }) => (
          <Box sx={sxForm}>
            <Form>
              <Stack spacing={2}>
                <TopLevelErrors status={status} />
                {tokenError && (
                  <CollapsibleAlert severity="error" title="Reconnectez-vous">
                    {tokenError}
                  </CollapsibleAlert>
                )}
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  data-cy="input-email"
                />
                <Field
                  component={TextField}
                  type="password"
                  label="Password"
                  name="password"
                  fullWidth
                  data-cy="input-password"
                />
                {isSubmitting && <LinearProgress />}
                <Button
                  sx={sxSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  fullWidth
                  data-cy="button-submit"
                >
                  Valider
                </Button>
              </Stack>
            </Form>
          </Box>
        )}
      </Formik>

      <AuthShortcuts />
    </>
  )
}

export default LogIn
