export const sxNav = {
  root: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "4fr 6fr",
  },
  rootMobile: {
    height: "100%",
    display: "grid",
  },
  list: {
    maxHeight: "100vh",
  },
  map: {
    height: "100%",
  },
  mapMobile: {
    height: "100vh",
    width: "100vw",
  },
  mapIcon: {
    position: "fixed",
    top: "72px",
    right: "12px",
    zIndex: 1200,
  },
  mapButton: {
    position: "fixed",
    margin: "auto",
    width: "300px",
    left: "100px",
    right: 0,
    top: "12px",
    textAlign: "center",
    zIndex: 1200,
  },
  mapButtonMobile: {
    position: "fixed",
    margin: "auto",
    width: "100%",
    left: 0,
    right: 0,
    bottom: "12px",
    textAlign: "center",
    zIndex: 1200,
  },
  exitMap: {
    position: "fixed",
    margin: "auto",
    width: "100%",
    textAlign: "center",
    zIndex: 1200,
  },
}
