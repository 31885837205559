import * as Yup from "yup"

import { Box, Button, LinearProgress, Typography } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { TopLevelErrors, handleError } from "../../../components/formik"
import { sxForm, sxSubmit } from "../../../styles"

import { AuthShortcuts } from "../components"
import { HeaderWithIcon } from "../../../components"
import { Lock } from "@mui/icons-material"
import React from "react"
import { TextField } from "formik-mui"
import { sendNewPassword } from "../../../../legacyApi"
import { useHistory } from "react-router-dom"

const SetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Requis"),
  password_check: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Les mots de passe ne sont pas identiques",
    ),
  }),
})

const SetPassword = ({ classes, token }) => {
  const history = useHistory()

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    try {
      sendNewPassword(values)
      history.push("/")
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <>
      <HeaderWithIcon
        title="Réinitialiser votre mot de passe"
        icon={<Lock />}
      />
      <Typography>
        Renseignez l'email avec lequel vous êtes inscrit.e sur navisure.app pour
        recevoir un email de réinitialisation.
      </Typography>

      <Formik
        initialValues={{
          password: "",
          password_check: "",
          token,
        }}
        validationSchema={SetPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, status }) => (
          <Box sx={sxForm}>
            <Form>
              <TopLevelErrors status={status} />
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Mot de passe"
                fullWidth
                data-cy="InputPassword"
              />
              <br />
              <br />
              <Field
                component={TextField}
                name="password_check"
                type="password"
                label="Confirmez votre mot de passe"
                fullWidth
                data-cy="InputPasswordConfirmation"
              />
              {isSubmitting && <LinearProgress />}
              <Button
                sx={sxSubmit}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                fullWidth
                data-cy="ButtonSubmit"
              >
                Réinitialiser le mot de passe
              </Button>
            </Form>
          </Box>
        )}
      </Formik>
      <AuthShortcuts />
    </>
  )
}

export default SetPassword
