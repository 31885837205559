import { Button, Typography } from "@mui/material"

import { HeaderWithIcon } from "../../../components"
import { PersonAdd } from "@mui/icons-material"
import React from "react"
import { sxSubmit } from "../../../styles"

const Intro = ({ setStep, invitation }) => (
  <>
    <HeaderWithIcon title={invitation.base.name} icon={<PersonAdd />} />

    <Typography component="h1" variant="h5" gutterBottom>
      Dispositif de sécurité et d'intervention
    </Typography>
    <Typography variant="h6" gutterBottom>
      Bienvenue {invitation.receiver_firstname},
    </Typography>
    <Typography variant="h6" gutterBottom>
      Créez votre compte pour pouvoir ensuite
    </Typography>
    <Typography variant="body1" gutterBottom>
      <ul>
        <li>Procéder à l'émargement départ et retour</li>
        <li>Programmer vos séances</li>
        <li>Alerter en temps réel le RTQ en cas de souci (beta)</li>
      </ul>
    </Typography>
    <Typography variant="h6" gutterBottom>
      Pour vous inscrire, préparez :
    </Typography>
    <Typography variant="body1" gutterBottom>
      <ul>
        <li>Votre numéro de carte professionnelle,</li>
        <li>
          Et si vous utilisez votre semi-rigide, son nom et son
          n°&nbsp;d'immatriculation
        </li>
      </ul>
    </Typography>
    <Button
      sx={sxSubmit}
      variant="contained"
      color="primary"
      onClick={() => setStep("form")}
      fullWidth
      data-cy="button-create-account"
    >
      Créer mon compte
    </Button>
  </>
)

export default Intro
