import * as R from "ramda"

import {
  cancelFetchNavs,
  createNav,
  fetchNavs,
  fetchNavsError,
  fetchNavsOk,
  navUpdated,
  startNavSync,
  stopNavSync,
  updateNav,
  updateNavError,
  updateNavWS,
} from "."
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import { interval, of } from "rxjs"
import { formatISO, sub, add } from "date-fns"

import { API_HTTP } from "../../../config"
import { sendMessage } from "../webSocket"

const now = new Date()
const start = formatISO(sub(now, { weeks: 2 }), { representation: "date" })
const end = formatISO(add(now, { weeks: 2 }), { representation: "date" })

const formatNavValues = (values) => {
  const checkAndFormatDate = (x) =>
    [null, "", undefined].includes(x) ? undefined : x.toISOString()

  return R.compose(
    // R.assoc("real_start", new Date().toISOString()),
    R.evolve({
      planned_end: checkAndFormatDate,
      planned_start: checkAndFormatDate,
    }),
  )(values)
}

const fetchNavsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchNavs.type),
    withLatestFrom(state$),
    switchMap(([__, state]) => {
      const baseId = R.path(["auth", "user", "active_base_id"], state)
      return ajax({
        method: "GET",
        url: `${API_HTTP}/bases/${baseId}/navs/?start=${start}&end=${end}`,
      }).pipe(
        map(({ response }) => fetchNavsOk(response)),
        catchError((error) => fetchNavsError(error.xhr.response.detail)),
        takeUntil(action$.pipe(ofType(cancelFetchNavs.type))),
      )
    }),
  )

const createNavEpic = (action$, state$, { webSocket }) =>
  action$.pipe(
    ofType(createNav.type),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      const nav = {
        ...action.payload.nav,
        pos_coords: state.geo.positions,
      }
      const data = formatNavValues(nav)
      return sendMessage({
        type: "nav.create",
        data,
      })
    }),
    catchError((error) => console.log("error", error)),
  )

const navSyncEpic = (action$, state$, { webSocket }) =>
  action$.pipe(
    ofType(startNavSync.type),
    mergeMap((action) =>
      interval(10000).pipe(
        withLatestFrom(state$),
        map(([time, state]) => {
          // console.log("state.geo", state.geo)
          const data = {
            id: action.payload,
            pos_coords: state.geo.positions,
          }

          // console.log("data", data)
          return sendMessage({
            type: "nav.update",
            data,
          })
        }),
        takeUntil(action$.pipe(ofType(stopNavSync.type))),
      ),
    ),
  )

const updateNavEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(updateNav.type),
    mergeMap((action) => {
      const url = `${API_HTTP}/navs/navs/${action.payload.id}/`
      return ajax({
        method: "POST",
        url,
        body: { token: action.payload },
      }).pipe(
        map(({ response: { token } }) => auth.decodeToken(token)),
        map((user) => navUpdated(user)),
        catchError((error) => {
          auth.deleteToken()
          return of(updateNavError(error.xhr.response.non_field_errors[0]))
        }),
      )
    }),
  )

const updateNavWSEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(updateNavWS.type),
    switchMap((action) => {
      const data = action.payload
      return of(
        sendMessage({
          type: "nav.update",
          data,
        }),
      )
    }),
  )

export default combineEpics(
  createNavEpic,
  fetchNavsEpic,

  navSyncEpic,

  updateNavEpic,
  updateNavWSEpic,
)
