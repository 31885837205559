import { Add, Cancel } from "@mui/icons-material"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { Field, FieldArray } from "formik"

import { PositiveIntegerField } from "../../../../../../components/formik"
import React from "react"
import SupportSearch from "./SupportSearch"

const newNavSupport = { support: null, count: 1, trainees: 1, base_owned: true }

const SupportForm = ({ navsupports }) => (
  <FieldArray name="navsupports">
    {(arrayHelpers) => (
      <Paper
        variant="outlined"
        sx={{
          p: (theme) => theme.spacing(1),
        }}
      >
        <Stack spacing={2}>
          {navsupports.map((ns, index) => {
            const nsPath = `navsupports.${index}`
            return (
              <Stack spacing={1} key={index}>
                <Typography variant="h6" align="center">
                  {ns.support?.name ? ns.support.name : "Ajouter un support"}{" "}
                  <IconButton
                    aria-label="delete"
                    color="neutral"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <Cancel />
                  </IconButton>
                </Typography>
                <Field
                  component={SupportSearch}
                  type="text"
                  name={`${nsPath}.support`}
                  label="Sélectionner le support"
                  arrayHelpers={arrayHelpers}
                  nsIndex={index}
                  ns={ns}
                  nsPath={nsPath}
                  dataCy={`autocomplete-${nsPath}`}
                />
                <PositiveIntegerField
                  name={`${nsPath}.count`}
                  label="Nombre de supports"
                  fullWidth
                  data-cy={`${nsPath}.count`}
                />
                <PositiveIntegerField
                  name={`${nsPath}.trainees`}
                  label="Nombre de stagiaires pour ces supports"
                  fullWidth
                  data-cy={`${nsPath}.trainees`}
                />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox data-cy={`${nsPath}.base_owned`} />}
                    label={
                      ns.count > 1
                        ? "Ces supports appartiennent à la base"
                        : "Ce support appartient à la base"
                    }
                    name={`${nsPath}.base_owned`}
                    checked={ns.base_owned}
                    onChange={({ target: { checked } }) =>
                      arrayHelpers.form.setFieldValue(
                        `${nsPath}.base_owned`,
                        checked,
                      )
                    }
                  />
                </FormGroup>
              </Stack>
            )
          })}
        </Stack>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            color="neutral"
            startIcon={<Add />}
            onClick={() => arrayHelpers.push(newNavSupport)}
            data-cy="button-add-support"
          >
            {navsupports.length > 0
              ? "Ajouter un autre support"
              : "Ajouter un support"}
          </Button>
        </Box>
      </Paper>
    )}
  </FieldArray>
)

export default SupportForm
