import { Polygon, Tooltip } from "react-leaflet"

import React from "react"

const blackOptions = { color: "yellow", fillColor: "yellow" }

const Zone = ({ zone }) => {
  return (
    <>
      <Polygon pathOptions={blackOptions} positions={zone.coords}>
        <Tooltip permanent direction="center" opacity={0.5}>
          {zone.name}
        </Tooltip>
      </Polygon>
      {/* <Marker position={getCentroid(zone.coords)}>
      </Marker> */}
    </>
  )
}

export default Zone
