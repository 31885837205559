import React, { useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { HighlightOff, PinDrop, Update } from "@mui/icons-material"
import { addMinutes, format, formatISO, parseISO } from "date-fns"

import { fr } from "date-fns/locale"
import { handleSendEvent } from "../../../../services/NavService"
import { sxNav } from "./styles"
import { updateNavWS } from "../../../../state/modules/navs"
import { useDispatch } from "react-redux"
import { LoadScreen } from "../../../components"

const EditDialog = ({ nav }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const sendEvent = async (kind) =>
    handleSendEvent({
      kind,
      nav: nav,
      loadingAction: setLoading,
      dispatch,
    })

  const handleAddTime = async (duration) => {
    const payload = {
      id: nav.id,
      planned_end: formatISO(addMinutes(parseISO(nav.planned_end), duration)),
    }

    try {
      dispatch(updateNavWS(payload))
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <Box>
      {loading ? (
        <LoadScreen text="Acquisition GPS et envoi au serveur..." />
      ) : (
        <>
          <Button
            sx={sxNav.bigButton}
            variant="contained"
            disabled={loading}
            color="secondary"
            startIcon={<HighlightOff />}
            onClick={() => sendEvent("finish")}
            data-cy="button-edit-finish-nav"
          >
            {loading ? "Envoi au serveur..." : "Terminer la nav"}
          </Button>

          <Button
            sx={sxNav.bigButton}
            variant="contained"
            disabled={loading}
            startIcon={<PinDrop />}
            onClick={() => sendEvent("pin")}
            data-cy="button-edit-update-position"
          >
            {loading ? "Envoi au serveur..." : "Actualiser ma position"}
          </Button>

          {[15, -15].map((duration) => (
            <Button
              key={duration}
              variant="contained"
              sx={sxNav.bigButton}
              startIcon={<Update />}
              onClick={() => handleAddTime(duration)}
              data-cy={`button-edit-time${duration}`}
            >
              Fin de nav {duration > 0 && "+"}
              {duration} min (
              {format(addMinutes(parseISO(nav.planned_end), duration), "p", {
                locale: fr,
              })}
              )
            </Button>
          ))}
        </>
      )}
    </Box>
  )
}
export default EditDialog
