import * as R from "ramda"

import { createSlice } from "@reduxjs/toolkit"

const reportingSlice = createSlice({
  name: "reporting",
  initialState: {
    dateInfos: {
      range: "week",
      targetDate: null,
    },
    navAmount: { fetching: true, amount: null },
    navZones: { fetching: true, list: [] },
    motorboats: { fetching: true, list: [] },
    motorboat: { fetching: true, data: {} },
    supports: { fetching: true, list: [] },
    support: { fetching: true, data: {} },
  },
  reducers: {
    setRange: (state, { payload }) =>
      R.assocPath(["dateInfos", "range"], payload.range, state),
    setTargetDate: (state, { payload }) =>
      R.assocPath(["dateInfos", "targetDate"], payload.targetDate, state),

    fetchNavAmount: R.compose(
      R.assocPath(["navAmount", "fetching"], true),
      R.assocPath(["navAmount", "error"], null),
    ),
    fetchNavAmountOk: (state, { payload }) =>
      R.compose(
        R.assocPath(["navAmount", "fetching"], false),
        R.assocPath(["navAmount", "amount"], payload.amount),
      )(state),
    fetchNavAmountError: (state, { payload }) =>
      R.compose(
        R.assocPath(["navAmount", "fetching"], false),
        R.assocPath(["navAmount", "error"], payload.error),
      )(state),
    fetchNavAmountCancel: R.assocPath(["navAmount", "fetching"], false),

    fetchNavZones: R.compose(
      R.assocPath(["navZones", "fetching"], true),
      R.assocPath(["navZones", "error"], null),
    ),
    fetchNavZonesOk: (state, { payload }) =>
      R.compose(
        R.assocPath(["navZones", "fetching"], false),
        R.assocPath(["navZones", "list"], payload.zones),
      )(state),
    fetchNavZonesError: (state, { payload }) =>
      R.compose(
        R.assocPath(["navZones", "fetching"], false),
        R.assocPath(["navZones", "error"], payload.error),
      )(state),
    fetchNavZonesCancel: R.assocPath(["navZones", "fetching"], false),

    fetchMotorboats: R.compose(
      R.assocPath(["motorboats", "fetching"], true),
      R.assocPath(["motorboats", "error"], null),
    ),
    fetchMotorboatsOk: (state, { payload }) =>
      R.compose(
        R.assocPath(["motorboats", "fetching"], false),
        R.assocPath(["motorboats", "list"], payload.motorboats),
      )(state),
    fetchMotorboatsError: (state, { payload }) =>
      R.compose(
        R.assocPath(["motorboats", "fetching"], false),
        R.assocPath(["motorboats", "error"], payload.error),
      )(state),
    fetchMotorboatsCancel: R.assocPath(["motorboats", "fetching"], false),

    fetchMotorboat: R.compose(
      R.assocPath(["motorboat", "fetching"], true),
      R.assocPath(["motorboat", "error"], null),
      R.assocPath(["motorboat", "data"], {}),
    ),
    fetchMotorboatOk: (state, { payload }) =>
      R.compose(
        R.assocPath(["motorboat", "fetching"], false),
        R.assocPath(["motorboat", "data"], payload.motorboat),
      )(state),
    fetchMotorboatError: (state, { payload }) =>
      R.compose(
        R.assocPath(["motorboat", "fetching"], false),
        R.assocPath(["motorboat", "error"], payload.error),
      )(state),
    fetchMotorboatCancel: R.compose(
      R.assocPath(["motorboat", "fetching"], false),
      R.assocPath(["motorboat", "data"], {}),
    ),

    fetchSupports: R.compose(
      R.assocPath(["supports", "fetching"], true),
      R.assocPath(["supports", "error"], null),
    ),
    fetchSupportsOk: (state, { payload }) =>
      R.compose(
        R.assocPath(["supports", "fetching"], false),
        R.assocPath(["supports", "list"], payload.supports),
      )(state),
    fetchSupportsError: (state, { payload }) =>
      R.compose(
        R.assocPath(["supports", "fetching"], false),
        R.assocPath(["supports", "error"], payload.error),
      )(state),
    fetchSupportsCancel: R.assocPath(["supports", "fetching"], false),

    fetchSupport: R.compose(
      R.assocPath(["support", "fetching"], true),
      R.assocPath(["support", "error"], null),
      R.assocPath(["support", "data"], {}),
    ),
    fetchSupportOk: (state, { payload }) =>
      R.compose(
        R.assocPath(["support", "fetching"], false),
        R.assocPath(["support", "data"], payload.support),
      )(state),
    fetchSupportError: (state, { payload }) =>
      R.compose(
        R.assocPath(["support", "fetching"], false),
        R.assocPath(["support", "error"], payload.error),
      )(state),
    fetchSupportCancel: R.compose(
      R.assocPath(["support", "fetching"], false),
      R.assocPath(["support", "data"], {}),
    ),
  },
})

export const {
  setRange,
  setTargetDate,

  fetchNavAmount,
  fetchNavAmountOk,
  fetchNavAmountError,
  fetchNavAmountCancel,

  fetchNavZones,
  fetchNavZonesOk,
  fetchNavZonesError,
  fetchNavZonesCancel,

  fetchMotorboats,
  fetchMotorboatsOk,
  fetchMotorboatsError,
  fetchMotorboatsCancel,

  fetchSupports,
  fetchSupportsOk,
  fetchSupportsError,
  fetchSupportsCancel,

  fetchMotorboat,
  fetchMotorboatCancel,
  fetchMotorboatError,
  fetchMotorboatOk,

  fetchSupport,
  fetchSupportCancel,
  fetchSupportError,
  fetchSupportOk,
} = reportingSlice.actions

export default reportingSlice.reducer
