import img1 from "./geoloc_ios_1.jpg"
import img2 from "./geoloc_ios_2.jpg"
import img3 from "./geoloc_ios_3.jpg"
import img4 from "./geoloc_ios_4.jpg"
import img5 from "./geoloc_ios_5.jpg"
import img6 from "./geoloc_ios_6.jpg"
import img7 from "./geoloc_ios_7.jpg"
import img8 from "./geoloc_ios_8.jpg"

const iosData = [
  {
    src: img1,
    alt: "Écran d'iPhone montrant l'icone Réglages",
    text: (
      <span>
        Cliquez sur <strong>Réglages</strong>
      </span>
    ),
  },

  {
    src: img5,
    alt: "Écran d'iPhone montrant l'option Confidentialité.",
    text: (
      <span>
        Cliquez sur <strong>Confidentialité</strong>.
      </span>
    ),
  },
  {
    src: img6,
    alt: "Écran d'iPhone montrant l'option Services de Localisation.",
    text: (
      <span>
        Cliquez <strong>Services de localisation</strong>.
      </span>
    ),
  },
  {
    src: img7,
    alt: "Écran d'iPhone montrant l'option Sites Safari.",
    text: (
      <span>
        Cliquez sur <strong>Sites Safari</strong>.
      </span>
    ),
  },
  {
    src: img8,
    alt: "Écran d'iPhone montrant les réglages de géolocalisation de Safari.",
    text: (
      <span>
        Vérifiez que <strong>Autoriser l'accès à votre position</strong> est
        bien sur <strong>Lorsque l'app est active</strong>.
      </span>
    ),
  },
  {
    src: img2,
    alt: "Écran d'iPhone montrant l'option Safari dans les apps, dans Réglages.",
    text: (
      <span>
        Revenez à <strong>Réglages</strong>, plus bas dans la liste de vos apps,
        cliquez sur <strong>Safari</strong>
      </span>
    ),
  },
  {
    src: img3,
    alt: "Écran d'iPhone montrant l'option Position de Safari.",
    text: (
      <span>
        Cliquez sur <strong>Position</strong>
      </span>
    ),
  },
  {
    src: img4,
    alt: "Écran d'iPhone montrant les Réglages de Position pour l'app Safari.",
    text: (
      <span>
        Vérifiez que
        <br />• <strong>Autres sites web</strong> est sur{" "}
        <strong>Demander</strong> ou <strong>Autoriser</strong>
        <br />• <strong>navisure.app</strong> est sur <strong>Autoriser</strong>{" "}
        ou n'apparaît pas encore (auquel cas il vous sera demandé plus tard
        d'autoriser la géoloc).
      </span>
    ),
  },
]

export default iosData
