import * as R from "ramda"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { formatISO } from "date-fns"
import { Formik } from "formik"

import { handleError } from "../../../../components/formik"
import { createNav, updateNavWS } from "../../../../../state/modules/navs"
import { useActiveBaseId } from "../../../../../services/BaseService"
import { useViewerLastNav } from "../../../../../services/UserService"
import { NavOngoingSchema, NavPlannedSchema, useZoneOpts } from "./utils"

import NavStepper from "./NavStepper"

const NewNavForm = ({ nav, nextAction }) => {
  const dispatch = useDispatch()
  const lastNav = useViewerLastNav()
  const lastPos = useSelector(R.path(["geo", "lastPos"]))

  const baseId = useActiveBaseId()

  const kindIsNow = nav?.kind === "now"

  const handleSubmit = async (values, { status, setStatus, setSubmitting }) => {
    const payload = !kindIsNow
      ? R.assoc("status", "PLANNED", values)
      : {
          ...values,
          status: "ONGOING",
          real_start: formatISO(new Date()),
          event: { ...lastPos, kind: "start" },
        }

    try {
      if (payload.id) {
        dispatch(updateNavWS(payload))
      } else {
        dispatch(createNav({ nav: payload, baseId }))
      }
      setStatus({ ...status, errors: null })
      nextAction()
    } catch (error) {
      console.log("error", error)
      handleError(setSubmitting, status, setStatus, error)
    }
  }

  return (
    <Formik
      initialValues={nav}
      validationSchema={kindIsNow ? NavOngoingSchema : NavPlannedSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const isLoading =
          formikProps.isSubmitting || (kindIsNow && !lastPos?.lat)
        return (
          <NavStepper
            isLoading={isLoading}
            kindIsNow={kindIsNow}
            {...formikProps}
          />
        )
      }}
    </Formik>
  )
}

export default NewNavForm
