import * as R from "ramda"

import React from "react"
import ReactMarkdown from "react-markdown"
import { useSelector } from "react-redux"
import { useViewerBaseUser } from "../../../services/UserService"

const RNR = () => {
  const baseuser = useViewerBaseUser()
  const rnr = useSelector(
    R.path(["bases", baseuser.base_id, "rules_n_regulations"]),
  )

  return (
    <div style={{ height: "60vh", overflowY: "auto" }}>
      {/* {rnr} */}
      <ReactMarkdown>{rnr}</ReactMarkdown>
    </div>
  )
}

export default RNR
