import * as R from "ramda"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material"

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HeaderWithIcon } from "../../../components"
import { Propeller } from "../../../components/icons"
import { useParams } from "react-router-dom"
import { fetchMotorboat } from "../../../../state/modules/reporting"

const ReportBlock = ({ motorboatreport, index }) => (
  <Card>
    <CardHeader>{motorboatreport.body}</CardHeader>
    <CardContent></CardContent>
  </Card>
)

const MotorboatDetail = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const fetching = useSelector(R.path(["reporting", "motorboat", "fetching"]))
  const motorboat = useSelector(R.path(["reporting", "motorboat", "data"]))
  console.log("motorboat: ", motorboat)

  useEffect(() => {
    if (R.isEmpty(motorboat) || `${motorboat.id}` !== id) {
      dispatch(fetchMotorboat({ id }))
    }
  }, [dispatch, motorboat, id])

  if (fetching) {
    return <p>Loading...</p>
  }

  return (
    <Box sx={{ m: 1 }}>
      <HeaderWithIcon
        sx={{ p: 4 }}
        title={
          fetching
            ? "Chargement..."
            : `${motorboat.name} (${motorboat.identification})`
        }
        icon={<Propeller />}
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card sx={{ p: 4 }}>
            <Typography variant="h5" color="primary">
              Résumé
            </Typography>
            <Typography>Longueur : {motorboat.length}m</Typography>
            <Typography>Puissance : {motorboat.horsepower}cv</Typography>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card sx={{ p: 4 }}>
            <Typography variant="h5" color="primary">
              Rapports
            </Typography>
            {R.reverse(motorboat.motorboatreports).map(
              (motorboatreport, index) => (
                <ReportBlock motorboatreport={motorboatreport} index={index} />
              ),
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MotorboatDetail
