import * as Sentry from "@sentry/react"

import { Integrations } from "@sentry/tracing"
import React from "react"
import Root from "./views/Root"
import axios from "axios"
import { createRoot } from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration"


axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFToken"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2a6a044fd7ca459289bdcc58a964bed7@o1167691.ingest.sentry.io/6258986",
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 0.3,
    normalizeDepth: 6,
  })
}

const container = document.getElementById("root")

const root = createRoot(container)

root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// serviceWorkerRegistration.unregister()
