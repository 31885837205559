import { Box, Grid, Typography } from "@mui/material"
import { GridBlock } from "../components"

import Container from "@mui/material/Container"
import { Masonry } from "@mui/lab"
import React from "react"

import NavAmount from "./NavAmount"
import ZoneChart from "./ZoneChart"
import Motorboats from "./Motorboats"
import Supports from "./Supports"
import { useDateInfos } from "../../../../services/ReportingService"

const Dashboard = () => {
  const { targetPeriod } = useDateInfos()
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="xl" sx={{ my: 3 }}>
        <Grid container spacing={3}>
          <GridBlock xs={12} noSkeleton>
            <Typography variant="h5" align="center" color="primary">
              {targetPeriod}
            </Typography>
          </GridBlock>
        </Grid>
      </Container>

      <Container maxWidth="xl" sx={{ mb: 4 }}>
        <Masonry columns={{ xs: 1, sm: 2 }} spacing={3} sx={{ m: 0 }}>
          <NavAmount />
          <ZoneChart />
          <Motorboats />
          <Supports />
          {/* <MasonryBlock>
            <Title>{summary?.alerts.title}</Title>
            {summary && <AlertCharts alerts={summary.alerts} />}
          </MasonryBlock>
          <MasonryBlock>
            <Title>{summary?.coaches.title}</Title>
            {summary && <Coaches coaches={summary.coaches.list} />}
          </MasonryBlock> */}
        </Masonry>
      </Container>
    </Box>
  )
}

export default Dashboard
