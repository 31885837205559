import * as R from "ramda"

import { createSlice } from "@reduxjs/toolkit"

const supportsSlice = createSlice({
  name: "supports",
  initialState: {
    fetching: false,
    creating: false,
    results: {},
  },
  reducers: {
    fetchSupports: R.assoc(["fetching"], true),
    fetchSupportsOk: (state, { payload: { results } }) =>
      R.compose(R.assoc("fetching", false), R.assoc("results", results))(state),
    fetchSupportsError: (state, { payload: { error } }) =>
      R.compose(R.assoc("fetching", false), R.assoc("error", error))(state),
    favSupport: (state, { payload: { support } }) =>
      R.over(R.lensPath(["results", "user"]), R.append(support), state),
    unfavSupport: (state, { payload: { id } }) => {
      const index = R.findIndex(R.propEq("id", id))(
        R.path(["results", "user"], state),
      )
      return R.over(R.lensPath(["results", "user"]), R.remove(index, 1), state)
    },
  },
})

export const {
  fetchSupports,
  fetchSupportsOk,
  fetchSupportsError,
  favSupport,
  unfavSupport,
} = supportsSlice.actions

export default supportsSlice.reducer
