import React from "react"

import { useActiveBaseId } from "../../../../../../services/BaseService"

import { Field } from "formik"
import { CheckboxGroup } from "../../../../../components/formik"

import { useZoneOpts } from "../utils"

const Zones = ({ values }) => {
  const baseId = useActiveBaseId()
  const zoneOpts = useZoneOpts(baseId)

  return (
    <>
      <Field
        component={CheckboxGroup}
        name="zone_ids"
        label="Zones prévues"
        options={zoneOpts}
      />
    </>
  )
}
export default Zones
