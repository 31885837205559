import { Field } from "formik"
import TextField from "@mui/material/TextField"

export const PositiveIntegerField = ({ name, ...props }) => (
  <Field name={name}>
    {({
      field: { name, value, onBlur },
      form: { setFieldValue },
      meta: { touched, error },
    }) => {
      const onChange = ({ target: { value } }) => {
        console.log("value: ", value)
        const allowed = /^[0-9 ]+$/
        if (value === "" || allowed.test(value)) {
          return setFieldValue(name, value)
        }
      }

      return (
        <TextField
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={touched && error}
          helperText={error}
          {...props} // label...
        />
      )
    }}
  </Field>
)
