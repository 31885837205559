import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import { useHistory } from "react-router-dom"

const columns = [
  { field: "name", headerName: "Nom", flex: 1 },
  { field: "amount", headerName: "Nombre de navs", flex: 1 },
  { field: "identification", headerName: "Immatriculation", flex: 1 },
]

const MotorboatDataGrid = ({
  motorboats,
  containerProps = {},
  dataGridProps = {},
}) => {
  const { push } = useHistory()

  const handleRowClick = ({ id }) => {
    console.log("id: ", id)
    push(`/reporting/motorboats/${id}/`)
  }
  return (
    <Box sx={{ height: 400, width: "100%" }} {...containerProps}>
      <DataGrid
        sx={{ cursor: "pointer" }}
        rows={motorboats}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        initialState={{
          sorting: {
            sortModel: [{ field: "amount", sort: "desc" }],
          },
        }}
        onRowClick={handleRowClick}
        {...dataGridProps}
      />
    </Box>
  )
}

export default MotorboatDataGrid
