import * as R from "ramda"

import { getActiveBase, getActiveBaseId } from "../selectors"

import { API_HTTP } from "../config"
import axios from "axios"
import { fetchBaseUsers } from "../legacyApi"
import { setBase } from "../state/modules/bases"
import { setBaseUsers } from "../state/modules/baseusers"
import { useSelector } from "react-redux"

export const fetchBase = async (dispatch, baseId) => {
  const res = await axios.get(`${API_HTTP}/bases/${baseId}/`)
  dispatch(setBase(res.data))
}

export const useActiveBaseId = () => useSelector(getActiveBaseId)

export const useActiveBase = () => useSelector(getActiveBase)

export const useBaseRtqUsers = () => {
  const baseId = useActiveBaseId()
  return R.filter(
    (baseuser) => baseuser.role === "rtq",
    useSelector(R.pathOr([], ["baseusers", `${baseId}`])),
  )
}

export const useBaseUser = (baseUserId) =>
  R.find(R.propEq("id", baseUserId))(
    useSelector(R.path(["baseusers", useActiveBaseId()])),
  )

export const useNavOwner = (nav) => {
  const baseUser = useBaseUser(nav.baseuser_id)
  if (baseUser) {
    return baseUser.user
  }
  return {}
}

export const getBaseUsers = async (dispatch, baseId) => {
  const res = await fetchBaseUsers(baseId)
  dispatch(setBaseUsers(res.data))
}

export const useBase = () => useSelector(R.path(["bases", useActiveBaseId()]))

export const useZone = (zoneId) => {
  const base = useActiveBase()
  const zone = R.find(R.propEq("id", parseInt(zoneId)), base.zones)
  return zone
}
