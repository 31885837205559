import { EMPTY, concat, from, of } from "rxjs"
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators"
import { closeSnackbar, enqueueSnackbar } from "../notifications"
// import * as R from "ramda"
import { combineEpics, ofType } from "redux-observable"
import { getCurrentPos, getCurrentPosError, getCurrentPosOk } from "."

import { clearNavInModal } from "../navs"
import { getPos } from "../../../services/GeoService"
import { getViewerBaseUser } from "../../../selectors"
import { nanoid } from "nanoid"
import { setBaseUser } from "../baseusers"

// const geolocCheckId = nanoid()

// const geolocCheckOkEpic = (action$) =>
//   action$.pipe(
//     ofType(geolocCheckOk.type),
//     map(() => closeSnackbar({ key: geolocCheckId })),
//   )

// const geolocCheckErrEpic = (action$) =>
//   action$.pipe(
//     ofType(geolocCheckErr.type),
//     mergeMap((action) => [
//       closeSnackbar({ key: geolocCheckId }),
//       enqueueSnackbar({
//         message: action.payload,
//         options: { key: nanoid(), variant: "error" },
//       }),
//     ]),
//   )
const getCurrentPosOkEpic = (action$, state$) =>
  action$.pipe(
    ofType(getCurrentPosOk.type),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const baseUser = getViewerBaseUser(state)
      if (baseUser.user.geoloc_status === "OK") {
        return EMPTY
      }
      const baseUserPayload = {
        id: baseUser.id,
        base_id: baseUser.base_id,
        user: { geoloc_status: "OK", geoloc_error: "" },
      }
      return of(setBaseUser(baseUserPayload))
    }),
  )

const getCurrentPosErrorEpic = (action$, state$) =>
  action$.pipe(
    ofType(getCurrentPosError.type),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { message } = action
      const baseUser = getViewerBaseUser(state)
      const baseUserPayload = {
        id: baseUser.id,
        base_id: baseUser.base_id,
        user: { geoloc_status: "ERROR", geoloc_error: message },
      }
      return of(setBaseUser(baseUserPayload))
    }),
  )

const getCurrentPosEpic = (action$) =>
  action$.pipe(
    ofType(getCurrentPos.type),
    mergeMap(() => {
      const key = nanoid()
      const getPos$ = from(getPos()).pipe(
        map((pos) => {
          return getCurrentPosOk({ pos })
        }),
        catchError((err) => {
          if (err.code === 1) {
            return concat(
              of(
                getCurrentPosError({
                  message: err.message,
                }),
              ),
              of(clearNavInModal()),
            )
          }
        }),
      )

      return concat(
        of(
          enqueueSnackbar({
            message: "Acquisition de votre position GPS...",
            options: { key, variant: "info" },
          }),
        ),
        concat(getPos$, of(closeSnackbar({ key }))),
      )
    }),
  )

export default combineEpics(
  getCurrentPosEpic,
  getCurrentPosOkEpic,
  getCurrentPosErrorEpic,
)
