import * as R from "ramda"
import {
  Box,
  Button,
  List,
  ListSubheader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import {
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Group,
  Map,
} from "@mui/icons-material"
import { Propeller, Sail } from "../../../components/icons"

import { Divider } from "@mui/material"
import MenuItem from "./MenuItem"
import {
  getPeriodEnd,
  getPeriodStart,
  getTargetPeriod,
  useDateInfos,
  useTargetDate,
} from "../../../../services/ReportingService"
import { setRange, setTargetDate } from "../../../../state/modules/reporting"
import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import { add, formatISO, sub } from "date-fns"

const ranges = [
  { id: "week", label: "semaine" },
  { id: "month", label: "mois" },
  { id: "year", label: "année" },
]

const Menu = () => {
  const dispatch = useDispatch()

  const range = useSelector(R.path(["reporting", "dateInfos", "range"]))
  const targetDate = useTargetDate()

  const { targetPeriod } = useDateInfos()

  const setPrevPeriod = () =>
    dispatch(
      setTargetDate({
        targetDate: formatISO(sub(targetDate, { [`${range}s`]: 1 })),
      }),
    )

  const setNextPeriod = () =>
    dispatch(
      setTargetDate({
        targetDate: formatISO(add(targetDate, { [`${range}s`]: 1 })),
      }),
    )

  const handleToggleRange = (event, range) => {
    dispatch(setRange({ range }))
  }

  return (
    <List component="nav">
      <MenuItem path="/reporting" label="Vue d'ensemble" icon={<Dashboard />} />
      <MenuItem
        path="/reporting/motorboats"
        label="Semi-rigides"
        icon={<Propeller />}
      />
      <MenuItem path="/reporting/supports" label="Supports" icon={<Sail />} />
      <MenuItem
        path="/reporting/navs"
        label="Navigations"
        icon={<Map />}
        disabled
      />
      <MenuItem
        path="/reporting/coaches"
        label="Moniteurs / Entraîneurs"
        icon={<Group />}
        disabled
      />
      <Divider sx={{ mx: 1, my: 2 }} />
      <ListSubheader>Période</ListSubheader>

      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Stack direction="row" alignItems="center">
          <Button size="small" onClick={setPrevPeriod}>
            <ChevronLeft />
          </Button>
          <Typography sx={{ px: 1 }} variant="body2">
            {targetPeriod}
          </Typography>
          <Button size="small" onClick={setNextPeriod}>
            <ChevronRight />
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <ToggleButtonGroup
          value={range}
          exclusive
          onChange={handleToggleRange}
          size="small"
        >
          {ranges.map(({ id, label }) => (
            <ToggleButton key={id} value={id}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>{" "}
      </Box>
    </List>
  )
}
export default Menu
