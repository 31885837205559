import React from "react"
import { useDispatch } from "react-redux"

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Stack,
  Typography,
} from "@mui/material"
import { Form, Formik } from "formik"

import { updateNavWS } from "../../../../../../state/modules/navs"
import { Switch, TextField } from "../../../../../components/formik"

import { sxNavDetail } from "../../styles"
import SupportsForm from "./SupportsForm"
import MotorboatsForm from "./MotorboatsForm"

const NewDebrief = ({ nav, returnToMain }) => {
  const dispatch = useDispatch()

  const initialValues = nav.report || {
    all_clear: false,
    human: "",
    motorboatreports: nav.navmotorboats.map((navmotorboat) => ({
      motorboat_name: navmotorboat.motorboat.fullname,
      motorboat_id: navmotorboat.motorboat.id,
      solved: false,
      body: "",
    })),
    supportreports: nav.navsupports.map((navsupport) => ({
      support_name: navsupport.support.name,
      support_id: navsupport.support.id,
      solved: false,
      body: "",
    })),
  }

  const cleaneReportValues = (values) => {
    if (values.all_clear) {
      return { all_clear: true }
    }
    const supportreports = values.supportreports.filter(
      (supportreport) => supportreport.body !== "",
    )
    const motorboatreports = values.motorboatreports.filter(
      (motorboatreport) => motorboatreport.body !== "",
    )

    return { ...values, supportreports, motorboatreports }
  }

  const handleSubmit = (values) => {
    const report = cleaneReportValues(values)
    try {
      dispatch(
        updateNavWS({
          id: nav.id,
          report,
          status: "DEBRIEFED",
        }),
      )
      returnToMain()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={kindIsNow ? NavOngoingSchema : NavPlannedSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <Stack spacing={2}>
              <Box>
                <Card
                  sx={{
                    p: (theme) => theme.spacing(3),
                    justifyContent: "center",
                  }}
                  variant="outlined"
                >
                  <Switch name="all_clear" label="Rien à déclarer" />
                </Card>
              </Box>
              <Box>
                {!values.all_clear && (
                  <>
                    <Typography align="center" variant="h5" color="primary">
                      Ou…
                    </Typography>
                    <Box>
                      <TextField
                        name="human"
                        label="Problèmes humains, médicaux"
                        fullWidth
                        multiline
                        rows={4}
                      />
                      <SupportsForm supportreports={values.supportreports} />
                      <MotorboatsForm
                        motorboatreports={values.motorboatreports}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit(values)}
                >
                  Enregistrer
                </Button>
              </Box>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default NewDebrief
