import { JWT_AUTH_TOKEN } from "../config"
import axios from "axios"

export const getUserFromToken = () => {
  const auth = JSON.parse(window.localStorage.getItem(JWT_AUTH_TOKEN))
  if (auth) {
    const [, payload] = auth.access.split(".")
    const decoded = window.atob(payload)
    return JSON.parse(decoded)
  }
  return null
}

export const getTokens = () => {
  const auth = JSON.parse(window.localStorage.getItem(JWT_AUTH_TOKEN))
  if (auth) {
    axios.defaults.headers.common.Authorization = `Bearer ${auth.access}`
    return auth
  }
  return {}
}

export const storeTokens = (tokens) => {
  axios.defaults.headers.common.Authorization = `Bearer ${tokens.access}`
  window.localStorage.setItem(JWT_AUTH_TOKEN, JSON.stringify(tokens))
}
export const deleteTokens = () => {
  window.localStorage.removeItem(JWT_AUTH_TOKEN)
  delete axios.defaults.headers.common.Authorization
}

const auth = {
  getTokens,
  storeTokens,
  getUserFromToken,
}

export default auth
