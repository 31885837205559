import React from "react"
import { Route, Switch } from "react-router-dom"

import Main from "./Main"
import Debrief from "./Debrief"

const NavDetail = () => (
  <Switch>
    <Route exact path="/navs/:id" component={Main} />
    <Route path="/navs/:id/debrief" component={Debrief} />
  </Switch>
)

export default NavDetail
