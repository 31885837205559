import { Card, Typography } from "@mui/material"

export const Frame = ({ children }) => {
  return (
    <Card
      variant="outlined"
      sx={{ textAign: "center", padding: "theme.spacing.1" }}
    >
      <Typography variant="h6">{children}</Typography>
    </Card>
  )
}
