import { MapContainer as LeafletMapContainer, TileLayer } from "react-leaflet"

import React from "react"

const MapContainer = ({ children, ...props }) => (
  <LeafletMapContainer {...props}>
    <TileLayer
      attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
      maxZoom={20}
    />
    <TileLayer
      attribution='Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
      url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
    />
    {children}
  </LeafletMapContainer>
)

export default MapContainer
