import * as R from "ramda"
import { Box, Button, Drawer } from "@mui/material"
import { LoadScreen, Map } from "../../components"
import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { cancelFetchNavs, fetchNavs } from "../../../state/modules/navs"

import CloseIcon from "@mui/icons-material/Close"
import { MapButtons } from "../../components/Map/MapButtons"
import NavDetail from "./NavDetail"
import NavList from "./NavList"
import { sxNav } from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { useNavs } from "../../../services/NavService"
import { useViewerOngoingNav } from "../../../services/UserService"
import { useWindowDimensions } from "../../../services/WindowDimensionsService"

const Navs = () => {
  const [date, setDate] = useState(new Date())
  const [map, setMap] = useState(false)
  const openMap = () => setMap(true)
  const closeMap = () => setMap(false)

  const dispatch = useDispatch()
  const navs = useNavs()
  const fetchingNavs = useSelector(R.path(["navs", "fetching"]))

  const viewerOngoingNav = useViewerOngoingNav()

  const { width } = useWindowDimensions()

  useEffect(() => {
    if (fetchingNavs) {
      dispatch(fetchNavs())
    }
    return () => dispatch(cancelFetchNavs())
  }, [dispatch, fetchingNavs, navs.length])

  if (fetchingNavs) {
    return <LoadScreen />
  }

  if (viewerOngoingNav) {
    return <Redirect to="/" />
  }

  return (
    <>
      {width < 1024 ? (
        <Box sx={sxNav.rootMobile}>
          <Box sx={sxNav.list}>
            <Switch>
              <Route
                exact
                path="/navs"
                render={(routeProps) => (
                  <NavList
                    {...routeProps}
                    date={date}
                    setDate={setDate}
                    width={width}
                    openMap={openMap}
                  />
                )}
              />
              <Route path="/navs/:id" component={NavDetail} />
            </Switch>
          </Box>

          <Drawer anchor="right" open={map} onClose={closeMap}>
            <Box sx={sxNav.exitMap}>
              <Button
                variant="contained"
                onClick={closeMap}
                color="primary"
                style={{ height: "40px", width: "100%", borderRadius: 0 }}
                startIcon={<CloseIcon />}
              >
                Close
              </Button>
            </Box>

            <Box sx={sxNav.mapMobile}>
              <Route
                path="/navs/:id?"
                render={(routeProps) => (
                  <Map date={date} selectedNavId={routeProps.match.params.id} />
                )}
              />
            </Box>
            <Box sx={sxNav.mapButtonMobile}>
              <MapButtons />
            </Box>
          </Drawer>
        </Box>
      ) : (
        <Box sx={sxNav.root}>
          <Box sx={sxNav.list}>
            <Switch>
              <Route
                exact
                path="/navs"
                render={(routeProps) => (
                  <NavList
                    {...routeProps}
                    date={date}
                    setDate={setDate}
                    width={width}
                  />
                )}
              />
              <Route path="/navs/:id" component={NavDetail} />
            </Switch>
          </Box>
          <Box sx={sxNav.map}>
            <Route
              path="/navs/:id?"
              render={(routeProps) => (
                <Map date={date} selectedNavId={routeProps.match.params.id} />
              )}
            />
          </Box>
          <Box sx={sxNav.mapButton}>
            <MapButtons />
          </Box>
        </Box>
      )}
    </>
  )
}

export default Navs
