import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"

const columns = [
  { field: "name", headerName: "Nom", flex: 1 },
  { field: "amount", headerName: "Nombre de navs", flex: 1 },
]

const SupportGrid = ({ supports, containerProps = {}, dataGridProps = {} }) => {
  const handleRowClick = (props) => {
    console.log(props)
  }
  return (
    <Box sx={{ height: 400, width: "100%" }} {...containerProps}>
      <DataGrid
        sx={{ cursor: "pointer" }}
        rows={supports}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        initialState={{
          sorting: {
            sortModel: [{ field: "amount", sort: "desc" }],
          },
        }}
        onRowClick={handleRowClick}
        {...dataGridProps}
      />
    </Box>
  )
}

export default SupportGrid
