import React from "react"
import { useHistory } from "react-router"
import {
  ListItem as MaterialLisItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

export const ListItem = ({
  primary,
  secondary,
  icon,
  action,
  next,
  ...rest
}) => {
  const history = useHistory()
  const handleClick = () => {
    if (next) {
      next()
    }
    if (typeof action === "string") {
      history.push(action)
    } else {
      action()
    }
  }

  return (
    <MaterialLisItem
      button={action ? true : false}
      onClick={handleClick}
      {...rest}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} secondary={secondary} />
    </MaterialLisItem>
  )
}
