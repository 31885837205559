import * as R from "ramda"

import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { Box, Stack, Typography } from "@mui/material"
import { Formik } from "formik"

import { useUserId } from "../../../../../../services/UserService"
import MotorboatSection from "./Motorboat"
import SupportSection from "./Support"
import { updateNavWS } from "../../../../../../state/modules/navs"

const Existing = ({ nav }) => {
  const dispatch = useDispatch()
  const userId = useUserId()

  const { id, report } = nav

  const handleSubmit = useCallback(
    (values) => {
      dispatch(updateNavWS({ id, report: values }))
    },
    [dispatch, id],
  )

  if (report.debrief_all_clear) {
    return <Typography variant="body2">Rien à signaler</Typography>
  }

  const initialValues = {
    author_id: userId,
    motorboatreports: report.motorboatreports.map((mr) => ({
      ...mr,
      solved: mr.solved ? true : false,
      comment: "",
    })),
    supportreports: report.supportreports.map((sr) => ({
      ...sr,
      solved: sr.solved ? true : false,
      comment: "",
    })),
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={kindIsNow ? NavOngoingSchema : NavPlannedSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        return (
          <Stack spacing={3}>
            <Box>
              <Typography variant="h4" color="primary">
                BILAN HUMAIN
              </Typography>
              <Typography variant="body2">
                {report.human || "Rien à signaler"}
              </Typography>
            </Box>
            <Box>
              {!R.isEmpty(report.motorboatreports) && (
                <>
                  <Typography variant="h4" color="primary">
                    SEMI-RIGIDES
                  </Typography>
                  {report.motorboatreports.map((motorboatreport, index) => (
                    <MotorboatSection
                      key={index}
                      motorboatreport={motorboatreport}
                      index={index}
                      handleSubmit={handleSubmit}
                    />
                  ))}
                </>
              )}
            </Box>
            <Box>
              {!R.isEmpty(report.supportreports) && (
                <>
                  <Typography variant="h4" color="primary">
                    SUPPORTS
                  </Typography>

                  {report.supportreports.map((supportreport, index) => (
                    <SupportSection
                      key={index}
                      supportreport={supportreport}
                      index={index}
                      handleSubmit={handleSubmit}
                    />
                  ))}
                </>
              )}
            </Box>
          </Stack>
        )
      }}
    </Formik>
  )
}

export default Existing
