import * as R from "ramda"
import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { formatISO } from "date-fns"

import { useDateInfos } from "../../../../services/ReportingService"
import { fetchSupports } from "../../../../state/modules/reporting"
import SupportDataGrid from "../components/SupportGrid"
import { HeaderWithIcon } from "../../../components/HeaderWithIcon"
import { Sail } from "../../../components/icons"

const Supports = () => {
  const dispatch = useDispatch()

  const fetching = useSelector(R.path(["reporting", "supports", "fetching"]))
  const supports = useSelector(R.path(["reporting", "supports", "list"]))

  const { periodStart, periodEnd } = useDateInfos()

  useEffect(() => {
    dispatch(
      fetchSupports({
        start: formatISO(periodStart),
        end: formatISO(periodEnd),
      }),
    )
  }, [dispatch, periodStart, periodEnd])

  return (
    <>
      <HeaderWithIcon title="Les supports de la base" icon={<Sail />} />

      <SupportDataGrid
        supports={supports}
        containerProps={{ sx: { height: "80%", width: "100%" } }}
        dataGridProps={{ pageSize: 20 }}
      />
    </>
  )
}

export default Supports
