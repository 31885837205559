import { Grid, Link } from "@mui/material"
import { useHistory, useLocation } from "react-router-dom"

import React from "react"

export const AuthShortcuts = () => {
  const history = useHistory()
  const location = useLocation()

  const rootLocation = location.pathname === "/"
  const forgotPwdLocation = location.pathname === "/password/forgot/"

  const handleGotoConnect = (event) => {
    event.preventDefault()
    history.push("/")
  }

  const handleGotoForgotPwd = (event) => {
    event.preventDefault()
    history.push("/password/forgot/")
  }

  return (
    <Grid>
      {!rootLocation && (
        <Link href="#" onClick={handleGotoConnect} data-cy="link-login">
          Se connecter
        </Link>
      )}
      {!rootLocation && !forgotPwdLocation && <span> • </span>}
      {!forgotPwdLocation && (
        <Link href="#" onClick={handleGotoForgotPwd} data-cy="link-forgot-pwd">
          Mot de passe oublié ?
        </Link>
      )}
    </Grid>
  )
}
