import React, { useState } from "react"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import LoadingButton from "@mui/lab/LoadingButton"
import TextField from "@mui/material/TextField"
import { createSupportFav } from "../../../../../../../../legacyApi"
import { favSupport } from "../../../../../../../../state/modules/supports"
import { useDispatch } from "react-redux"

const AddSupportDialog = ({
  open,
  setOpen,
  value,
  setValue,
  setFormValue,
  dataCy,
}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    setLoading(true)
    const support = await createSupportFav({ name: value })
    dispatch(favSupport({ support }))
    setFormValue(support)
    handleClose()
  }

  const handleClose = () => {
    setValue({
      name: "",
    })

    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Confirmez le support à ajouter</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            label="Nom du support"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button data-cy={`${dataCy}.dialog.cancel`} onClick={handleClose}>
            Annuler
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            type="submit"
            data-cy={`${dataCy}.dialog.submit`}
          >
            Ajouter
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default AddSupportDialog
