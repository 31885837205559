import * as R from "ramda"

import {
  catchError,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs/operators"
import { combineEpics, ofType } from "redux-observable"
import {
  fetchNavAmount,
  fetchNavAmountCancel,
  fetchNavAmountError,
  fetchNavAmountOk,
  fetchNavZones,
  fetchNavZonesCancel,
  fetchNavZonesError,
  fetchNavZonesOk,
  fetchMotorboats,
  fetchMotorboatsCancel,
  fetchMotorboatsError,
  fetchMotorboatsOk,
  fetchSupports,
  fetchSupportsCancel,
  fetchSupportsError,
  fetchSupportsOk,
  fetchMotorboat,
  fetchMotorboatCancel,
  fetchMotorboatError,
  fetchMotorboatOk,
  fetchSupport,
  fetchSupportCancel,
  fetchSupportError,
  fetchSupportOk,
} from "."

import { API_HTTP } from "../../../config"

const fetchNavAmountEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchNavAmount.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { start, end },
        },
        state,
      ]) => {
        return ajax({
          method: "GET",
          url: `${API_HTTP}/bases/${state.auth.user.active_base_id}/reporting/${start}/${end}/nav_amount/`,
        }).pipe(
          map(({ response }) => {
            return fetchNavAmountOk({ amount: response })
          }),
          catchError((error) =>
            fetchNavAmountError({ error: error.xhr.response.detail }),
          ),
          takeUntil(action$.pipe(ofType(fetchNavAmountCancel.type))),
        )
      },
    ),
  )

const fetchNavZonesEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchNavZones.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { start, end },
        },
        state,
      ]) => {
        return ajax({
          method: "GET",
          url: `${API_HTTP}/bases/${state.auth.user.active_base_id}/reporting/${start}/${end}/nav_zones/`,
        }).pipe(
          map(({ response }) => {
            return fetchNavZonesOk({ zones: response })
          }),
          catchError((error) =>
            fetchNavZonesError({ error: error.xhr.response.detail }),
          ),
          takeUntil(action$.pipe(ofType(fetchNavZonesCancel.type))),
        )
      },
    ),
  )

const fetchMotorboatsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchMotorboats.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { start, end },
        },
        state,
      ]) => {
        return ajax({
          method: "GET",
          url: `${API_HTTP}/bases/${state.auth.user.active_base_id}/reporting/${start}/${end}/motorboats/`,
        }).pipe(
          map(({ response }) => {
            return fetchMotorboatsOk({ motorboats: response })
          }),
          catchError((error) =>
            fetchMotorboatsError(error.xhr.response.detail),
          ),
          takeUntil(action$.pipe(ofType(fetchMotorboatsCancel.type))),
        )
      },
    ),
  )

const fetchSupportsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchSupports.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { start, end },
        },
        state,
      ]) => {
        return ajax({
          method: "GET",
          url: `${API_HTTP}/bases/${state.auth.user.active_base_id}/reporting/${start}/${end}/supports/`,
        }).pipe(
          map(({ response }) => {
            return fetchSupportsOk({ supports: response })
          }),
          catchError((error) =>
            fetchSupportsError({ error: error.xhr.response.detail }),
          ),
          takeUntil(action$.pipe(ofType(fetchSupportsCancel.type))),
        )
      },
    ),
  )

const fetchMotorboatEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchMotorboat.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { start, end, id },
        },
        state,
      ]) => {
        return ajax({
          method: "GET",
          url: `${API_HTTP}/bases/${state.auth.user.active_base_id}/reporting/${start}/${end}/motorboats/${id}/`,
        }).pipe(
          map(({ response }) => {
            return fetchMotorboatOk({ motorboat: response })
          }),
          catchError((error) =>
            fetchMotorboatError({ error: error.xhr.response.detail }),
          ),
          takeUntil(action$.pipe(ofType(fetchMotorboatCancel.type))),
        )
      },
    ),
  )

const fetchSupportEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchSupport.type),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { start, end, id },
        },
        state,
      ]) => {
        return ajax({
          method: "GET",
          url: `${API_HTTP}/bases/${state.auth.user.active_base_id}/reporting/${start}/${end}/supports/${id}/`,
        }).pipe(
          map(({ response }) => {
            return fetchSupportOk({ support: response })
          }),
          catchError((error) =>
            fetchSupportError({ error: error.xhr.response.detail }),
          ),
          takeUntil(action$.pipe(ofType(fetchSupportCancel.type))),
        )
      },
    ),
  )

export default combineEpics(
  fetchNavAmountEpic,
  fetchNavZonesEpic,
  fetchMotorboatsEpic,
  fetchSupportsEpic,
  fetchMotorboatEpic,
  fetchSupportEpic,
)
