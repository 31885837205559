import * as R from "ramda"

import { Polyline, useMap } from "react-leaflet"
import { getLatLng, getNavColor } from "../../../../services/NavService"

import EventMarker from "./EventMarker"
import React from "react"
import { useMapTarget } from "../../../../services/GeoService"

const MapNav = ({ nav, navIndex, navCount, alert }) => {
  const mapTarget = useMapTarget()
  const geolocEvents = R.compose(
    R.when(() => !nav.selected, R.takeLast(1)),
    R.filter(R.has("lat")),
  )(nav.events)

  const map = useMap()
  if (Number.isInteger(mapTarget) && mapTarget === nav.id) {
    map.setView(R.last(getLatLng(geolocEvents)), map.getZoom(), {
      animate: true,
    })
  }
  const allPos = R.map(getLatLng, geolocEvents)

  const color = getNavColor(navIndex, navCount, alert)

  return geolocEvents.length > 0 ? (
    <>
      {geolocEvents.map((event, index, events) => (
        <EventMarker
          key={`${nav.id}/${event.timestamp}`}
          nav={nav}
          event={event}
          color={color}
          last={events.length === index + 1}
        />
      ))}
      <Polyline
        pathOptions={{ color, weight: 2, dashArray: "4,12" }}
        positions={allPos}
      />
    </>
  ) : null
}

export default MapNav
