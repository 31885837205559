import { createTheme } from "@mui/material/styles"

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0e4194",
    },
    secondary: {
      main: "#e30613",
    },
    neutral: {
      main: "#9e9e9e",
      contrastText: "#fff",
    },
  },
})

export default theme
