import * as R from "ramda"

import React from "react"
import { useDispatch } from "react-redux"

import { Button } from "@mui/material"
import { PlayCircleFilled } from "@mui/icons-material"

import {
  useViewerBaseUser,
  useViewerLastNav,
} from "../../../../services/UserService"

import { getCurrentPos } from "../../../../state/modules/geo"
import { setNavInModal } from "../../../../state/modules/navs"

import { getInitialValues } from "../../../../services/NavService"
import Map from "../../../components/Map"
import Geoloc from "./Geoloc"
import MapOpts from "./MapOpts"

const styleTopButton = {
  position: "fixed",
  margin: "auto",
  width: "100%",
  left: 0,
  right: 0,
  bottom: "24px",
  textAlign: "center",
  zIndex: 1200,
}

const Idle = () => {
  const dispatch = useDispatch()

  const lastNav = useViewerLastNav()
  const nav = getInitialValues(lastNav)

  const viewerBaseUser = useViewerBaseUser()
  const geoloc_status = R.path(["user", "geoloc_status"], viewerBaseUser)

  const openNavModal = async () => {
    dispatch(getCurrentPos())
    dispatch(setNavInModal({ ...nav, kind: "now" }))
  }

  if (["UNDEFINED", "ERROR"].includes(geoloc_status)) {
    return <Geoloc viewerBaseUser={viewerBaseUser} />
  }

  return (
    <>
      <div style={styleTopButton}>
        <Button
          variant="contained"
          startIcon={<PlayCircleFilled />}
          onClick={openNavModal}
          color="primary"
          data-cy="button-nav-now"
        >
          Démarrage rapide
        </Button>
      </div>
      {/* {position.error && (
        <div className={classes.paper}>
          <Alert color="warning">
            <AlertTitle>
              La géolocalisation n'est pas activée pour votre navigateur.
            </AlertTitle>
            {position.error}
          </Alert>
        </div>
      )} */}
      {/* {geoLocStatus.status === "loading" ? (
        <p>Test de la geolocalisation</p>
      ) : geoLocStatus.status === "error" ? (
      ) : null} */}
      <Map />
      <MapOpts />
    </>
  )
}

export default Idle
