import { Box, Typography } from "@mui/material"
import React, { useEffect, useRef } from "react"

import EventItem from "./EventItem"
import { sxInNav } from "./styles"

const Events = ({ events = [] }) => {
  const eventsEndRef = useRef(null)

  const scrollToBottom = () => {
    eventsEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(scrollToBottom, [events])

  return (
    <Box>
      {events.map((event) => (
        <EventItem key={event.timestamp} event={event} />
      ))}
      <div ref={eventsEndRef} />
    </Box>
  )
}

export default Events
