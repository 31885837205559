import React, { useEffect } from "react"
import * as R from "ramda"
import { useDispatch, useSelector } from "react-redux"

import { getTokens } from "../../services/AuthService"
import { refreshToken } from "../../state/modules/auth"

import Anonymous from "./Anonymous"
import Authenticated from "./Authenticated"
import { LoadScreen } from "../components"

const App = () => {
  const dispatch = useDispatch()
  const tokenCheck = useSelector(R.path(["auth", "tokenCheck"]))
  const refresh = useSelector(R.path(["auth", "refresh"]))
  const tokens = getTokens()

  useEffect(() => {
    dispatch(refreshToken(tokens))
  }, [dispatch])

  // if (refresh && tokens) return <div>Authenticated side</div>
  if (refresh && tokens) return <Authenticated />

  if (tokenCheck) {
    return <LoadScreen text="Vérification de votre identité" />
  }

  return <Anonymous />
}
export default App
