import React, { useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { Done } from "@mui/icons-material"

import { useDispatch } from "react-redux"

import PhoneIcon from "@mui/icons-material/Phone"
import MedicalServicesIcon from "@mui/icons-material/MedicalServices"

import { handleSendEvent } from "../../../../services/NavService"
import { sxNav } from "./styles"
import { Propeller, Sail } from "../../../components/icons"
import { LoadScreen } from "../../../components"

const Alert = ({ nav }) => {
  const [loading, setLoading] = useState(false)
  const [alertKind, setAlertKind] = useState()

  const dispatch = useDispatch()

  const sendEvent = async (kind, desc = "") =>
    handleSendEvent({
      kind,
      desc,
      nav: nav,
      loadingAction: setLoading,
      closeAction: resetAlert,
      dispatch,
    })

  const resetAlert = () => {
    setAlertKind()
  }

  return (
    <Box>
      {loading ? (
        <LoadScreen>Acquisition GPS et envoi au serveur...</LoadScreen>
      ) : (
        <>
          {alertKind ? (
            <>
              <Button
                sx={sxNav.bigButton}
                variant="contained"
                onClick={() =>
                  sendEvent(
                    "alert",
                    `Problème de type ${alertKind}, gravité faible.`,
                  )
                }
                disabled={loading}
                startIcon={<MedicalServicesIcon />}
                color={nav.alert_status ? "primary" : "secondary"}
                data-cy={`button-${alertKind}-warning`}
              >
                Je peux résoudre le problème ou le régler plus tard
              </Button>
              <Button
                sx={sxNav.bigButton}
                variant="contained"
                onClick={() =>
                  sendEvent(
                    "alert",
                    `Problème de type ${alertKind}, besoin d'assistance.`,
                  )
                }
                disabled={loading}
                startIcon={<MedicalServicesIcon />}
                color={nav.alert_status ? "primary" : "secondary"}
                data-cy={`button-${alertKind}-panpan`}
              >
                J'ai besoin d'une aide extérieure
              </Button>
              {alertKind === "humain" && (
                <Button
                  sx={sxNav.bigButton}
                  variant="contained"
                  onClick={() =>
                    sendEvent(
                      "alert",
                      `Problème de type ${alertKind}, urgence vitale !`,
                    )
                  }
                  disabled={loading}
                  startIcon={<MedicalServicesIcon />}
                  color={nav.alert_status ? "primary" : "secondary"}
                  data-cy={`button-${alertKind}-mayday`}
                >
                  Urgence vitale
                </Button>
              )}
              <Button sx={sxNav.button} variant="outlined" onClick={resetAlert}>
                Annuler
              </Button>
            </>
          ) : (
            <>
              {nav.alert_status ? (
                <Button
                  fullWidth
                  sx={sxNav.bigButton}
                  variant="contained"
                  onClick={() => sendEvent("solved")}
                  startIcon={<Done />}
                  color="primary"
                  data-cy="button-cancel-alert"
                >
                  Déclarer le problème résolu
                </Button>
              ) : (
                <>
                  {/* <Button
                    sx={sxNav.button}
                    fullWidth
                    variant="outlined"
                    // onClick={() => setAlertKind("humain")}
                    disabled={loading}
                    startIcon={<PhoneIcon />}
                    color={"primary"}
                    data-cy="button-send-alert-human"
                  >
                    Appeler un resp
                  </Button> */}
                  <Button
                    sx={sxNav.bigButton}
                    variant="contained"
                    onClick={() => setAlertKind("humain")}
                    disabled={loading}
                    startIcon={<MedicalServicesIcon />}
                    color={nav.alert_status ? "primary" : "secondary"}
                    data-cy="button-send-alert-human"
                  >
                    Problème humain
                  </Button>
                  <Button
                    sx={sxNav.bigButton}
                    variant="contained"
                    onClick={() => setAlertKind("support")}
                    disabled={loading}
                    startIcon={<Sail />}
                    color={nav.alert_status ? "primary" : "secondary"}
                    data-cy="button-send-alert-support"
                  >
                    Problème sur un support
                  </Button>
                  <Button
                    sx={sxNav.bigButton}
                    variant="contained"
                    onClick={() => setAlertKind("semi-rigide")}
                    disabled={loading}
                    startIcon={<Propeller />}
                    color={nav.alert_status ? "primary" : "secondary"}
                    data-cy="button-send-alert-motorboat"
                  >
                    Problème de semi-rigide
                  </Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}
export default Alert
