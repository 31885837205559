import * as R from "ramda"
import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { formatISO } from "date-fns"

import { useDateInfos } from "../../../../services/ReportingService"
import { fetchMotorboats } from "../../../../state/modules/reporting"
import MotorboatDataGrid from "../components/MotorboatGrid"
import { HeaderWithIcon } from "../../../components/HeaderWithIcon"
import { Propeller } from "../../../components/icons"

const Motorboats = () => {
  const dispatch = useDispatch()

  const fetching = useSelector(R.path(["reporting", "motorboats", "fetching"]))
  const motorboats = useSelector(R.path(["reporting", "motorboats", "list"]))

  const { periodStart, periodEnd } = useDateInfos()

  useEffect(() => {
    dispatch(
      fetchMotorboats({
        start: formatISO(periodStart),
        end: formatISO(periodEnd),
      }),
    )
  }, [dispatch, periodStart, periodEnd])

  return (
    <>
      <HeaderWithIcon
        title="Les semi-rigides de la base"
        icon={<Propeller />}
      />

      <MotorboatDataGrid
        motorboats={motorboats}
        containerProps={{ sx: { height: "80%", width: "100%" } }}
        dataGridProps={{ pageSize: 20, rowsPerPageOptions: [20] }}
      />
    </>
  )
}

export default Motorboats
