import { Alert } from "@mui/material"
import { Container } from "@mui/material"
import React from "react"

export const FullScreen = ({ text, severity = "error" }) => {
  return (
    <Container maxWidth="xs">
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Alert severity={severity} variant="filled">
          {text}
        </Alert>
      </div>
    </Container>
  )
}
