import { createSlice } from "@reduxjs/toolkit"

const basesSlice = createSlice({
  name: "bases",
  initialState: {},
  reducers: {
    setBase: (state, { payload }) => ({
      ...state,
      [payload.id]: payload,
    }),
  },
})

export const { setBase } = basesSlice.actions

export default basesSlice.reducer
