import * as R from "ramda"

import { Field, FieldArray } from "formik"
import { Paper, Stack, Typography } from "@mui/material"

import React from "react"
import { TextField } from "formik-mui"

const NavMotorboatDebriefs = ({ navmotorboats }) => (
  <FieldArray name="navmotorboats">
    {(arrayHelpers) => (
      <Paper
        variant="outlined"
        sx={{
          p: (theme) => theme.spacing(1),
        }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          Les semi-rigides
        </Typography>
        <Stack spacing={2}>
          {navmotorboats.map((ns, index) => {
            const nsPath = `navmotorboats.${index}`
            return (
              <Field
                key={nsPath}
                component={TextField}
                name={`${nsPath}.debrief`}
                type="text"
                label={ns.motorboat.name}
                fullWidth
                multiline
                rows={4}
                data-cy={`input-${nsPath}.debrief`}
              />
            )
          })}
        </Stack>
      </Paper>
    )}
  </FieldArray>
)

export default NavMotorboatDebriefs
