import { Propeller } from "../../icons/Propeller"
import { SvgIcon } from "@mui/material"
// import React from "react"
import coachGraySVG from "./svg/coach_gray.svg"
import coachGreenSVG from "./svg/coach_green.svg"
import coachRedSVG from "./svg/coach_red.svg"
import { divIcon } from "leaflet"
import meGraySVG from "./svg/me_gray.svg"
import meGreenSVG from "./svg/me_green.svg"
import meRedSVG from "./svg/me_red.svg"
import { renderToString } from "react-dom/server"

const MapIcon = ({ kind, color, iconSize = [24, 24] }) => {
  const getIconUrl = () => {
    if (kind === "coach" && color === "green") return coachGreenSVG
    if (kind === "coach" && color === "red") return coachRedSVG
    if (kind === "coach" && color === "gray") return coachGraySVG
    if (kind === "me" && color === "green") return meGreenSVG
    if (kind === "me" && color === "red") return meRedSVG
    if (kind === "me" && color === "gray") return meGraySVG
  }
  const iconUrl = getIconUrl()

  return new divIcon({
    html: renderToString(<Propeller />),
    className: "dummy",
    iconSize,
  })
}

export default MapIcon
