import { Card, CardActions, CardContent, Typography } from "@mui/material"
import {
  getMotorboatList,
  getSupportList,
  navTimeInfos,
  useNav,
} from "../../../../../services/NavService"

import Block from "./Block"
import DebriefModule from "./DebriefModule"
import NavActions from "./NavActions"
import React from "react"
import StatusChip from "./StatusChip"
import ZoneItem from "./ZoneItem"
import { sxNavDetail } from "../styles"
import { useNavOwner } from "../../../../../services/BaseService"
import { useViewerBaseUser } from "../../../../../services/UserService"
import { NavInfos } from "../../components/NavInfos"

const MainNavDetail = ({ match }) => {
  const nav = useNav(match.params.id)

  const navOwner = useNavOwner(nav)
  const viewerBaseUser = useViewerBaseUser()

  const viewerIsOwner = navOwner.id === viewerBaseUser.user.id
  const viewerIsRTQ = viewerBaseUser.role === "rtq"

  return (
    <Card sx={sxNavDetail.paper} elevation={0} variant="outlined">
      <CardContent sx={sxNavDetail.card}>
        <NavInfos nav={nav} />
        <StatusChip status={nav.status} alert_status={nav.alert_status} />
        <Block
          title="supports et stagiaires"
          body={getSupportList(nav.navsupports, { noAmount: true })}
        />
        <Block
          title="semi-rigides et accompagnants"
          body={getMotorboatList(nav.navmotorboats)}
        />
        <Block title="zones">
          {nav.zone_ids ? (
            <>
              {nav.zone_ids.map((zoneId) => (
                <ZoneItem key={zoneId} zoneId={zoneId} />
              ))}
            </>
          ) : (
            <Typography>Zones non renseignées</Typography>
          )}
        </Block>
        <DebriefModule nav={nav} />
      </CardContent>
      <CardActions sx={sxNavDetail.cardActions}>
        <NavActions
          nav={nav}
          navOwner={navOwner}
          viewerBaseUser={viewerBaseUser}
          viewerIsOwner={viewerIsOwner}
          viewerIsRTQ={viewerIsRTQ}
        />
      </CardActions>
    </Card>
  )
}

export default MainNavDetail
