import React, { useEffect, useState } from "react"
import { getUserFromToken, storeTokens } from "../../../services/AuthService"
import { useHistory, useParams } from "react-router-dom"

import { HeaderWithIcon } from "../../components"
import { HelpOutlined } from "@mui/icons-material"
import { SignUpForm } from "../Anonymous/components"
import { Typography } from "@mui/material"
import { baseSignup } from "../../../legacyApi"
import { fetchBaseByShortName } from "../../../legacyApi"
import { handleError } from "../../components/formik"
import { loginOk } from "../../../state/modules/auth"
import { useDispatch } from "react-redux"

const BaseSignUp = () => {
  const [base, setBase] = useState()
  const [baseNotFound, setBaseNotFound] = useState(false)
  const { short_name } = useParams()

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const fetchBase = async () => {
      try {
        const base = await fetchBaseByShortName(short_name)
        setBase(base)
      } catch (e) {
        setBaseNotFound(true)
      }
    }
    fetchBase()
  }, [short_name])

  if (baseNotFound) {
    return (
      <>
        <HeaderWithIcon icon={<HelpOutlined />}>
          <Typography variant="h4" align="center">
            Nous ne trouvons pas la base avec le nom court{" "}
            <strong>{short_name}</strong>
          </Typography>
          <Typography variant="h5" align="center">
            Verifiez que l'url est bien correcte ou contactez la base pour plus
            d'informations.
          </Typography>
        </HeaderWithIcon>
      </>
    )
  }

  if (!base) {
    return <p>Loading</p>
  }

  const initialValues = {
    email: "",
    password: "",
    lastname: "",
    firstname: "",
    mobile: "",
    pro_card: "",
    photo: "",
    base_id: base.id,
  }

  const handleSubmit = async (
    values,
    { status, setStatus, setSubmitting, setErrors },
  ) => {
    try {
      const { data } = await baseSignup(values)
      setStatus({ ...status, errors: null })
      storeTokens(data)
      dispatch(
        loginOk({
          ...data,
          user: getUserFromToken(),
        }),
      )
      history.push("/")
    } catch (error) {
      if (error?.response?.data?.email) {
        setErrors(error.response.data)
      } else {
        handleError(setSubmitting, status, setStatus, error)
      }
    }
  }

  return (
    <SignUpForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      title={`Inscription pour la base ${base.name} invitation`}
    />
  )
}
export default BaseSignUp
