import * as R from "ramda"
import * as Yup from "yup"

import { pickAndRenameKeys } from "../../../../../services/MainService"
import { useSelector } from "react-redux"

export const useMotorboatOpts = (baseId) => {
  const { id, active_base_id } = useSelector(R.path(["auth", "user"]))

  return R.compose(
    R.prepend({ value: "", label: "Pas de semi-rigide" }),
    R.map(pickAndRenameKeys({ id: "value", str_name: "label" })),
    R.filter(
      R.either(R.propEq("user_id", id), R.propEq("base_id", active_base_id)),
    ),
  )(useSelector(R.pathOr([], ["warehouse", "motorboats", baseId])))
}

export const useSupportOpts = () =>
  R.compose(
    // R.tap((c) => console.log("c", c)),
    R.map(
      pickAndRenameKeys({
        id: "value",
        composed_str: "label",
        carrying_capacity: "multiplier",
      }),
    ),
    // R.tap((b) => console.log("b", b)),
    R.flatten,
    R.map(R.prop("supports")),
  )(useSelector(R.pathOr([], ["warehouse", "categories"])))

export const useZoneOpts = (baseId) =>
  R.map(
    pickAndRenameKeys({ id: "value", name: "label" }),
    useSelector(R.pathOr([], ["bases", baseId, "zones"])),
  )

const stringRequired = Yup.string().required("Requis.")
const positiveIntRequired = Yup.number().integer().min(0).required("Requis")
const boolRequired = Yup.bool().required("Requis.")
const positiveFloat = Yup.number().positive()

const ZonesSchema = Yup.array()
  .min(1, "Sélectionnez au moins une zone")
  .of(positiveIntRequired)

const NavSupportsSchema = Yup.array().of(
  Yup.object().shape({
    support: Yup.object().shape({
      id: positiveIntRequired,
      name: stringRequired,
    }),
    count: positiveIntRequired,
    trainees: positiveIntRequired,
    base_owned: boolRequired,
  }),
)

const NavMotorboatsSchema = Yup.array().of(
  Yup.object().shape({
    motorboat: Yup.object().shape({
      id: positiveIntRequired,
      name: stringRequired,
      identification: stringRequired,
      length: positiveFloat,
    }),
    additional_crews: positiveIntRequired,
    base_owned: boolRequired,
  }),
)

export const NavOngoingSchema = Yup.object().shape({
  planned_end: Yup.date().required("Requis."),
  zone_ids: ZonesSchema,
  navsupports: NavSupportsSchema,
  navmotorboats: NavMotorboatsSchema,
})

export const NavPlannedSchema = Yup.object().shape({
  planned_start: Yup.date()
    .nullable()
    .min(new Date(), "Choisissez une date dans le futur.")
    .required("Requis."),
  planned_end: Yup.date()
    .nullable()
    .when("planned_start", (planned_start, schema) => {
      if (planned_start) {
        const after_planned_start = new Date(planned_start.getTime() + 1)
        return schema.min(
          after_planned_start,
          "La date de fin doit être après la date de début.",
        )
      }
    })
    .required("Requis."),
  zone_ids: ZonesSchema,
  navsupports: NavSupportsSchema,
  navmotorboats: NavMotorboatsSchema,
})
