import { Button, Grid, Typography } from "@mui/material"
import React, { useState } from "react"

import { Frame } from "../../../components"
import RNRModal from "./RNRModal"
import ZoneItem from "./ZoneItem"
import { latLngAsDMS } from "../../../../services/GeoService"

const sxSection = { marginBottom: (theme) => theme.spacing(4) }

const sxFrame = {
  textAlign: "center",
  padding: (theme) => theme.spacing(1),
}

const Show = ({ base, viewerBaseUser }) => {
  const [showRNR, setShowRNR] = useState(false)
  const toggleRNR = () => setShowRNR(!showRNR)

  return (
    <div>
      <Typography color="primary" align="center" variant="subtitle1">
        La base
      </Typography>
      <Typography variant="h3" color="primary" align="center">
        {base.name}
      </Typography>
      <section sx={sxSection}>
        <Typography variant="h4">Description</Typography>
        <Typography variant="body1" paragraph>
          {base.description}
        </Typography>
      </section>
      <section sx={sxSection}>
        <Typography variant="h4">Règlement intérieur</Typography>
        <Button
          onClick={toggleRNR}
          color="primary"
          variant="outlined"
          fullWidth
        >
          Voir le règlement intérieur
        </Button>
      </section>
      <section sx={sxSection}>
        <Typography variant="h4">Canal VHF</Typography>
        <Frame>{base.emergency_vhf}</Frame>
      </section>
      <section sx={sxSection}>
        <Typography variant="h4">Téléphone d'urgence</Typography>
        <Frame>{base.emergency_phone}</Frame>
      </section>
      <section sx={sxSection}>
        <Typography variant="h4">Position sur la carte</Typography>
        <Frame>{latLngAsDMS(base.coords)}</Frame>
      </section>
      <section sx={sxSection}>
        <Typography variant="h4">Zones de navigation </Typography>
        <Grid container spacing={2}>
          {base.zones.map((zone) => (
            <Grid key={zone.id} item xs={6}>
              <ZoneItem zone={zone} />
            </Grid>
          ))}
        </Grid>
      </section>
      <RNRModal
        showRNR={showRNR}
        toggleRNR={toggleRNR}
        rnr={base.rules_n_regulations}
      />
    </div>
  )
}

export default Show
