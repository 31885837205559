import {} from "@mui/material"

import { Circle, Tooltip, useMap } from "react-leaflet"

import React from "react"
import { Typography } from "@mui/material"
import Zone from "../Zone"
import { useMapTarget } from "../../../../services/GeoService"

const fillBlueOptions = { fillColor: "blue" }
const MapBase = ({ base }) => {
  const mapTarget = useMapTarget()
  const map = useMap()
  if (mapTarget === "base") {
    map.setView(base.coords, map.getZoom(), {
      animate: true,
    })
  }

  return base ? (
    <>
      {base.coords && (
        <Circle center={base.coords} pathOptions={fillBlueOptions} radius={400}>
          <Tooltip direction="top">
            <Typography variant="body2">{base.name}</Typography>
          </Tooltip>
        </Circle>
      )}
      {base.zones?.map((zone) => (
        <Zone key={zone.id} zone={zone} />
      ))}
    </>
  ) : null
}

export default MapBase
