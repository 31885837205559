import React from "react"

import { Box, Button, Grid, Container, Avatar, Typography } from "@mui/material"
import { Home, VisibilityOff } from "@mui/icons-material"
import { styleFullHeight } from "../styles"

import { useHistory } from "react-router"

const NotFound = () => {
  const history = useHistory()

  return (
    <Container maxWidth="xs">
      <Box sx={styleFullHeight.paper}>
        <Avatar sx={styleFullHeight.avatar}>
          <VisibilityOff />
        </Avatar>
        <Typography component="h1" variant="h5">
          Cette page n'existe pas
        </Typography>
        <Typography component="h3" variant="h6">
          ou elle a été déplacée.
        </Typography>
        <Grid>
          <Button
            variant="contained"
            onClick={() => history.push("/")}
            startIcon={<Home size="large" />}
            color="primary"
            style={{ height: "5em", width: "320px", borderRadius: "4px" }}
          >
            Retourner à l'accueil
          </Button>
        </Grid>
      </Box>
    </Container>
  )
}

export default NotFound
