import { API_HTTP } from "../config"
import axios from "axios"

export const fetchBaseByShortName = async (short_name) => {
  const res = await axios.get(`${API_HTTP}/bases/shortname/${short_name}/`)
  return res.data
}

export const updateBasePromise = (values) =>
  axios.patch(`${API_HTTP}/bases/${values.id}/`, values)

export const updateBaseUserPromise = (values) =>
  axios.patch(
    `${API_HTTP}/bases/${values.base_id}/baseusers/${values.id}/`,
    values,
  )

export const fetchBaseUsers = async (baseId) =>
  axios.get(`${API_HTTP}/bases/${baseId}/baseusers/`)
