import React from "react"
import { Typography } from "@mui/material"

export const PageTitle = ({ children, ...props }) => (
  <Typography
    align="center"
    color="primary"
    variant="h5"
    gutterBottom
    {...props}
  >
    {children}
  </Typography>
)
