import * as R from "ramda"

import { Add, Cancel } from "@mui/icons-material"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { Field, FieldArray } from "formik"

import MotorboatSearch from "./MotorboatSearch"
import { PositiveIntegerField } from "../../../../../../components/formik"
import React from "react"

const newNavMotorboat = {
  motorboat: null,
  additional_crews: 0,
  base_owned: true,
}

const MotorboatForm = ({ navmotorboats }) => (
  <FieldArray name="navmotorboats">
    {(arrayHelpers) => (
      <Paper
        variant="outlined"
        sx={{
          p: (theme) => theme.spacing(1),
        }}
      >
        <Stack spacing={2}>
          {navmotorboats.map((nm, index) => {
            const nmPath = `navmotorboats.${index}`
            return (
              <Stack spacing={1} key={index}>
                <Typography variant="h6" align="center">
                  {nm.motorboat?.fullname
                    ? nm.motorboat.fullname
                    : "Ajouter un semi-rigide"}
                  <IconButton
                    aria-label="delete"
                    color="neutral"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <Cancel />
                  </IconButton>
                </Typography>
                <Field
                  component={MotorboatSearch}
                  type="text"
                  name={`${nmPath}.motorboat`}
                  label="Sélectionner le semi-rigide"
                  arrayHelpers={arrayHelpers}
                  nmIndex={index}
                  nm={nm}
                  nmPath={nmPath}
                  dataCy={`autocomplete-${nmPath}`}
                />
                <PositiveIntegerField
                  name={`${nmPath}.additional_crews`}
                  label="Accompagnants"
                  fullWidth
                  data-cy={`${nmPath}.additional_crews`}
                />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox data-cy={`${nmPath}.base_owned`} />}
                    label="Ce semi-rigide appartient à la base"
                    name={`${nmPath}.base_owned`}
                    checked={nm.base_owned}
                    onChange={({ target: { checked } }) =>
                      arrayHelpers.form.setFieldValue(
                        `${nmPath}.base_owned`,
                        checked,
                      )
                    }
                  />
                </FormGroup>
              </Stack>
            )
          })}
        </Stack>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            color="neutral"
            startIcon={<Add />}
            onClick={() => arrayHelpers.push(newNavMotorboat)}
            data-cy="button-add-motorboat"
          >
            {navmotorboats.length > 0
              ? "Ajouter un autre semi-rigide"
              : "Ajouter un semi-rigide"}
          </Button>
        </Box>
      </Paper>
    )}
  </FieldArray>
)

export default MotorboatForm
