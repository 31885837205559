export const card = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "spaceBetween",
  padding: (theme) => theme.spacing(2),
  width: "100%",
  height: "100vh",
  textAlign: "center",
  overflowY: "auto",
}
export const sxNavDetail = {
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: (theme) => theme.spacing(1),
    width: "100%",
    height: "100%",
  },
  card,
  alertCard: {
    ...card,
    borderBottom: (theme) => `1px solid ${theme.palette.secondary.main}`,
  },
  cardActions: {
    mt: (theme) => theme.spacing(3),
    borderTop: (theme) => `1px solid ${theme.palette.primary.main}`,
    width: "100%",
  },
  phoneButton: {
    marginTop: "8px",
    marginBottom: "20px",
    borderRadius: "4px",
  },
}

export const sxBlock = {
  border: (theme) => `1px solid ${theme.palette.primary.main}`,
  borderRadius: 1,
  p: (theme) => theme.spacing(0.5),
  mt: (theme) => theme.spacing(0.5),
  mb: (theme) => theme.spacing(1),
}
