import {
  Box,
  Button,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"

import React from "react"
import { styleFullHeight, sxSubmit } from "../../../../styles"

const GeoLocPrompt = ({ handleGeolocate, checking }) => (
  <Box sx={styleFullHeight}>
    <Container maxWidth="md">
      <Typography component="h5" variant="h4">
        Navisure a besoin de vous géolocaliser.
      </Typography>
      <Typography component="h5" variant="h5">
        Uniquement quand vous faites des actions de départ, d'alerte ou
        d'arrivée.
      </Typography>
      {checking && <LinearProgress />}
      <Button
        sx={sxSubmit}
        onClick={handleGeolocate}
        disabled={checking}
        variant="contained"
        color="primary"
        fullWidth
        data-cy="button-geolocate"
      >
        Tester la géolocalisation
      </Button>
    </Container>
  </Box>
)

export default GeoLocPrompt
