import {
  Box,
  Button,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"
import { styleFullHeight, sxSubmit } from "../../../../styles"

import GuideAndroid from "./GuideAndroid"
import GuideIOS from "./GuideIOS"
import React from "react"

const getMobileOS = () => {
  const ua = navigator.userAgent
  // console.log("ua", ua)
  if (/android/i.test(ua)) {
    return "Android"
  } else if (/iPad|iPhone|iPod/.test(ua)) {
    return "iOS"
  }
  return "Other"
}

const GeoLocError = ({ handleGeolocate, checking }) => {
  const mobile = getMobileOS()

  return (
    <Box sx={styleFullHeight}>
      <Container maxWidth="md">
        <Typography component="h5" variant="h4" color="secondary">
          {checking
            ? "Test de la géolocalisation"
            : "La géolocalisation n'a pas fonctionné."}
        </Typography>
        {mobile === "iOS" && <GuideIOS />}
        {mobile === "Android" && <GuideAndroid />}
        {checking && <LinearProgress />}
        <Button
          sx={sxSubmit}
          onClick={handleGeolocate}
          disabled={checking}
          variant="outlined"
          color="primary"
          fullWidth
        >
          Retester la géolocalisation
        </Button>
      </Container>
    </Box>
  )
}

export default GeoLocError
