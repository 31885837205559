import { Button, Typography } from "@mui/material"

import React from "react"

// import iosData from "./data/ios"
// import { makeStyles } from "@mui/material/styles"

// //   root: {
//     // background: "blue",
//     height: "100vh",
//     display: "grid",
//     gridTemplateRows: "1fr 10fr 1fr",
//   },
//   title: {
//     // background: "red",
//     padding: theme.spacing(2),
//   },
//   image: {
//     // background: "green",
//     overflow: "hidden",
//     textAlign: "center",
//   },
//   img: {
//     objectFit: "contain",
//     maxWidth: "100%",
//     maxHeight: "100%",
//   },
//   actions: {
//     display: "grid",
//     // background: "yellow",
//     justifyContent: "center",
//     alignItems: "center",
//   },
// }))

const GuideAndroid = () => {
  // const classes = useStyles()

  // const [showGuide, setShowGuide] = useState(false)
  // const [step, setStep] = useState(0)

  // const totalSetps = iosData.length - 1

  return (
    <>
      <br />
      <Typography variant="h6">
        Il semble que vous utilisiez un mobile Android. Suivez les instructions
        pour réactiver la géolocalisation.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        href="https://support.google.com/accounts/answer/3467281?hl=fr"
        target="_blank"
      >
        Instructions
      </Button>
      {/* <Dialog
        fullScreen
        open={showGuide}
        onClose={() => setShowGuide(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <div className={classes.root}>
          <div className={classes.title}>
            <Typography align="center" variant="h6" gutterBottom>
              Guide pour réactiver la géolocalisation sur iOS
            </Typography>
            <Typography variant="body2">{iosData[step].text}</Typography>
          </div>
          <div className={classes.image}>
            <img
              className={classes.img}
              src={iosData[step].src}
              alt={iosData[step].alt}
            />
          </div>
          <div className={classes.actions}>
            <div>
              {step !== 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setStep(step - 1)}
                >
                  Précédent
                </Button>
              )}{" "}
              {step + 1} / {totalSetps + 1}{" "}
              {step !== totalSetps && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setStep(step + 1)}
                >
                  Suivant
                </Button>
              )}
              {step === totalSetps && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowGuide(false)}
                >
                  Terminé
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog> */}
    </>
  )
}

export default GuideAndroid

// <DialogContent>
// <div style={{ maxWidth: "100%", maxHeight: "100%", margin:"auto" }}>
//
// </div>
// </DialogContent>
// <DialogActions>
// </DialogActions>
