import React from "react"
import { useDispatch } from "react-redux"
import { Button, ButtonGroup } from "@mui/material"
import { PersonPin, Home } from "@mui/icons-material"

import { centerOnMe } from "../../../../services/GeoService"
import { setMapTarget } from "../../../../state/modules/geo"

export const MapButtons = () => {
  const dispatch = useDispatch()
  const gotoBase = () => dispatch(setMapTarget("base"))

  return (
    <>
      <ButtonGroup size="large">
        <Button
          variant="contained"
          startIcon={<Home />}
          onClick={gotoBase}
          style={{ opacity: 0.8 }}
        >
          Base
        </Button>
        <Button
          variant="contained"
          startIcon={<PersonPin />}
          onClick={() => centerOnMe(dispatch)}
          // disabled={position.error}
          style={{ opacity: 0.8 }}
        >
          Ma position
        </Button>
      </ButtonGroup>
    </>
  )
}
