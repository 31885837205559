import * as R from "ramda"

import StartEnd from "./StartEnd"
import Activities from "./Activities"
import SecurityBoats from "./SecurityBoats"
import Zones from "./Zones"
import { format, formatDistanceToNow } from "date-fns"

const formatActivities = (navsupports) => {
  if (R.isEmpty(navsupports)) {
    return <span>Pas d'activité</span>
  }
  return (
    `Activité${navsupports.length > 1 ? "s" : ""}: ` +
    R.compose(R.join(", "), R.map(R.path(["support", "name"])))(navsupports)
  )
}

const formatMotorboats = (navmotorboats) => {
  if (R.isEmpty(navmotorboats)) {
    return <span>Pas d'embarcation de sécurité</span>
  }
  return (
    `Embarcation${navmotorboats.length > 1 ? "s" : ""} sécu: ` +
    R.compose(
      R.join(", "),
      R.map(R.path(["motorboat", "fullname"])),
    )(navmotorboats)
  )
}

export const getSteps = (kindIsNow) => [
  {
    id: 1,
    initialTitle: kindIsNow ? "Créneau Horaire" : "Date et créneau horaire",
    completedTitle: ({ planned_end }) => (
      <span>
        Fin prévue à {format(planned_end, "HH:mm")} (
        {formatDistanceToNow(planned_end)})
      </span>
    ),
    helperText: kindIsNow
      ? {
          // title: "Heure de fin de votre nav",
          body: (
            <ul>
              <li>Vous pouvez décaler l'heure de fin en cours de session.</li>
              <li>
                Si vous n'avez pas pas clôturé votre session 20 minutes après
                l'heure de fin, un message d'alerte sera envoyé au(x) RTQ de la
                base.
              </li>
            </ul>
          ),
        }
      : {
          title:
            "Les navs planifiées démarrent automatiquement à la date et l'heure indiquées.",
          body: "Vous recevrez un SMS avant le démarrage automatique.",
        },

    fields: ["planned_start", "planned_end"],
    content: (values) => <StartEnd values={values} kindIsNow={kindIsNow} />,
    disabled: R.compose(R.isNil, R.prop("planned_end")),
  },
  {
    id: 2,
    initialTitle: "Activités pratiquées",
    completedTitle: ({ navsupports }) => (
      <span>
        {console.log(navsupports)} {formatActivities(navsupports)}
      </span>
    ),
    helperText: {
      title: "Vous pouvez choisir plusieurs activités.",
      body: " Si vous ne choisissez aucune activité, il faudra au moins choisir une embarcation de sécurité.",
    },
    fields: ["navsupports"],
    content: (values) => <Activities values={values} />,
    disabled: R.always(false),
  },
  {
    id: 3,
    initialTitle: "Embarcations de sécurité",
    completedTitle: ({ navmotorboats }) => (
      <span>
        {console.log(navmotorboats)} {formatMotorboats(navmotorboats)}
      </span>
    ),
    helperText: {
      title: "Vous pouvez choisir plusieurs embarcations de sécurité.",
      body: "Si vous ne choisissez aucune embarcation, il faudra au moins choisir une activité.",
    },
    fields: ["navmotorboats"],
    content: (values) => <SecurityBoats values={values} />,
    disabled: R.always(false),
  },
  {
    id: 4,
    initialTitle: "Zones de pratique",
    completedTitle: ({ zone_ids }) => <span>Toto</span>,
    helperText: {
      title: "Choisissez au moins une zone.",
    },
    fields: ["zone_ids"],
    content: (navForm) => <Zones navForm={navForm} />,
    disabled: R.compose(R.isEmpty, R.prop("zone_ids")),
  },
]
