import * as R from "ramda"
import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { formatISO } from "date-fns"

import { useDateInfos } from "../../../../services/ReportingService"
import { fetchSupports } from "../../../../state/modules/reporting"
import { MasonryBlock } from "../components"
import SupportDataGrid from "../components/SupportGrid"

const Supports = () => {
  const dispatch = useDispatch()

  const fetching = useSelector(R.path(["reporting", "supports", "fetching"]))
  const supports = useSelector(R.path(["reporting", "supports", "list"]))

  const { periodStart, periodEnd } = useDateInfos()
  const start = formatISO(periodStart)
  const end = formatISO(periodEnd)

  useEffect(() => {
    dispatch(
      fetchSupports({
        start,
        end,
      }),
    )
  }, [dispatch, start, end])

  return (
    <MasonryBlock title={"Supports les plus utilisés"} fetching={fetching}>
      <SupportDataGrid supports={supports} />
    </MasonryBlock>
  )
}

export default Supports
