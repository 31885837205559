import * as R from "ramda"
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

import {
  format,
  formatDistanceToNow,
  fromUnixTime,
  isToday,
  parseISO,
} from "date-fns"
import { fr } from "date-fns/locale"

import { fetchBase, getBaseUsers, useActiveBaseId } from "./BaseService"

import { fetchNavs } from "../state/modules/navs"

// import { getCategories, getMotorboats, getSupports } from "./WarehouseService"

export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce(
    (acc, key) => R.assoc(keysMap[key] || key, obj[key], acc),
    {},
    R.keys(obj),
  ),
)

export const pickAndRenameKeys = R.curry((keysMap, obj) =>
  R.compose(R.pick(Object.values(keysMap)), renameKeys(keysMap))(obj),
)

export const formatTime = (time) => {
  const parseTime = Number(time) ? fromUnixTime(time / 1000) : parseISO(time)
  const formatedTime = format(parseTime, "k:mm")
  return formatedTime
}

export const timeToNow = (datetime) => {
  return formatDistanceToNow(parseISO(datetime), {
    addSuffix: true,
    locale: fr,
  })
}

export const formatDuration = (startISO, endISO) => {
  const start = parseISO(startISO)
  const end = endISO && parseISO(endISO)
  let output = format(start, "k:mm")

  if (end) {
    output = `${output}-${format(end, "k:mm")}`
  }

  if (!isToday(start)) {
    output = `${format(start, "d/M")}, ${output}`
  }
  return `AJD ${output}`
}

export const formatStartTime = (startISO) => {
  const start = parseISO(startISO)
  let output = format(start, "k:mm")
  if (!isToday(start)) {
    output = `${format(start, "d/M")}, ${output}`
  }
  return output
}
export const formatEndTime = (endISO) => {
  const end = parseISO(endISO)
  let output = format(end, "k:mm")
  if (!isToday(end)) {
    output = `${format(end, "d/M")}, ${output}`
  }
  return output
}

export const useFetchBaseAndUsers = () => {
  const dispatch = useDispatch()
  const baseId = useActiveBaseId()

  useEffect(() => {
    if (baseId) {
      fetchBase(dispatch, baseId)
      getBaseUsers(dispatch, baseId)
      // getCategories(dispatch)
      // getSupports(dispatch)
      // getMotorboats(dispatch, baseId)
      dispatch(fetchNavs())
    }
  }, [dispatch, baseId])
}

export const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join("").toLowerCase()

export const accord = (word, list, plural = null) => {
  if (list.length > 1) {
    if (plural) return plural
    return word + "s"
  }
  return word
}

export const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value && JSON.parse(JSON.stringify(value))
  }, [value])

  return ref.current
}
