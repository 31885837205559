import React from "react"
import { Alert, AlertTitle } from '@mui/material';

export const TopLevelErrors = ({ status }) => {
  if (status && status.errors && status.errors.length > 0) {
    return (
      <React.Fragment>
        {status.errors.map((error, index) => (
          <Alert key={index} severity="warning">
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        ))}
      </React.Fragment>
    )
  }
  return null
}
