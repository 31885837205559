import { Box, Button, Dialog, Typography } from "@mui/material"
import React, { useState } from "react"

import iosData from "./data/ios"

const sx = {
  root: {
    // background: "blue",
    height: "100vh",
    display: "grid",
    gridTemplateRows: "1fr 10fr 1fr",
  },
  title: {
    // background: "red",
    padding: (theme) => theme.spacing(2),
  },
  image: {
    // background: "green",
    overflow: "hidden",
    textAlign: "center",
  },
  img: {
    "object-fit": "contain",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  actions: {
    display: "grid",
    // background: "yellow",
    justifyContent: "center",
    alignItems: "center",
  },
}

const GuideIOS = () => {
  const [showGuide, setShowGuide] = useState(false)
  const [step, setStep] = useState(0)

  const totalSetps = iosData.length - 1

  return (
    <>
      <br />
      <Typography variant="h6">
        Il semble que vous utilisiez un mobile Apple. Suivez les instructions
        pour réactiver la géolocalisation sur iPhone.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setShowGuide(true)}
      >
        Instructions
      </Button>
      <Dialog
        fullScreen
        open={showGuide}
        onClose={() => setShowGuide(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box sx={sx.root}>
          <Box sx={sx.title}>
            <Typography align="center" variant="h6" gutterBottom>
              Guide pour réactiver la géolocalisation sur iOS
            </Typography>
            <Typography variant="body2">{iosData[step].text}</Typography>
          </Box>
          <Box sx={sx.image}>
            <img
              style={sx.img}
              src={iosData[step].src}
              alt={iosData[step].alt}
            />
          </Box>
          <Box sx={sx.actions}>
            <Box>
              {step !== 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setStep(step - 1)}
                >
                  Précédent
                </Button>
              )}{" "}
              {step + 1} / {totalSetps + 1}{" "}
              {step !== totalSetps && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setStep(step + 1)}
                >
                  Suivant
                </Button>
              )}
              {step === totalSetps && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowGuide(false)}
                >
                  Terminé
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default GuideIOS

// <DialogContent>
// <div style={{ maxWidth: "100%", maxHeight: "100%", margin:"auto" }}>
//
// </div>
// </DialogContent>
// <DialogActions>
// </DialogActions>
