import * as Yup from "yup"

import { Field, Form, Formik } from "formik"

import { Box, Button, Dialog } from "@mui/material"

import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import React from "react"
import TextField from "@mui/material/TextField"
import { sxForm } from "../../../styles/sx"

const ProfilYupSchema = Yup.object().shape({
  user: Yup.object().shape({
    password: Yup.string()
      .min(6, "Votre mot de passe doit faire plus de 6 caractères.")
      .max(64, "Votre mot de passe est trop long (>64).")
      .required("Requis"),
  }),
})

const PasswordModal = ({ openPassword, setOpenPassword }) => {
  return (
    <div>
      <Formik
        // initialValues={}
        validationSchema={ProfilYupSchema}
        // onSubmit={handleSubmit}
      >
        {({
          submitForm,
          isSubmitting,
          isValid,
          status,
          values,
          setFieldValue,
          errors,
        }) => {
          return (
            <Box sx={sxForm}>
              <Form>
                <Dialog
                  open={openPassword}
                  onClose={() => setOpenPassword(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Changement de mot de passe
                  </DialogTitle>
                  <DialogContent>
                    <Field
                      component={TextField}
                      name=""
                      type="password"
                      label="Mot de passe actuel"
                      fullWidth
                      required
                    />
                    <br />
                    <Field
                      component={TextField}
                      name=""
                      type="password"
                      label="Nouveau mot de passe"
                      fullWidth
                      required
                    />
                    <br />
                    <Field
                      component={TextField}
                      name=""
                      type="password"
                      label="Confirmer le nouveau mot de passe"
                      fullWidth
                      required
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => setOpenPassword(false)}
                    >
                      Annuler
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || isValid}
                      onClick={() => setOpenPassword(false)}
                    >
                      Enregistrer
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            </Box>
          )
        }}
      </Formik>
    </div>
  )
}

export default PasswordModal
