import * as R from "ramda"

import { Box, Divider, Typography } from "@mui/material"
import React, { useState } from "react"
import { endOfYesterday, format } from "date-fns"

import AlertBlock from "./AlertBlock"
import Calendar from "./Calendar"
import { Drawer } from "@mui/material"
import HeaderButtons from "./HeaderButtons"
import NavBlock from "./NavBlock"
import PastNavsAlert from "./PastNavsAlert"
import { sxNavList } from "./styles"
import { useNavs } from "../../../../services/NavService"

const NavList = ({ date, setDate, width, openMap }) => {
  const [showCal, setShowCal] = useState(false)
  const toggleCal = () => setShowCal(!showCal)

  const onGoingPastNavs = useNavs({
    groupBy: true,
    end: endOfYesterday(),
  })?.ONGOING

  const { ONGOING, FINISHED, PLANNED, DEBRIEFED } = useNavs({
    groupBy: true,
    date: date,
  })

  const { viewerOngoingNavsAlert, viewerOngoingNavsNormal } = ONGOING
    ? R.groupBy(
        (nav) =>
          nav.alert_status
            ? "viewerOngoingNavsAlert"
            : "viewerOngoingNavsNormal",
        ONGOING,
      )
    : { viewerOngoingNavsNormal: [], viewerOngoingNavsAlert: [] }

  const variant = width < 501 ? "h5" : "h4"

  return (
    <>
      <Box sx={sxNavList.grid}>
        <Box>
          <Typography
            sx={sxNavList.title}
            align={width < 501 ? "right" : "center"}
            color="primary"
            variant={variant}
            gutterBottom
          >
            Navs du {format(date, "d/M")}
            <HeaderButtons
              date={date}
              setDate={setDate}
              openMap={openMap}
              width={width}
              toggleCal={toggleCal}
            />
          </Typography>
        </Box>
        {viewerOngoingNavsAlert && (
          <>
            <Box
              sx={sxNavList.paper}
              style={{
                overflowY: "auto",
              }}
            >
              <AlertBlock navs={viewerOngoingNavsAlert} />
            </Box>
            <Divider />
          </>
        )}
        <Box
          sx={sxNavList.paper}
          style={{
            overflowY: "auto",
          }}
        >
          <PastNavsAlert onGoingPastNavs={onGoingPastNavs} setDate={setDate} />
          <NavBlock navs={viewerOngoingNavsNormal} status="ONGOING" />
          <NavBlock navs={PLANNED} status="PLANNED" />
          <NavBlock navs={FINISHED} status="FINISHED" />
          <NavBlock navs={DEBRIEFED} status="DEBRIEFED" />
        </Box>
      </Box>
      <Drawer anchor="right" open={showCal} onClose={toggleCal}>
        <Calendar date={date} setDate={setDate} toggleCal={toggleCal} />
      </Drawer>
    </>
  )
}

export default NavList
