import "yup-phone"

import * as Yup from "yup"

// import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material/"
import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { sxForm, sxSubmit } from "../../../styles"

import { AuthShortcuts } from "."
import { HeaderWithIcon } from "../../../components"
import MuiPhoneNumber from "mui-phone-number"
import { PersonAdd } from "@mui/icons-material"
import React from "react"
import { TextField } from "formik-mui"
import { TopLevelErrors } from "../../../components/formik"

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email().required("Requis"),
  password: Yup.string()
    .min(6, "Votre mot de passe doit faire plus de 6 caractères.")
    .max(64, "Votre mot de passe est trop long (>64).")
    .required("Requis"),
  lastname: Yup.string().required("Requis"),
  firstname: Yup.string().required("Requis"),
  mobile: Yup.string().phone().required(),
  pro_card: Yup.string().required("Requis"),
  // motorboat_name: Yup.string(),
  // motorboat_id: Yup.string(),
})

const Title = ({ children }) => (
  <Typography
    align="center"
    variant="h6"
    color="primary"
    sx={{
      mt: (theme) => theme.spacing(2),
      mb: (theme) => theme.spacing(1),
    }}
  >
    {children}
  </Typography>
)

export const SignUpForm = ({ initialValues, handleSubmit, title }) => {
  // const [showBoat, setShowBoat] = useState(false)
  // const toggleBoat = () => setShowBoat(!showBoat)
  return (
    <React.Fragment>
      <HeaderWithIcon title={title} icon={<PersonAdd />} />

      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, status, setFieldValue }) => {
          return (
            <Box sx={sxForm}>
              <Form>
                <TopLevelErrors status={status} />
                <Title>Identifiants de connexion</Title>
                <Stack spacing={1}>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                    required
                    autoComplete="off"
                    data-cy="input-email"
                  />
                  <Field
                    component={TextField}
                    type="password"
                    label="Password"
                    name="password"
                    fullWidth
                    required
                    autoComplete="off"
                    data-cy="input-password"
                  />
                </Stack>
                <Title>Profil</Title>
                <Stack spacing={1}>
                  <Field
                    component={TextField}
                    type="text"
                    label="Prénom"
                    name="firstname"
                    fullWidth
                    required
                    data-cy="input-firstname"
                  />
                  <Field
                    component={TextField}
                    type="text"
                    label="Nom"
                    name="lastname"
                    fullWidth
                    required
                    data-cy="input-lastname"
                  />
                  <Field name="mobile">
                    {({ field, meta }) => (
                      <MuiPhoneNumber
                        type="phone"
                        label="Téléphone mobile"
                        fullWidth
                        required
                        defaultCountry={"fr"}
                        data-cy="input-mobile"
                        error={meta.touched && meta.error && true}
                        helperText={meta.touched && meta.error}
                        {...field}
                        onChange={(value) => setFieldValue("mobile", value)}
                      />
                    )}
                  </Field>
                  <Field
                    component={TextField}
                    name="pro_card"
                    type="text"
                    label="Numéro de carte professionnelle"
                    fullWidth
                    required
                    data-cy="input-pro_card"
                  />
                </Stack>
                {/* <Title>Ajouter un semi-rigide ?</Title>
                <Button
                  onClick={toggleBoat}
                  startIcon={showBoat ? <ArrowDropUp /> : <ArrowDropDown />}
                  color="primary"
                  variant="outlined"
                  fullWidth
                  data-cy="button-toggle-motorboat"
                >
                  {showBoat ? "Infos du semi-rigide" : "OUI"}
                </Button>
                <Collapse in={showBoat}>
                  <Field
                    component={TextField}
                    name="motorboat_name"
                    type="text"
                    label="Nom"
                    fullWidth
                    required
                    data-cy="input-motorboat-name"
                  />
                  <Field
                    component={TextField}
                    name="motorboat_id"
                    type="text"
                    label="Immatriculation"
                    fullWidth
                    data-cy="input-motorboat-id"
                  />
                </Collapse> */}
                {/* <br />
            <br />
            <Field component={SimpleFileUpload} name="photo" label="Photo" />
            <br /> */}
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                  sx={sxSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  fullWidth
                  data-cy="button-submit"
                >
                  VALIDER
                </Button>
              </Form>
            </Box>
          )
        }}
      </Formik>
      <AuthShortcuts />
    </React.Fragment>
  )
}
