export const card = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: (theme) => theme.spacing(1),
  borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
  width: "100%",
}

const headerCard = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: (theme) => theme.spacing(0),
  backgroundColor: (theme) => theme.palette.primary.light,
  color: (theme) => theme.palette.primary.contrastText,
  width: "100%",
  height: "32px",
}

export const sxNavList = {
  alert: {
    width: "100%",
    margin: (theme) => `${theme.spacing(1)} auto`,
  },
  root: {
    height: "100vh",
  },
  paper: {
    padding: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.text.secondary,
  },
  block: {
    alignItems: "center",
    padding: (theme) => theme.spacing(1),
    width: "100%",
  },
  headerCard,
  headerCardAlert: {
    ...headerCard,
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
  card,
  cardList: { padding: "0px 8px" },
  cardText: {
    color: (theme) => theme.palette.primary.main,
    lineHeight: 1.1,
  },
  accidentReportedText: {
    color: (theme) => theme.palette.secondary.main,
    lineHeight: 1.1,
  },
  "@keyframes alertBackground": {
    from: { backgroundColor: (theme) => theme.palette.secondary.light },
    to: { backgroundColor: (theme) => theme.palette.secondary.contrastText },
  },
  grid: {
    display: "grid",
    height: "100vh",
    columnGap: "10%",
    rowGap: "0.5%",
    alignContent: "start",
  },
  alertBackground: {
    ...card,
    animationName: "$alertBackground",
    animationDuration: "0.8s",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
    // animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  "@keyframes alertText": {
    from: { color: (theme) => theme.palette.secondary.contrastText },
    to: { color: (theme) => theme.palette.primary.main },
  },
  alertText: {
    animationName: "$alertText",
    animationDuration: "0.8s",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
    // animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  list: { padding: "0px", display: "flex" },
  listItem: { padding: "0px 16px", textAlign: "center" },
  title: {
    height: "36px",
    marginBottom: "0px",
  },
}
