import {} from "@mui/icons-material"

import * as R from "ramda"

import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import { Check, Delete } from "@mui/icons-material"

import DrawZone from "./DrawZone"
import MapContainer from "../../../components/Map/MapContainer"
import React from "react"
import { Undo } from "@mui/icons-material"
import { getCentroid } from "../../../../services/GeoService"
import { useMapEvents } from "react-leaflet"

const AddPoint = ({ setFieldValue, index, zone }) => {
  useMapEvents({
    click: ({ latlng: { lat, lng } }) =>
      setFieldValue(`zones.${index}.coords`, [...zone.coords, [lat, lng]]),
  })

  return null
}

const styleMap = {
  width: "100%",
  height: "100%",
  margin: "0",
}
const styleAppBarButtons = {
  margin: "0 8px",
}

const DrawModal = ({
  show,
  zones,
  zoneIndex,
  baseCoords,
  clearZone,
  setFieldValue,
}) => {
  console.log("baseCoords: ", baseCoords)

  if (R.isNil(zoneIndex)) return null

  const zone = R.nth(zoneIndex, zones)
  console.log("zone: ", zone)
  const keyPath = `zones.${zoneIndex}.coords`
  console.log("keyPath: ", keyPath)
  console.log("show: ", show)

  const center = !R.isEmpty(zone.coords) ? getCentroid(zone.coords) : baseCoords

  const _removeLast = () => setFieldValue(keyPath, R.dropLast(1, zone.coords))
  const _removeAll = () => setFieldValue(keyPath, [])

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={clearZone}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={clearZone}
            aria-label="close"
            size="large"
          >
            <Check />
          </IconButton>
          <Typography variant="h6">Editer {zone.name}</Typography>

          <Button
            style={styleAppBarButtons}
            variant="contained"
            startIcon={<Undo />}
            onClick={_removeLast}
          >
            Anuler le dernier point
          </Button>
          <Button
            style={styleAppBarButtons}
            variant="contained"
            startIcon={<Delete />}
            onClick={_removeAll}
          >
            Effacer tout
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <MapContainer
          style={styleMap}
          zoom={12}
          zoomControl={false}
          center={center}
        >
          <AddPoint
            setFieldValue={setFieldValue}
            index={zoneIndex}
            zone={zone}
          />
          {zones.map((zone, index) => (
            <DrawZone
              key={index}
              zone={zone}
              baseCoords={baseCoords}
              active={index === zoneIndex}
            />
          ))}
        </MapContainer>
      </DialogContent>
    </Dialog>
  )
}

export default DrawModal
