import { Box, Card, Typography } from "@mui/material"

import NavItem from "./NavItem"
import React from "react"
import { sxNavList } from "./styles"

const AlertBlock = ({ navs }) => {
  const amount = navs && `(${navs.length})`

  return (
    <Box sx={sxNavList.block}>
      <Card sx={sxNavList.headerCardAlert} elevation={0} variant="outlined">
        <Typography align="center" variant="h5">
          En Alerte {amount}
        </Typography>
      </Card>
      {navs.map((nav) => (
        <NavItem key={nav.id} nav={nav} />
      ))}
    </Box>
  )
}

export default AlertBlock
