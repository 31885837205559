import { Button, Paper } from "@mui/material"

import { CalendarPicker } from "@mui/x-date-pickers"
import Day from "./Day"
import { EventAvailable } from "@mui/icons-material"
import React from "react"

const sxCal = {
  calendar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: (theme) => theme.spacing(1),
    margin: (theme) => `${theme.spacing(1)} auto`,
    color: (theme) => theme.palette.text.secondary,
    textAlign: "center",
    maxWidth: 1200,
  },
}
const Calendar = ({ date, setDate, toggleCal }) => {
  const handleCalendarChange = (date) => {
    setDate(date)
    toggleCal()
  }

  return (
    <Paper sx={sxCal.calendar}>
      <CalendarPicker
        date={date}
        onChange={handleCalendarChange}
        renderDay={(day, selectedDays, pickersDayProps) => (
          <Day day={day} {...pickersDayProps} />
        )}
      />
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EventAvailable />}
        onClick={() => setDate(new Date())}
        fullWidth
      >
        Aujourd'hui
      </Button>
    </Paper>
  )
}

export default Calendar
