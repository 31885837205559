import * as R from "ramda"
import * as Sentry from "@sentry/browser"

import { Container, Typography } from "@mui/material"

import { BugReport } from "@mui/icons-material/"
import { HeaderWithIcon } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { styleFullHeight } from "../../styles"

const RenderComponent = () => {
  return (
    <Container maxWidth="xs">
      <div sx={styleFullHeight}>
        <HeaderWithIcon icon={<BugReport />}>
          <Typography component="h1" variant="h4">
            Une erreur s'est produite
          </Typography>
          <Typography component="h2" variant="h5">
            Cette erreur a été envoyée à l'équipe de développeurs.
          </Typography>
        </HeaderWithIcon>
      </div>
    </Container>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, (scope) => {
      scope.setUser(this.props.user)
    })
  }

  render() {
    if (this.state.hasError) {
      return <RenderComponent />
    }
    return this.props.children
  }
}

const getUser = R.compose(R.pick(["user"]), R.prop("auth"))

export default connect(getUser)(ErrorBoundary)
