import { Box, Typography } from "@mui/material"
import {
  getMotorboatList,
  getSupportList,
  navTimeInfos,
} from "../../../../services/NavService"

import React from "react"
import { sxInNav } from "./styles"

const NavInfos = ({ nav, classes }) => {
  console.log("nav: ", nav)
  return (
    <Box sx={sxInNav.navInfosWrapper}>
      <Box sx={sxInNav.navInfos}>
        <Typography color="primary" variant="h5" align="center">
          {getSupportList(nav.navsupports)}
        </Typography>
        <Typography variant="h6">{nav.trainees} stagiaires</Typography>
        {nav.alert_status && (
          <Typography
            variant={nav.alert_status ? "h3" : "h5"}
            color={nav.alert_status ? "#FFFFFF" : "primary"}
          >
            En Alerte
          </Typography>
        )}
        <Typography variant="body1" align="center">
          {navTimeInfos(nav)}
        </Typography>
        <Typography variant="body1" align="center">
          Semi-rigide{nav.navmotorboats.length > 0 ? "s" : ""}:{" "}
          {getMotorboatList(nav.navmotorboats)}
        </Typography>
      </Box>
    </Box>
  )
}

export default NavInfos
