import * as R from "ramda"

import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { Close } from "@mui/icons-material"
import NavForm from "./NewNavForm"
import React from "react"
import { clearNavInModal } from "../../../../state/modules/navs"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const styleAppBar = {
  position: "relative",
}
const styleTitle = {
  marginLeft: (theme) => theme.spacing(2),
  flex: 1,
}
const NavModal = () => {
  const nav = useSelector(R.pathOr(false, ["navs", "navInModal"]))
  const dispatch = useDispatch()
  const handleClose = () => dispatch(clearNavInModal())

  // use this const for Dialog props'open' which accept only boolean
  const open = nav ? true : false

  const getTitle = (kind) => {
    switch (kind) {
      case "now":
        return "Démarrer tout de suite"
      case "planify":
        return "Plannifier une nav"
      case "modify":
        return "Modifier la nav programmée"
      default:
        return ""
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <AppBar sx={styleAppBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" sx={styleTitle}>
            {getTitle(nav?.kind)}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <NavForm nextAction={handleClose} nav={nav} />
      </DialogContent>
    </Dialog>
  )
}
export default NavModal
