import * as R from "ramda"

export const handleError = (setSubmitting, status, setStatus, error) => {
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    // console.log("error.response.data", error.response.data)
    // console.log("error.response.status", error.response.status)
    // console.log("error.response.headers", error.response.headers)
    let errors = []
    if (R.hasPath(["response", "data", "non_field_errors"], error)) {
      errors = [...errors, ...error.response.data.non_field_errors]
    }
    if (R.hasPath(["response", "data", "detail"], error)) {
      errors = [...errors, error.response.data.detail]
    }
    setStatus({
      ...status,
      errors: errors,
    })
    setSubmitting(false)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log("Request error", error)
    setStatus({ ...status, errors: ["Network error. Check your connection."] })
    setSubmitting(false)
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error)
    setStatus({ ...status, errors: [error.message] })
    setSubmitting(false)
  }
}
