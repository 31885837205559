export const JWT_AUTH_TOKEN = "navisure.auth"

const e2e = window.localStorage.getItem("e2e")

export const API_HTTP = e2e
  ? `${process.env.REACT_APP_E2E_BACKEND_HTTP}/api`
  : `${process.env.REACT_APP_BACKEND_HTTP}/api`
export const API_WS = e2e
  ? process.env.REACT_APP_E2E_BACKEND_WS
  : process.env.REACT_APP_BACKEND_WS
