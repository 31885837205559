import * as R from "ramda"

import { getCurrentPos, setMapTarget } from "../state/modules/geo"
import { useEffect, useState } from "react"

import { getLastPos } from "../selectors"
import { useSelector } from "react-redux"

export const usePosition = (options = { enableHighAccuracy: true }) => {
  const [position, setPosition] = useState({})

  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setPosition({ error: "Geolocation is not supported" })
      return
    }
    const resolve = ({ coords }) => {
      setPosition({
        lat: coords.latitude,
        lng: coords.longitude,
        timestamp: Date.now(),
      })
    }
    const reject = (error) => {
      setPosition({ error: error.message })
    }

    geo.getCurrentPosition(resolve, reject, options)
  }, [options])

  return position
}

export const convertDMSToDEG = (dms) => {
  const [degrees, minutes, seconds, direction] = dms.split(/[^\d\w.]+/)

  let deg = (
    Number(degrees) +
    Number(minutes) / 60 +
    Number(seconds) / 3600
  ).toFixed(6)

  if (direction === "S" || direction === "W") {
    deg = deg * -1
  }
  return deg
}

export const convertDEGToDMS = (deg, lat) => {
  const absolute = Math.abs(deg)

  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2)

  let direction
  if (lat) {
    direction = deg >= 0 ? "N" : "S"
  } else {
    direction = deg >= 0 ? "E" : "W"
  }

  return degrees + "°" + minutes + "'" + seconds + '"' + direction
}

export const latLngAsDMS = (latLng) => {
  if (
    R.anyPass([
      R.isNil,
      (latLng) => R.type(latLng) !== "Object",
      (latLng) => !R.has("lat", latLng) || !R.has("lng", latLng),
    ])(latLng)
  ) {
    return ""
  }
  return `${convertDEGToDMS(latLng.lat, true)} / ${convertDEGToDMS(latLng.lng)}`
}

export const getCentroid = (arr = []) => {
  let twoTimesSignedArea = 0
  let cxTimes6SignedArea = 0
  let cyTimes6SignedArea = 0

  let length = arr.length

  if (length === 0) return [47.509, -3.121]

  let x = function (i) {
    return arr[i % length][0]
  }
  let y = function (i) {
    return arr[i % length][1]
  }

  for (let i = 0; i < arr.length; i++) {
    let twoSA = x(i) * y(i + 1) - x(i + 1) * y(i)
    twoTimesSignedArea += twoSA
    cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA
    cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA
  }
  let sixSignedArea = 3 * twoTimesSignedArea
  return [
    cxTimes6SignedArea / sixSignedArea,
    cyTimes6SignedArea / sixSignedArea,
  ]
}

export const getPos = async (
  options = {
    enableHighAccuracy: true,
  },
) => {
  const position = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
  return {
    timestamp: Date.now(),
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  }
}

export const useMapTarget = () => useSelector(R.path(["geo", "mapTarget"]))

export const useLastPos = () => useSelector(getLastPos)

export const centerOnMe = (dispatch) => {
  dispatch(getCurrentPos())
  dispatch(setMapTarget("me"))
}
