import React, { useCallback } from "react"

import { useNavOwner } from "../../../../../services/BaseService"
import { useViewerBaseUser } from "../../../../../services/UserService"
import { useNav, navTimeInfos } from "../../../../../services/NavService"

import { Card, CardContent, Fab, Typography } from "@mui/material"
import { ArrowBack, NoteAlt } from "@mui/icons-material"

import { HeaderWithIcon } from "../../../../components"
import New from "./New"
import Existing from "./Existing"
import { sxRightFab } from "../../../../styles"

const Debrief = ({ match, history }) => {
  const nav = useNav(match.params.id)

  const navOwner = useNavOwner(nav)
  const viewerBaseUser = useViewerBaseUser()

  const viewerIsOwner = navOwner.id === viewerBaseUser.user.id

  const returnToMain = useCallback(
    () => history.push(`/navs/${nav.id}`),
    [history, nav.id],
  )

  if (!nav.report && !viewerIsOwner) {
    returnToMain()
  }

  const debriefStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: (theme) => theme.spacing(1),
    width: "100%",
    height: "100%",
    overflowY: "auto",
  }

  return (
    <Card sx={debriefStyles} elevation={0} variant="outlined">
      <Fab
        onClick={returnToMain}
        aria-label="menu"
        sx={sxRightFab}
        data-cy="toggle-main-menu"
      >
        <ArrowBack />
      </Fab>

      <CardContent>
        <HeaderWithIcon title="Debrief" icon={<NoteAlt />} />
        <Typography align="center" variant="body1" gutterBottom>
          {navTimeInfos(nav)}
        </Typography>

        {!nav.report ? (
          <New nav={nav} returnToMain={returnToMain} />
        ) : (
          <Existing nav={nav} />
        )}
      </CardContent>
    </Card>
  )
}
export default Debrief
