import React from "react"
import { Switch, Route } from "react-router-dom"

import Links from "./Links"
import Contacts from "./Contacts"
import NotFound from "../NotFound"

const Infos = () => (
  <Switch>
    <Route exact path={"/infos/links"} component={Links} />
    <Route exact path={"/infos/contacts"} component={Contacts} />
    <Route component={NotFound} />
  </Switch>
)

export default Infos
